import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import styles from './index.module.scss'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip)
ChartJS.defaults.font.family = 'Montserrat, sans-serif'
ChartJS.defaults.font.size = 14
ChartJS.defaults.font.lineHeight = '1.125rem'
ChartJS.defaults.color = '#060D45'

interface IComissionsGraphProps {
  comissions?: {
    labels: string[]
    data: number[]
  }
}

const formatValue = (value: number): string =>
  new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    currencyDisplay: 'symbol',
    notation: 'compact',
    maximumFractionDigits: 0
  }).format(value)

export default function ComissionsGraph({
  comissions = { labels: [], data: [] }
}: IComissionsGraphProps) {
  return (
    <div
      role={'group'}
      aria-label={'Contenedor de gráfica de comportamiento de comisiones'}
      className={styles.root}>
      <h2 className={styles.root__title}>Comportamiento de comisiones</h2>
      <div className={styles['root__graph-wrapper']}>
        <Line
          aria-label="Gráfica de comportamiento de comisiones"
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                backgroundColor: '#3E6DED',
                bodyAlign: 'center',
                titleAlign: 'center',
                callbacks: {
                  label: (item) => ' ' + formatValue(item.raw as number)
                }
              }
            },
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                max:
                  comissions.data.filter((value) => value === 0).length ===
                  comissions.data.length
                    ? 100000
                    : undefined,
                ticks: {
                  callback: (value) => formatValue(value as number)
                }
              }
            }
          }}
          data={{
            labels: comissions.labels,
            datasets: [
              {
                data: comissions.data,
                borderColor: '#DB7927',
                pointBackgroundColor: '#DB7927',
                tension: 0.25
              }
            ]
          }}
        />
      </div>
    </div>
  )
}
