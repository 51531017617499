import { useCustomFieldRegistration, useFieldMessage } from '@/hooks'
import { ICheckboxFormGroupProps } from '@/interfaces'
import { useState } from 'react'
import { FieldValues } from 'react-hook-form'
import styles from './index.module.scss'

export default function CheckboxGroup<T extends FieldValues>({
  field,
  label = '',
  className = ''
}: ICheckboxFormGroupProps<T>) {
  const [isChecked, setIsChecked] = useState(false)
  const { isError, message } = useFieldMessage<T>(field)
  const fieldRegistration = useCustomFieldRegistration<T>(field)

  return (
    <div
      role={'group'}
      className={
        styles.root +
        (isError ? ` ${styles['root--error']}` : '') +
        (className !== '' ? ` ${className}` : '')
      }>
      <label
        className={
          styles.root__wrapper +
          (isChecked ? ` ${styles['root__wrapper--checked']}` : '')
        }
        htmlFor={field?.id}>
        <input
          id={field?.id}
          type={'checkbox'}
          className={styles.root__input}
          onChangeCapture={({ currentTarget: { checked } }) => {
            setIsChecked(checked)
          }}
          {...fieldRegistration}
        />
        <span className={styles.root__label}>{label}</span>
      </label>
      <span className={styles.root__message}>{message}</span>
    </div>
  )
}
