import styles from './index.module.scss'
import { IMessageToast, MessageToasts } from '@atoms/index'
import { useState } from 'react'

interface IRequestRecoveryButtonProps {
  messages?: IMessageToast[]
  onClick?: () => Promise<void> | void
  disabled?: boolean
  onMessageDismiss?: (index: number) => void
}

export default function RequestRecoveryButton({
  messages = [],
  onClick,
  disabled = false,
  onMessageDismiss
}: IRequestRecoveryButtonProps) {
  const [isLoading, setIsLoading] = useState(false)

  const handleButtonClick = async () => {
    setIsLoading(true)
    await onClick?.()
    setIsLoading(false)
  }
  return (
    <div className={styles.root} role={'content-info'}>
      <h2 className={styles['root__group-title']}>Cambio de contraseña</h2>
      <p>
        Para cambiar tu contraseña de manera segura debes generar el código de
        verificación. Te enviaremos tu código de verificación al correo con el
        cual te registraste.
      </p>
      <button
        type={'button'}
        onClick={handleButtonClick}
        className={styles['root__submit-button']}
        disabled={disabled || isLoading}
        aria-label="Botón para enviar la solicitud de recuperación">
        GENERAR CÓDIGO
      </button>
      {isLoading && <div className={styles.root__loading}></div>}
      {messages.length !== 0 && (
        <div className={styles.root__messages}>
          <MessageToasts
            dismissible
            messages={messages}
            onDismiss={onMessageDismiss}
          />
        </div>
      )}
    </div>
  )
}
