import { useIsMobile } from '@/hooks'
import { TCommission } from '@/interfaces'
import { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import {
  formatAmount,
  formatCommissionStatus,
  formatCommissionType,
  formatDate,
  parseCommissionStatus,
  parseCommissionType
} from '@/functions'
import { CommissionDetailRow } from '..'

type TDetailsStatus = 'collapsed' | 'expanded'

interface ICommissionTBodyProps {
  commission?: TCommission
}

export default function CommissionTBody({ commission }: ICommissionTBodyProps) {
  const isMobile = useIsMobile()
  const [detailsStatus, setDetailsStatus] =
    useState<TDetailsStatus>('collapsed')

  return (
    <tbody
      className={styles.root}
      aria-label={'Detalle de comisión'}
      role={'group'}
      data-status={detailsStatus}>
      <tr
        className={styles.root__data}
        onClick={() => {
          setDetailsStatus((current) =>
            current === 'collapsed' ? 'expanded' : 'collapsed'
          )
        }}>
        <td>
          {commission !== undefined && (
            <FeatherIcon
              className={styles.root__icon}
              icon={
                detailsStatus === 'collapsed' ? 'chevron-down' : 'chevron-up'
              }
            />
          )}
        </td>
        <td>{commission !== undefined && formatDate(commission.updatedAt)}</td>
        {!isMobile && (
          <td colSpan={2}>
            {commission !== undefined &&
              formatCommissionType(parseCommissionType(commission.ruleTypeId))}
          </td>
        )}
        <td>{commission !== undefined && formatAmount(commission.amount)}</td>
        <td>
          {commission !== undefined && (
            <span
              className={styles.root__badge}
              data-status={parseCommissionStatus(commission.commissionStateId)}>
              {formatCommissionStatus(
                parseCommissionStatus(commission.commissionStateId)
              )}
            </span>
          )}
        </td>
      </tr>
      {commission !== undefined && (
        <CommissionDetailRow
          commission={commission}
          visible={detailsStatus === 'expanded'}
        />
      )}
    </tbody>
  )
}
