import { IKliikerResetPasswordFormFields } from '@/interfaces'
import {
  kliikerPasswordChangeRequestService,
  kliikerResetPasswordFormService
} from '@/services'
import { IMessageToast } from '@atoms/index'
import { ResetPasswordForm, RequestRecoveryButton } from '@molecules/index'
import { useSession } from 'next-auth/react'
import { useState, useEffect, useRef } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styles from './index.module.scss'

interface IKliikerChangePasswordProps {
  resetTime?: number
}

export default function KliikerChangePassword({
  resetTime = 60
}: IKliikerChangePasswordProps) {
  const emailSent = useRef<string>()
  const [requestMessages, setRequestMessages] = useState<IMessageToast[]>([])
  const [resetMessages, setResetMessages] = useState<IMessageToast[]>([])
  const [remainingTime, setRemainingTime] = useState(0)
  const [requestDisabled, setRequestDisabled] = useState(false)
  const [resetDisabled, setResetDisabled] = useState(true)
  const { data } = useSession()

  const handleRequestButtonClick = async () => {
    setRequestDisabled(true)
    try {
      const idToken = data?.idToken
      const email = data?.user.email
      if (idToken === undefined)
        throw new Error('¡Algo salió mal 😰!', { cause: 'No-Id-Token' })
      if (email === undefined)
        throw new Error('¡Algo salió mal 😰!', { cause: 'No-Email' })
      await kliikerPasswordChangeRequestService(idToken)
      emailSent.current = email
      setRemainingTime(resetTime)
    } catch (ex) {
      setRequestMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
    setResetDisabled(false)
  }

  const handleResetFormSubmit: SubmitHandler<
    IKliikerResetPasswordFormFields
  > = async (data) => {
    try {
      if (emailSent.current === undefined)
        throw new Error(
          'Algo salió mal en el envío del formulario, intenta generar un nuevo código y volver a envíar'
        )
      data.email = emailSent.current
      await kliikerResetPasswordFormService(data)
      setResetMessages((currentMessages) => [
        ...currentMessages,
        { type: 'success', message: 'Contraseña actualizada con éxito' }
      ])
    } catch (ex) {
      setResetMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  useEffect(() => {
    if (!requestDisabled) return
    const intervalId = setInterval(() => {
      if (remainingTime === 0) {
        setRequestDisabled(false)
        clearInterval(intervalId)
      } else setRemainingTime((currentTime) => currentTime - 1)
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [remainingTime, requestDisabled])

  return (
    <section className={styles.root} role="group-section">
      <RequestRecoveryButton
        onClick={handleRequestButtonClick}
        messages={requestMessages}
        onMessageDismiss={(index) =>
          setRequestMessages((currentMessage) =>
            currentMessage.filter((_, currentIndex) => index !== currentIndex)
          )
        }
        disabled={requestDisabled}
      />
      <div className={styles['root__reset-password']}>
        <ResetPasswordForm
          position="start"
          textButton="GUARDAR CAMBIOS"
          resetTime={remainingTime}
          messages={resetMessages}
          onMessageDismiss={(index) =>
            setResetMessages((currentMessage) =>
              currentMessage.filter((_, currentIndex) => index !== currentIndex)
            )
          }
          onSubmit={handleResetFormSubmit}
          disabled={resetDisabled}
        />
      </div>
    </section>
  )
}
