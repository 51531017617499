import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BmodelCard } from '@molecules/index'
import { bmodelSectionData } from './index.data'
import 'swiper/css/bundle'
import styles from './index.module.scss'
import Link from 'next/link'

export default function BmodelSection() {
  return (
    <section role={'contentinfo'} className={styles.root}>
      <h2 className={styles.root__heading}>
        <strong>Así podrás vender</strong>
        <br /> como Kliiker
      </h2>
      <p className={styles.root__description}>
        Registrate y recibe el link de tu tienda virtual
      </p>
      <Swiper
        role={'list'}
        className={styles['root__cards-list']}
        modules={[Autoplay]}
        centerInsufficientSlides
        onResize={(swiper) => swiper.update()}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
        slidesPerView={1}
        breakpoints={{
          '1024': { slidesPerView: 3 }
        }}>
        {bmodelSectionData.map((data, index) => (
          <SwiperSlide
            key={index}
            className={styles.root__item}
            role={'listitem'}>
            <BmodelCard title={data.title} placeholder={data.image}>
              {data.description}
            </BmodelCard>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles['root__actions-wrapper']}>
        <Link
          href={'/registro'}
          title={'Enlace al formulario de registro Kliiker'}
          className={styles.root__cta}>
          ¡Únete aquí!
        </Link>
      </div>
    </section>
  )
}
