import styles from './index.module.scss'
import { SubmitHandler, useForm } from 'react-hook-form'
import Link from 'next/link'
import { IMessageToast, InputGroup, MessageToasts } from '@atoms/index'
import { IInputFormGroup, IKliikerLoginFormFields } from '@/interfaces'
import { useFormGroups, useKliikerLoginFormFields } from '@/hooks'
import { getFieldProp } from '@/functions'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useState } from 'react'

interface ILoginFormProps {
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onSubmit?: SubmitHandler<IKliikerLoginFormFields>
}

export default function LoginForm({
  messages = [],
  onMessageDismiss = () => {},
  onSubmit = () => {}
}: ILoginFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const formObject = useForm<IKliikerLoginFormFields>({
    mode: 'all'
  })
  const fields = useKliikerLoginFormFields()
  const groups = useFormGroups<
    IKliikerLoginFormFields,
    IInputFormGroup<IKliikerLoginFormFields>
  >(fields, ['email', 'password'])

  const handleFormSubmit: SubmitHandler<IKliikerLoginFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    if (executeRecaptcha !== undefined)
      data.gCaptcha = await executeRecaptcha('kliikerLoginFormSubmit')
    await onSubmit(data)
    setIsLoading(false)
  }

  return (
    <form
      className={styles.root}
      role={'form'}
      onSubmit={formObject.handleSubmit(handleFormSubmit)}>
      <div className={styles['root__input-groups']}>
        {groups.map(({ field, ...props }, key) => {
          const fieldProp = getFieldProp<IKliikerLoginFormFields>(
            formObject,
            field
          )
          return (
            <InputGroup<IKliikerLoginFormFields>
              key={key}
              field={fieldProp}
              {...props}
            />
          )
        })}
      </div>
      <Link
        href={'/ingreso/recuperar'}
        className={styles['root__forget-password-link']}>
        ¿Olvidaste tu contraseña?
      </Link>
      <button
        type={'submit'}
        className={styles['root__submit-button']}
        aria-label="Botón para enviar el formulario"
        disabled={isLoading}>
        Entrar
      </button>
      {isLoading && <div className={styles.root__loading}></div>}
      {messages.length !== 0 && (
        <div className={styles.root__messages}>
          <MessageToasts
            dismissible
            messages={messages}
            onDismiss={onMessageDismiss}
          />
        </div>
      )}
      <p className={styles['root__signin-cta']}>
        ¿Aún no eres parte de nuestra comunidad?{' '}
        <Link href={'/registro'}>Regístrate aquí</Link>
      </p>
    </form>
  )
}
