import Image from 'next/image'
import placeholder from '@/assets/img/dashboard/banner1.jpg'
import styles from './index.module.scss'
import Link from 'next/link'

export default function KliikerGoals() {
  return (
    <div
      role={'group'}
      className={styles.root}
      aria-label={'Contendor de las metas del Kliiker'}>
      <Link href='/portal/academy'>
        <figure
          aria-label={'Máscara para la imagen de fondo'}
          className={styles.root__mask}>
          <Image
            src={placeholder}
            alt={'Banner respecto a premios para los Kliikers'}
            className={styles.root__image}
            priority
          />
        </figure>
      </Link>
      <hgroup className={styles.root__heading}>
        <h2 className={styles.root__title}>Academia Kliiker</h2>
        <p className={styles.root__description}>
          Nuestra nueva sección, un lugar para aprender a vender y ganar más
        </p>
      </hgroup>
    </div>
  )
}
