import styles from './index.module.scss'
import Link from 'next/link'
// import { SVGComponent } from '@atoms/index'
import { Accordion, IAccordionItem } from '@molecules/index'
import { useAppSelector } from '@/state/hooks'
import { selectBenefits } from './index.slice'

export default function BefenitsSection() {
  const benefits = useAppSelector(selectBenefits).map<IAccordionItem>(
    (benefit) => ({
      title: benefit.title,
      content: benefit.shortDescription
    })
  )

  return (
    <section role={'contentinfo'} className={styles.root}>
      <div className={styles.root__content}>
        <hgroup className={styles.root__heading}>
          <h2 className={styles.root__title}>
            <strong>Los beneficios de convertirse</strong> en Kliiker
          </h2>
          <p className={styles.root__description}>
            Por ser parte de la comunidad tienes acceso a miles de beneficios
            exclusivos con las mejores marcas.
          </p>
        </hgroup>
        <div className={styles.root__wrapper} role={'group'}>
          {/* <SVGComponent
            svgPath="benefits-section/decoration"
            className={styles.root__decoration}
          /> */}
          <Accordion items={benefits} />
        </div>
      </div>
      <div className={styles.root__button}>
        <Link
          href={'/registro'}
          className={styles.root__cta}
          title="Enlace al formulario de registro de Kliiker">
          ¡Únete Aquí!
        </Link>
      </div>
    </section>
  )
}
