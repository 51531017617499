import { InputGroup } from 'components/atoms/index'
export const parseTypeBonus = (
  typeRule: string,
  typeBonus: string,
  formObject?: any
) => {
  if (typeRule !== '7')
    return {
      1: (
        <InputGroup
          label="Valor de comisión"
          placeholder="Ej: $10.000"
          field={{
            id: 'commissionAmount',
            formObject
          }}
        />
      ),
      2: (
        <InputGroup
          label="Premio"
          placeholder="Ej: Camiseta"
          field={{
            id: 'description',
            formObject
          }}
        />
      ),
      3: (
        <>
          <InputGroup
            label="Premio"
            placeholder="Ej: Camiseta"
            field={{
              id: 'description',
              formObject
            }}
          />
          <InputGroup
            label="Valor de comisión"
            placeholder="Ej: $10.000"
            field={{
              id: 'commissionAmount',
              formObject
            }}
          />
        </>
      )
    }[typeBonus]
  else
    return {
      1: (
        <InputGroup
          label="% de comisión por venta*"
          field={{
            id: 'percentage',
            formObject
          }}
        />
      ),
      2: (
        <InputGroup
          label="Valor de comisión"
          placeholder="Ej: $10.000"
          field={{
            id: 'commissionAmount',
            formObject
          }}
        />
      )
    }[typeBonus]
}
