import {
  IKliikerLinkCreationFormFields,
  IKliikerProfile,
  IServiceUser,
  TSelectItemsFiller,
  ISelectOptions,
  IKliikerEditDataFormFields,
  IMetricskliiker
} from '@/interfaces'
import { mockedEmptyKliikerProfile, mockedMetricsKliiker } from '@/mocks'
import {
  kliikerCitiesService,
  kliikerCityService,
  kliikerEditUserService,
  kliikerMetricsService,
  kliikerProfilePictureService,
  kliikerStatesService
} from '@/services'
import { IMessageToast, TabsMenu } from '@atoms/index'
import {
  KliikerAccountBox,
  KliikerDataBox,
  KliikerChangePassword
} from '@molecules/index'
import { signIn, useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styles from './index.module.scss'
import { useRouter } from 'next/router'

interface ITab {
  label: string
  element: JSX.Element
}

export default function KliikerProfileSection() {
  const initialTab = 0
  const session = useSession()
  const [currentTab, setCurrentTab] = useState(initialTab)
  const [linkMessages, setLinkMessages] = useState<IMessageToast[]>([])
  const [editMessages, setEditMessages] = useState<IMessageToast[]>([])
  const [kliiker, setKliiker] = useState<IKliikerProfile>(
    mockedEmptyKliikerProfile
  )
  const [metrics, setMetrics] = useState<IMetricskliiker>(mockedMetricsKliiker)
  const idToken = session.data?.idToken
  const router = useRouter()

  const handleLinkSubmit: SubmitHandler<
    IKliikerLinkCreationFormFields
  > = async ({ linkId }) => {
    try {
      if (session.data?.user === undefined)
        throw new Error('¡Algo salio mal 😰!', { cause: 'No-Session-User' })
      else if (idToken === undefined)
        throw new Error('¡Algo salio mal 😰!', { cause: 'No-Id-Token' })
      const userData: IServiceUser = {
        ...session.data.user,
        uniqueAlias: linkId
      }
      const response = await kliikerEditUserService(idToken, userData)
      response && router.push('/portal')
    } catch (ex) {
      setLinkMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  const handleEditSubmit: SubmitHandler<IKliikerEditDataFormFields> = async (
    data
  ) => {
    try {
      const user = session.data?.user
      if (idToken === undefined)
        throw new Error('¡Algo salió mal 😰!', { cause: 'No-Id-Token' })
      if (user === undefined)
        throw new Error('¡Algo salió mal 😰!', { cause: 'No-User' })
      let photoProfile: string | null = user.photoProfile
      if (data.picture !== '') {
        const pictureResponse = await kliikerProfilePictureService(
          idToken,
          data.picture
        )
        photoProfile = pictureResponse.photoProfile
      }
      const {
        firstName: firstname,
        lastName: lastname,
        cityCode,
        phoneNumber: phone
      } = data
      await kliikerEditUserService(idToken, {
        ...user,
        firstname,
        lastname,
        cityId: parseInt(cityCode),
        phone,
        photoProfile
      })
      const loginResponse = await signIn('kliiker-services-with-token', {
        redirect: false
      })
      if (
        loginResponse === undefined ||
        (loginResponse !== undefined && !loginResponse.ok)
      )
        throw new Error('¡Algo falló al refrescar la sesión 😰!', {
          cause: 'Cant-refresh-session'
        })
    } catch (ex) {
      setEditMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  const dataBoxStateFiller: TSelectItemsFiller = async () => {
    const response = await kliikerStatesService()
    return response.map<ISelectOptions>((state) => ({
      label: state.name,
      value: state.provinceId
    }))
  }

  const dataBoxCityFiller: TSelectItemsFiller = async (dependency) => {
    const response = await kliikerCitiesService(dependency)
    return response.map<ISelectOptions>((city) => ({
      label: city.name,
      value: city.cityId
    }))
  }

  const tabs: ITab[] = [
    {
      label: 'Cuenta',
      element: (
        <KliikerAccountBox
          kliiker={kliiker}
          metrics={metrics}
          messages={linkMessages}
          onMessageDismiss={(index) =>
            setLinkMessages((currentMessages) =>
              currentMessages.filter(
                (_, currentIndex) => index !== currentIndex
              )
            )
          }
          onError={(message) =>
            setLinkMessages((currentMessages) => [...currentMessages, message])
          }
          onLinkSubmit={handleLinkSubmit}
        />
      )
    },
    {
      label: 'Datos personales',
      element: (
        <KliikerDataBox
          kliiker={kliiker}
          stateFiller={dataBoxStateFiller}
          cityFiller={dataBoxCityFiller}
          messages={editMessages}
          onMessageDismiss={(index) =>
            setEditMessages((currentMessages) =>
              currentMessages.filter(
                (_, currentIndex) => index !== currentIndex
              )
            )
          }
          onSubmit={handleEditSubmit}
        />
      )
    },
    { label: 'Contraseña', element: <KliikerChangePassword /> }
  ]
  const tabLabels = tabs.map<string>((tab) => tab.label)

  useEffect(() => {
    if (session.status === 'loading') return
    if (session.data === null) return
    const {
      email,
      phone: phoneNumber,
      document: docNumber,
      firstname: firstName,
      lastname: lastName,
      uniqueAlias,
      photoProfile
    } = session.data.user
    setKliiker((currentKliiker) => ({
      ...currentKliiker,
      contact: { email, phoneNumber },
      identification: { docNumber, firstName, lastName },
      linkId: uniqueAlias ?? '',
      picture: photoProfile ?? ''
    }))
  }, [session])

  useEffect(() => {
    async function fetchCity() {
      if (session.status === 'loading') return
      if (session.data === null) return
      const { cityId } = session.data.user
      if (cityId === null) return
      try {
        const response = await kliikerCityService(`${cityId}`)
        setKliiker((currentKliiker) => ({
          ...currentKliiker,
          location: { cityCode: `${cityId}`, stateCode: response.provinceId }
        }))
      } catch (ex) {}
    }
    fetchCity()
  }, [session])

  useEffect(() => {
    async function fetchMetrics() {
      if (idToken === undefined) return
      try {
        const responseMetrics = await kliikerMetricsService({ idToken })
        setMetrics(responseMetrics)
      } catch (error) {}
    }
    fetchMetrics()
  }, [idToken])

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección del perfil del Kliiker'}
      className={styles.root}>
      <h1 className={styles.root__title}>Mi perfil</h1>
      <nav
        aria-label={'Tabs de la página de perfil'}
        className={styles['root__tabs-wrapper']}>
        <TabsMenu
          tabs={tabLabels}
          initialActiveTab={initialTab}
          onChange={(index) => setCurrentTab(index)}
        />
      </nav>
      <div className={styles.root__content}>{tabs[currentTab].element}</div>
    </section>
  )
}
