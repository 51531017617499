import { useEffect, useState } from 'react'
import { TableWrapper } from '..'
import { useSession } from 'next-auth/react'
import {
  TCommissionRule,
  IBrandRule,
  ISelectOptions,
  IAdminRulesCommissions
} from '@/interfaces'
import { TAlertStatus, Alert, RulesComissionsTable } from '@molecules/index'

import styles from './index.module.scss'
import SelectGroup from '@atoms/select-group'
import RuleCommissionModal from '@molecules/rules-commission-modal'
import { parseTypeRule } from './index.data'
import {
  IAdminRulesTableQuery,
  adminRulesCommissionsTable,
  adminRulesCommissionsTableDelete,
  getAdminRulesTableService
} from '@/services'
import { IMessageToast } from '@atoms/message-toasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { listOptions } from '@/mocks/rules-commission/field-rules'

export default function RulesComissionSection() {
  const tableSize = 20
  const [tableData, setTableData] = useState<TCommissionRule[]>([])
  const [tableQuery, setTableQuery] = useState<IAdminRulesTableQuery>({
    size: tableSize
  })
  const formObject = useForm<{ ruleId: string; brandId: string }>()
  const [tableRefreshCount, setTableRefreshCount] = useState(0)
  const [brandsOptions, setBrandsOptions] = useState<ISelectOptions[]>([])
  const [typeRule, setTypeRule] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9>(1)
  const [brands, setBrands] = useState<IBrandRule[]>([])
  const [pageActive, setPageActive] = useState(1)
  const [countRegister, setCountRegister] = useState(1)
  const [countPages, setCountPages] = useState(1)
  const [alertStatus, setAlertStatus] = useState<TAlertStatus>('closed')
  const [alertMessages, setAlertMessages] = useState<IMessageToast[]>([])
  const [actionType, setActionType] = useState<
    'add-rule' | 'edit-rule' | 'duplicate-rule' | 'delete-rule'
  >('add-rule')
  const [duplicate, setDuplicate] = useState<TCommissionRule>()
  const [deleteItem, setDeleteItem] = useState<number>()
  const { data } = useSession()
  const idToken = data?.idToken

  const handlePageChange = (page: number) => {
    setPageActive(page)
  }

  const handleTableChange = async (ruleCommision: TCommissionRule) => {
    try {
      if (idToken === undefined) return
      if (ruleCommision === undefined) return
      const newObject = {
        ...ruleCommision,
        isActive: !ruleCommision.active
      }
      await adminRulesCommissionsTable({
        idToken,
        comission: newObject
      })
      setTableRefreshCount((oldCount) => oldCount + 1)
    } catch (ex) {}
  }
  const handleChangeNameRule = (object: TCommissionRule) => {
    setTypeRule(object.ruleTypeId)
  }

  const handleEditItem = (object: TCommissionRule) => {
    setActionType('edit-rule')
    if (alertStatus === 'closed') {
      setAlertStatus('open')
      setDuplicate(object)
    } else {
      setAlertStatus('closed')
    }
  }
  const handleDuplicateItem = (object: TCommissionRule) => {
    setActionType('duplicate-rule')
    if (alertStatus === 'closed') {
      setAlertStatus('open')
      setDuplicate(object)
    } else {
      setAlertStatus('closed')
    }
  }

  const handleDeleteItem = (id: number) => {
    setActionType('delete-rule')
    if (alertStatus === 'closed') {
      setAlertStatus('open')
      setDeleteItem(id)
    } else {
      setAlertStatus('closed')
    }
  }

  const handleCreateItem: SubmitHandler<IAdminRulesCommissions> = async (
    data
  ) => {
    try {
      if (idToken === undefined) return
      if (data === undefined) return
      const newObject = {
        ruleId: data.ruleId,
        ruleTypeId: data.ruleTypeId,
        brandId: data.brandId,
        isActive: false,
        params: {
          beginDate: data.beginDate,
          endDate: data.endDate,
          percentage: data.percentage,
          thresholdAmount: data.thresholdAmount,
          commissionAmount: data.commissionAmount,
          quantity: data.quantity,
          period: data.period,
          periods: data.periods,
          description: data.description,
          steps: data.steps
        }
      }
      await adminRulesCommissionsTable({
        idToken,
        comission: newObject
      })
      setAlertStatus('closed')
      setTableRefreshCount((oldCount) => oldCount + 1)
    } catch (ex) {}
  }

  async function handleDuplicateDeleteItem() {
    try {
      if (idToken === undefined) return
      if (actionType === 'duplicate-rule') {
        if (duplicate === undefined) return
        const newObject = {
          ...duplicate,
          isActive: duplicate.active
        }
        delete newObject.ruleId
        await adminRulesCommissionsTable({
          idToken,
          comission: newObject
        })
      }
      if (actionType === 'delete-rule') {
        if (deleteItem === undefined) return
        await adminRulesCommissionsTableDelete({
          idToken,
          comissionId: deleteItem
        })
      }
      setTableRefreshCount((oldCount) => oldCount + 1)
    } catch (ex) {}
  }

  useEffect(() => {
    const fetchTable = async () => {
      if (idToken === undefined) return
      try {
        const response = await getAdminRulesTableService({
          idToken,
          params: { ...tableQuery, page: pageActive - 1 }
        })
        setTableData(response.rulesPage.data)
        setBrands(response.brands)
        setBrandsOptions(
          response.brands.map<ISelectOptions>((brand) => ({
            label: brand.name,
            value: `${brand.brandId}`
          }))
        )
        setCountRegister(response.rulesPage.countRegister)
        setCountPages(response.rulesPage.countPage)
      } catch (ex) {}
    }
    fetchTable()
  }, [idToken, pageActive, tableRefreshCount, tableQuery])

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección de reglas de comisiones'}
      className={styles.root}>
      <div className={styles.root__filter}>
        <SelectGroup
          label="Tipo"
          activeLabel="Tipo"
          field={{ id: 'ruleId', formObject }}
          itemsFiller={async () => {
            const options = await listOptions()
            options.unshift({ label: 'Todos', value: 'all' })
            return options
          }}
          onChange={(value) => {
            if (value === undefined) return
            setTableQuery((oldQuery) => ({
              ...oldQuery,
              ruleType: value === 'all' ? undefined : parseInt(value)
            }))
          }}
        />
        <SelectGroup
          label="Marca o grupo"
          activeLabel="Marca o grupo"
          field={{ id: 'brandId', formObject }}
          itemsFiller={() => [
            { label: 'Todos', value: 'all' },
            ...brandsOptions
          ]}
          onChange={(value) => {
            if (value === undefined) return
            setTableQuery((oldQuery) => ({
              ...oldQuery,
              brandId: value === 'all' ? undefined : parseInt(value)
            }))
          }}
        />
      </div>
      <div className={styles.root__content}>
        <TableWrapper
          title="Lista de reglas de comisiones"
          elements={[
            {
              label: 'AGREGAR REGLA',
              icono: 'plus',
              handleClick: () => {
                setActionType('add-rule')
                if (alertStatus === 'closed') {
                  setAlertStatus('open')
                } else {
                  setAlertStatus('closed')
                }
              }
            }
          ]}
          handlePageChange={handlePageChange}
          resultsNumber={countRegister}
          totalPages={countPages}>
          <RulesComissionsTable
            commissionsRule={tableData}
            brands={brands}
            onChange={handleTableChange}
            onEdit={handleEditItem}
            onDuplicate={handleDuplicateItem}
            onDelete={handleDeleteItem}
            onChangeNameRule={handleChangeNameRule}
          />
        </TableWrapper>
        {actionType !== 'duplicate-rule' &&
          actionType !== 'delete-rule' &&
          alertStatus !== 'closed' && (
            <RuleCommissionModal
              status={alertStatus}
              actionType={actionType}
              handleCancell={() => setAlertStatus('closed')}
              onSubmit={handleCreateItem}
              brands={brandsOptions}
              activeObject={duplicate}
            />
          )}{' '}
        {(actionType === 'delete-rule' || actionType === 'duplicate-rule') &&
          alertStatus !== 'closed' && (
            <Alert
              onResponse={async (response) => {
                if (!response) {
                  setAlertStatus('closed')
                  return
                }
                setAlertStatus('waiting')
                try {
                  await handleDuplicateDeleteItem()
                  setAlertStatus('closed')
                } catch (ex) {
                  setAlertMessages((oldMessages) => [
                    ...oldMessages,
                    { type: 'error', message: (ex as Error).message }
                  ])
                  setAlertStatus('open')
                }
                setAlertStatus('closed')
              }}
              messages={alertMessages}
              status={alertStatus}
              type={actionType}
              nameRule={parseTypeRule(typeRule)}
            />
          )}
      </div>
    </section>
  )
}
