export const parseTypeRule = (type: number) => {
  return {
    1: 'Venta',
    2: 'Primera venta',
    3: 'Ventas por temporada',
    4: 'Permanencia',
    5: 'Referidos efectivos',
    6: 'Desempeño',
    7: 'Primera venta de referido',
    8: 'Reactivación de kliiker',
    9: 'Meta'
  }[type]
}
