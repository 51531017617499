import Link from 'next/link'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { IStaticLink } from '@/interfaces'

interface ILinksListProps {
  title?: string
  list?: IStaticLink[]
}

export default function LinksList({ title = '', list = [] }: ILinksListProps) {
  return (
    <div role={'group'} className={styles.root}>
      <p className={styles.root__title}>{title}</p>
      <ul className={styles.root__list}>
        {list.map((item, key) => {
          const { text, featherIcon, ...linkProps } = item
          return (
            <li key={key}>
              <Link className={styles.root__link} {...linkProps}>
                {featherIcon !== undefined ? (
                  <FeatherIcon
                    icon={featherIcon}
                    className={styles['root__link-icon']}
                  />
                ) : (
                  <></>
                )}
                <span className={styles['root__link-text']}>{item.text}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
