import {
  IConsecutivenessCommissionParams,
  IEffectiveReferalsCommissionParams,
  IFirstSellCommissionParams,
  IGoalsCommissionParams,
  IPercentRewardCommissionParam,
  IPermanenceCommissionParams,
  IReactivationCommissionParams,
  IReferalCommissionParams,
  ISeasonCommissionParams
} from './params'

export enum ECommissionType {
  't1' = 'sell',
  't2' = 'first-sell',
  't3' = 'season',
  't4' = 'permanence',
  't5' = 'effective-referals',
  't6' = 'consecutiveness',
  't7' = 'referal',
  't8' = 'reactivation',
  't9' = 'goals'
}

export type TCommissionType =
  | 'sell'
  | 'permanence'
  | 'first-sell'
  | 'season'
  | 'referal'
  | 'effective-referals'
  | 'consecutiveness'
  | 'reactivation'
  | 'goals'

interface IBaseCommissionRule {
  ruleId?: number
  brandId: number
  active: boolean
}

interface ISellCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 1
  params: IPercentRewardCommissionParam
}

interface IFirstSellCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 2
  params: IFirstSellCommissionParams
}
interface ISeasonCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 3
  params: ISeasonCommissionParams
}
interface IPermanenceCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 4
  params: IPermanenceCommissionParams
}

interface IEffectiveReferalsCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 5
  params: IEffectiveReferalsCommissionParams
}

interface IConsecutivenessCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 6
  params: IConsecutivenessCommissionParams
}
interface IReferalCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 7
  params: IReferalCommissionParams
}

interface IReactivationCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 8
  params: IReactivationCommissionParams
}

interface IGoalsCommissionRule extends IBaseCommissionRule {
  ruleTypeId: 9
  params: IGoalsCommissionParams
}

export type TCommissionRule =
  | ISellCommissionRule
  | IPermanenceCommissionRule
  | IFirstSellCommissionRule
  | ISeasonCommissionRule
  | IReferalCommissionRule
  | IEffectiveReferalsCommissionRule
  | IConsecutivenessCommissionRule
  | IReactivationCommissionRule
  | IGoalsCommissionRule
