import { BrandLink, SocialLinks, SVGComponent } from '@atoms/index'
import { LinksList } from '@molecules/index'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useFooterLinksListArray } from './index.data'
import styles from './index.module.scss'

interface IFooterProps {
  alternative?: boolean
}

export default function Footer({ alternative = false }: IFooterProps) {
  const { status } = useSession()
  const linksListArray = useFooterLinksListArray()

  return (
    <footer
      role={'contentinfo'}
      className={`${styles.root}${
        alternative ? ` ${styles['root--alternative']}` : ''
      }`}>
      <nav className={styles.root__navigation}>
        <div role={'group'} className={styles['root__brand-block']}>
          <BrandLink
            className={styles['root__brand-link']}
            href={status === 'authenticated' ? '/portal' : '/'}
          />
          <p className={styles['root__brand-text']}>
            Genera ingresos extra de una manera fácil, rápida y segura.
          </p>
          <SocialLinks />
        </div>
        {linksListArray.map((linksList, key) => (
          <LinksList key={key} {...linksList} />
        ))}
      </nav>
      <Link
        href={'https://www.sic.gov.co/'}
        target="_blank"
        className={styles['root__sic-link']}
        title={
          'enlace a la página de la superintendencia de industria y comercio'
        }>
        <SVGComponent
          svgPath="footer/sic-logo"
          className={styles['root__sic-logo']}
        />
      </Link>
      <p className={styles['root__copy-wrapper']}>
        Todos los derechos reservados Americanino 2023 • COMODÍN S.A.S • NIT:
        800069933-6
      </p>
    </footer>
  )
}
