interface ISocialLink {
  name: string
  url: string
  type: 'svg' | 'feather'
  icon: string
}

export const socialLinksArray: ISocialLink[] = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/Kliikercommunity',
    type: 'feather',
    icon: 'facebook'
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/kliikercommunity',
    type: 'feather',
    icon: 'instagram'
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/kliiker_',
    type: 'feather',
    icon: 'twitter'
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/kliiker-community-104892255',
    type: 'feather',
    icon: 'linkedin'
  },
  {
    name: 'TikTok',
    url: 'https://www.tiktok.com/@kliiker',
    type: 'svg',
    icon: 'social-links/logo-tiktok'
  }
]
