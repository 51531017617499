import {
  IKliikerAccountActivationFormFields,
  IKliikerBasicDataFormFields,
  IKliikerEditDataFormFields,
  IKliikerEmail,
  IKliikerFisapayFormFields,
  IKliikerLoginFormFields,
  IKliikerPasswordCreationFields,
  IKliikerPasswordField,
  IKliikerPreRegisterFormFields,
  TFormGroup,
  IField,
  IKliikerRecoveryRequestFormFields,
  IKliikerVerificationCodeField,
  IKliikerResetPasswordFormFields,
  IKliikerReferalsFormFields,
  IKliikerLinkCreationFormFields,
  IKliikerFilterDateFormFields
} from '@/interfaces'
import Link from 'next/link'
import { Path } from 'react-hook-form'

function getKliikerEmailField<T extends IKliikerEmail>() {
  const emailField = new Map<keyof T, TFormGroup<T>>()
  emailField.set('email', {
    field: {
      id: 'email' as Path<T>,
      options: {
        required: true,
        pattern: {
          value: /^.+@.+$/,
          message: 'El formato de correo no es correcto'
        }
      }
    },
    label: 'Correo electrónico',
    type: 'email'
  })
  return new Map([...emailField])
}

function getKliikerBasicDataFormFields<
  T extends IKliikerBasicDataFormFields
>() {
  const emailField = getKliikerEmailField<T>()
  const basicDataFormFields = new Map<keyof T, TFormGroup<T>>()
  basicDataFormFields.set('docNumber', {
    field: {
      id: 'docNumber' as Path<T>,
      options: {
        required: true,
        pattern: {
          value: /^[0-9]+$/,
          message:
            'El número de documento solo puede tener números sin puntos ni espacios'
        }
      }
    },
    label: 'Número de cédula',
    info: 'Sólo lo utilizaremos para autenticar tus datos',
    type: 'text'
  })
  basicDataFormFields.set('firstName', {
    field: {
      id: 'firstName' as Path<T>,
      options: {
        required: true,
        pattern: {
          value: /^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$/i,
          message: 'El nombre solo puede tener letras y espacios'
        }
      }
    },
    label: 'Nombres',
    type: 'text'
  })
  basicDataFormFields.set('lastName', {
    field: {
      id: 'lastName' as Path<T>,
      options: {
        required: true,
        pattern: {
          value: /^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$/i,
          message: 'El apellido solo puede tener letras y espacios'
        }
      }
    },
    label: 'Apellidos',
    type: 'text'
  })
  basicDataFormFields.set('phoneNumber', {
    field: {
      id: 'phoneNumber' as Path<T>,
      options: {
        required: true,
        pattern: {
          value: /^[0-9]{1,10}$/,
          message:
            'El teléfono solo puede tener números sin puntos, espacios, ni caracteres espaciales (max. 10 dígitos)'
        }
      }
    },
    label: 'Número de celular',
    type: 'tel'
  })
  return new Map([...emailField, ...basicDataFormFields])
}

export function getKliikerPreRegisterFormFields<
  T extends IKliikerPreRegisterFormFields
>() {
  const basicDataFormFields = getKliikerBasicDataFormFields<T>()
  const passwordDataFormFields = getKliikerPasswordCreationFields<T>()
  const preRegisterFormFields = new Map<keyof T, TFormGroup<T>>()
  preRegisterFormFields.set('emailConfirmation', {
    field: {
      id: 'emailConfirmation' as Path<T>,
      options: {
        required: true,
        pattern: {
          value: /^.+@.+$/,
          message: 'El formato de correo no es correcto'
        }
      },
      customValidation: (value, formElement) => {
        const email =
          formElement?.querySelector<HTMLInputElement>('[name="email"]')?.value
        return value === email || 'La confirmación de correo no coincide'
      }
    },
    label: 'Confirma tu correo electrónico',
    type: 'email'
  })
  passwordDataFormFields.forEach((value, key) => {
    preRegisterFormFields.set(key, value)
  })
  preRegisterFormFields.set('termsAgreement', {
    field: {
      id: 'termsAgreement' as Path<T>,
      options: {
        required: {
          value: true,
          message: 'Debes aceptar los terminos y condiciones para continuar'
        }
      }
    },
    label: (
      <>
        Acepto los{' '}
        <Link href={'/terminos-y-condiciones'} target={'_blank'}>
          Términos y Condiciones
        </Link>{' '}
        y la{' '}
        <Link href={'/politicas-de-privacidad'} target={'_blank'}>
          Política de privacidad
        </Link>{' '}
        de Kliiker
      </>
    )
  })
  return new Map([
    ...basicDataFormFields,
    ...passwordDataFormFields,
    ...preRegisterFormFields
  ])
}

function getKliikerPasswordField<T extends IKliikerPasswordField>() {
  const passwordFormField = new Map<keyof T, TFormGroup<T>>()
  passwordFormField.set('password', {
    field: {
      id: 'password' as Path<T>,
      options: { required: true }
    },
    type: 'password',
    label: 'Ingresa tu contraseña'
  })
  return new Map([...passwordFormField])
}

export function getPasswordConditions(): Array<{
  text: string
  rule: (value: string) => boolean
}> {
  return [
    {
      text: 'Mínimo 8 y máximo 15 caracteres.',
      rule: (value) => value.length >= 8 && value.length <= 15
    },
    {
      text: 'Mínimo una (1) mayúscula.',
      rule: (value) => /[A-Z]/.test(value)
    },
    {
      text: 'Mínimo una (1) minúscula.',
      rule: (value) => /[a-z]/.test(value)
    },
    {
      text: 'Mínimo un (1) número',
      rule: (value) => /[0-9]/.test(value)
    }
  ]
}

export function getKliikerPasswordCreationFields<
  T extends IKliikerPasswordCreationFields
>() {
  const passwordField = getKliikerPasswordField<T>().get(
    'password'
  ) as TFormGroup<T>
  const passwordFieldFieldProp = passwordField.field as IField<T>
  const passwordCreationFields = new Map<keyof T, TFormGroup<T>>()
  passwordCreationFields.set('password', {
    ...passwordField,
    field: {
      ...passwordFieldFieldProp,
      customValidation: (value) => {
        if (typeof value !== 'string') return false
        const ruleError: string[] = []
        getPasswordConditions().forEach((validation) => {
          if (!validation.rule(value)) {
            ruleError.push(validation.text)
          }
        })
        const shiftedError = ruleError.shift()
        const firstError: string | boolean =
          shiftedError !== undefined ? shiftedError : true
        return ruleError.length + 1 === 0 || firstError !== undefined
          ? firstError
          : true
      }
    }
  })
  passwordCreationFields.set('passwordConfirmation', {
    field: {
      id: 'passwordConfirmation' as Path<T>,
      options: { required: true },
      customValidation: (value, formElement) => {
        const passwordInputValue =
          formElement?.querySelector<HTMLInputElement>('[name="password"]')
            ?.value ?? ''
        return value === passwordInputValue || 'Las contraseñas no coinciden'
      }
    },
    type: 'password',
    label: 'Confirma tu contraseña'
  })
  return new Map([...passwordCreationFields])
}

export function getKliikerAccountActivationFormFields<
  T extends IKliikerAccountActivationFormFields
>() {
  const accountActivationFormFields = getKliikerPasswordCreationFields<T>()
  return new Map([...accountActivationFormFields])
}

export function getKliikerFisapayFormFields<
  T extends IKliikerFisapayFormFields
>() {
  const fisapayFormFields = new Map<keyof T, TFormGroup<T>>()
  fisapayFormFields.set('contractAgreement', {
    field: {
      id: 'contractAgreement' as Path<T>,
      options: {
        required: {
          value: true,
          message: 'Debes aceptar el contrato de corretaje para continuar'
        }
      }
    },
    label: (
      <>
        Acepto <Link href={'/'}>Contrato de Corretaje Mercantil</Link>
      </>
    )
  })
  fisapayFormFields.set('termsAgreement', {
    field: {
      id: 'termsAgreement' as Path<T>,
      options: {
        required: {
          value: true,
          message: 'Debes aceptar los términos y políticas para continuar'
        }
      }
    },
    label: (
      <>
        Acepto{' '}
        <Link href={'/'}>
          Autorización para el Tratamiento de Datos Personales
        </Link>
        , <Link href={'/'}>Términos y Condiciones</Link> y{' '}
        <Link href={'/'}>Aviso de Privacidad</Link>
      </>
    )
  })
  return new Map([...fisapayFormFields])
}

export function getKliikerLoginFormFields<T extends IKliikerLoginFormFields>() {
  const emailField = getKliikerEmailField<T>()
  const passwordField = getKliikerPasswordField<T>()
  return new Map([...emailField, ...passwordField])
}

export function getKliikerRecoveryRequestFormFields<
  T extends IKliikerRecoveryRequestFormFields
>() {
  const emailField = getKliikerEmailField<T>()
  return new Map([...emailField])
}

export function getKliikerVerificationCodeField<
  T extends IKliikerVerificationCodeField
>() {
  const verificationCodeField = new Map<keyof T, TFormGroup<T>>()
  verificationCodeField.set('verificationCode', {
    field: {
      id: 'verificationCode' as Path<T>,
      options: {
        required: {
          value: true,
          message:
            'Debes proporcionar un código de verificación para continuar.'
        },
        validate: (value) => {
          return (
            value.length === 6 ||
            'Debes proporcionar un código de verificación válido para continuar.'
          )
        }
      }
    }
  })
  return new Map([...verificationCodeField])
}

export function getKliikerResetPasswordFormFields<
  T extends IKliikerResetPasswordFormFields
>() {
  const verificationCodeField = getKliikerVerificationCodeField<T>()
  const passwordCreationFields = getKliikerPasswordCreationFields<T>()
  return new Map([...verificationCodeField, ...passwordCreationFields])
}

export function getKliikerReferalsFormFields<
  T extends IKliikerReferalsFormFields
>() {
  const emailField = getKliikerEmailField<T>()
  return new Map([...emailField])
}

export function getKliikerLinkCreationFormFields<
  T extends IKliikerLinkCreationFormFields
>() {
  const linkCreationFormFields = new Map<keyof T, TFormGroup<T>>()
  linkCreationFormFields.set('linkId', {
    field: {
      id: 'linkId' as Path<T>,
      options: {
        required: true,
        pattern: {
          value: /^[A-Za-z0-9-_]+$/,
          message:
            'Tu link solo puede estar compuesto por letras, números, guiones medios (-) y guiones bajos (_)'
        }
      }
    },
    label: 'Link único de Kliiker',
    prepend: 'kliikerId',
    noBorder: true
  })
  return new Map([...linkCreationFormFields])
}

export function getKliikerEditDataFormFields<
  T extends IKliikerEditDataFormFields
>() {
  const basicDataFormFields = getKliikerBasicDataFormFields<T>()
  const editDataFormFields = new Map<keyof T, TFormGroup<T>>()
  editDataFormFields.set('picture', { field: { id: 'picture' as Path<T> } })
  editDataFormFields.set('stateCode', {
    field: { id: 'stateCode' as Path<T>, options: { required: true } },
    label: 'Seleccionar departamento',
    activeLabel: 'Departamento'
  })
  editDataFormFields.set('cityCode', {
    field: { id: 'cityCode' as Path<T>, options: { required: true } },
    label: 'Seleccionar ciudad',
    activeLabel: 'Ciudad'
  })
  return new Map([...basicDataFormFields, ...editDataFormFields])
}

export function getKliikerFilterFormFields<
  T extends IKliikerFilterDateFormFields
>() {
  const filterDateFormFields = new Map<keyof T, TFormGroup<T>>()
  filterDateFormFields.set('startDate', {
    field: { id: 'startDate' as Path<T> },
    label: 'Desde',
    type: 'date'
  })
  filterDateFormFields.set('endDate', {
    field: { id: 'endDate' as Path<T> },
    label: 'Hasta',
    type: 'date'
  })
  return new Map([...filterDateFormFields])
}
