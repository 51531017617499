import { useEffect, useState } from 'react'
import styles from './index.module.scss'

interface ITabsMenuProps {
  tabs?: string[]
  initialActiveTab?: number
  onChange?: (index: number) => void
}

export default function TabsMenu({
  tabs = [],
  initialActiveTab = -1,
  onChange = () => {}
}: ITabsMenuProps) {
  const [currentIndex, setCurrentIndex] = useState(-1)

  useEffect(() => {
    setCurrentIndex(initialActiveTab)
  }, [initialActiveTab])

  return (
    <menu
      role={'menu'}
      aria-label={'Tabs de navegación'}
      className={styles.root}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          type={'button'}
          className={
            styles.root__tab +
            (currentIndex === index ? ` ${styles['root__tab--active']}` : '')
          }
          title={`Cambiar al tab '${tab}'`}
          onClick={() => {
            setCurrentIndex(index)
            onChange(index)
          }}>
          {tab}
        </button>
      ))}
    </menu>
  )
}
