import { THeaders, TQueryParams } from '@/interfaces'
import {
  IKliikerCommissionsTableQuery,
  IKliikerCommissionsTableResponse,
  buildKliikerCommissionTableQuery,
  kliikerService
} from '..'
import { formatServiceDate } from '@/functions'

interface IAdminPerformanceBase {
  firstname: string
  lastname: string
  totalSales: number
  ordersCount: number
  activityPercent: number
  averageTicket: number
}

interface IAdminPerformanceDataItem extends IAdminPerformanceBase {
  document: string
  city: string
}

interface IAdminPerformanceResume extends IAdminPerformanceBase {
  email: string
  phone: string
  photoProfile: string
}

interface IAdminPerformanceResponse {
  userPerformancePage: {
    currentPage: number
    countPage: number
    countRegister: number
    data: IAdminPerformanceDataItem[]
    hasNextPage: string
  }
  citiesNames: string[]
}

interface IAdminPerformanceReportResponse {
  url: string
}

export type TAdminPerformanceFieldOrder =
  | 'total_sales'
  | 'orders_count'
  | 'average_ticket'
  | 'activity_percentage'

export interface IAdminPerformanceQueryOptions {
  startDate?: Date
  endDate?: Date
  city?: string
  fieldOrder?: TAdminPerformanceFieldOrder
  ascending?: boolean
  page?: number
  size?: number
  documentNumber?: string
}

function buildAdminPerformanceQueryParams(
  queryOptions?: IAdminPerformanceQueryOptions
) {
  const withQueryParams: TQueryParams = []
  if (queryOptions !== undefined) {
    const {
      startDate,
      endDate,
      city,
      fieldOrder,
      ascending,
      page,
      size,
      documentNumber
    } = queryOptions
    startDate !== undefined &&
      withQueryParams.push(['startDate', formatServiceDate(startDate)])
    endDate !== undefined &&
      withQueryParams.push(['endDate', formatServiceDate(endDate)])
    city !== undefined && withQueryParams.push(['city', city])
    fieldOrder !== undefined && withQueryParams.push(['fieldOrder', fieldOrder])
    ascending !== undefined &&
      withQueryParams.push(['ascending', ascending ? 'true' : 'false'])
    page !== undefined && withQueryParams.push(['page', `${page}`])
    size !== undefined && withQueryParams.push(['size', `${size}`])
    documentNumber !== undefined &&
      documentNumber !== '' &&
      withQueryParams.push(['documentNumber', documentNumber])
  }
  return withQueryParams
}

export async function getAdminPerformanceService(params: {
  idToken: string
  queryOptions?: IAdminPerformanceQueryOptions
  bypass?: IAdminPerformanceResponse
}) {
  const { idToken: withAuthToken, bypass, queryOptions } = params
  const withQueryParams = buildAdminPerformanceQueryParams(queryOptions)
  return await kliikerService<{}, {}, IAdminPerformanceResponse>({
    type: 'obj',
    endpoint: '/admin/performance',
    method: 'GET',
    withAuthToken,
    withQueryParams,
    bypass
  })
}

export async function getAdminPerformanceReportService(params: {
  idToken: string
  queryOptions?: IAdminPerformanceQueryOptions
  bypass?: IAdminPerformanceReportResponse
}) {
  const { idToken: withAuthToken, bypass, queryOptions } = params
  const withQueryParams = buildAdminPerformanceQueryParams(queryOptions)
  return await kliikerService<{}, {}, IAdminPerformanceReportResponse>({
    type: 'obj',
    endpoint: '/admin/performance/download-report',
    method: 'GET',
    withAuthToken,
    withQueryParams,
    bypass
  })
}

export async function getAdminPerformanceResume(params: {
  idToken: string
  document: string
  bypass?: IAdminPerformanceResume
}) {
  const { idToken: withAuthToken, bypass, document } = params
  return await kliikerService<{}, {}, IAdminPerformanceResume>({
    type: 'obj',
    endpoint: '/admin/performance/resume',
    method: 'GET',
    withAuthToken,
    withQueryParams: [['document', document]],
    bypass
  })
}

export async function getAdminKliikerCommissions(params: {
  idToken: string
  email: string
  queryParams?: IKliikerCommissionsTableQuery
  bypass?: IKliikerCommissionsTableResponse
}) {
  const { idToken: withAuthToken, email, bypass, queryParams } = params
  const withQueryParams = buildKliikerCommissionTableQuery(queryParams)
  const withHeaders: THeaders = [['target_email', email]]
  return await kliikerService<{}, {}, IKliikerCommissionsTableResponse>({
    endpoint: '/dashboard/commissions-table',
    type: 'obj',
    bypass,
    method: 'GET',
    withAuthToken,
    withQueryParams,
    withHeaders
  })
}
