import { IKliikerAccountActivationFormFields } from '@/interfaces'
import {
  kliikerAccountActivationFormService,
  kliikerLoginFormService
} from '@/services'
import { IMessageToast, SVGComponent } from '@atoms/index'
import { ActivationForm } from '@molecules/index'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styles from './index.module.scss'

interface IAccountActivationSection {
  requestId?: string
}

export default function AccountActivationSection({
  requestId
}: IAccountActivationSection) {
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const router = useRouter()

  const handleFormSubmit: SubmitHandler<
    IKliikerAccountActivationFormFields
  > = async (data) => {
    try {
      const user = await kliikerAccountActivationFormService(data)
      const loginResponse = await kliikerLoginFormService({
        email: user.email,
        password: user.password !== undefined ? user.password : '',
        gCaptcha: data.gCaptcha
      })
      if (!loginResponse)
        throw new Error(
          'Algo salió mal al iniciar tu sesión, pero tu cuenta fue creada con éxito.'
        )
      router.replace('/ingreso/onboarding')
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección de activación de cuenta'}
      className={styles.root}>
      <SVGComponent
        svgPath="brands-section/decoration"
        role={'img'}
        aria-label="Isotipo de Kliiker"
        className={styles.root__decoration}
      />
      <div
        role={'group'}
        aria-label="Contenedor del formulario de activación de cuenta"
        className={styles['root__form-wrapper']}>
        <ActivationForm
          requestId={requestId}
          messages={messages}
          onMessageDismiss={(index) =>
            setMessages((current) =>
              current.filter((_, currentIndex) => index !== currentIndex)
            )
          }
          onSubmit={handleFormSubmit}
        />
      </div>
    </section>
  )
}
