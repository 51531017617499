import { IFaq } from '@/interfaces'

export const mockedFaq: IFaq = {
  title: '¿Test question title?',
  shortAnswer:
    'Lorem ipsum dolor sit amet consectetur adipiscing, elit malesuada natoque semper rhoncus erat hac, justo felis arcu auctor vulputate. Aliquam scelerisque lacus egestas conubia felis bibendum nam, lobortis risus nibh quis gravida quam quisque, leo cum maecenas viverra porttitor vitae. Augue ac ornare aliquam eu quam facilisis congue, natoque tempus.'
}

export const mockedFaqsArray: IFaq[] = [
  mockedFaq,
  mockedFaq,
  mockedFaq,
  mockedFaq,
  mockedFaq
]
