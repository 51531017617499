import { TQueryParams } from '@/interfaces'
import { kliikerService } from '..'
import { ICommissionTableData } from '../commissions'
import { formatServiceDate } from '@/functions'

interface IAdminCommissionAuthorizationResponse {
  url: string
}

export interface IAdminGeneratedCommissionsQuery {
  startDate?: Date
  endDate?: Date
  page?: number
  size?: number
  documentSearch?: string
  state?: string
}

export interface IAdminDownloadFileCommissionsQuery {
  startDate: Date
  endDate: Date
}

function buildAdminGeneratedCommissionsQueryParams(
  queryOptions?: IAdminGeneratedCommissionsQuery
) {
  const withQueryParams: TQueryParams = []
  if (queryOptions !== undefined) {
    const { startDate, endDate, page, size, documentSearch, state } =
      queryOptions
    startDate !== undefined &&
      withQueryParams.push(['startDate', formatServiceDate(startDate)])
    endDate !== undefined &&
      withQueryParams.push(['endDate', formatServiceDate(endDate)])
    page !== undefined && withQueryParams.push(['page', `${page}`])
    size !== undefined && withQueryParams.push(['size', `${size}`])
    documentSearch !== undefined &&
      documentSearch !== '' &&
      withQueryParams.push(['documentNumber', documentSearch])
    state !== undefined &&
      state !== '' &&
      withQueryParams.push(['state', state])
  }
  return withQueryParams
}

function buildAdminDownloadFileCommissionsQueryParams(
  queryOptions: IAdminDownloadFileCommissionsQuery
) {
  const withQueryParams: TQueryParams = []
  if (queryOptions !== undefined) {
    const { startDate, endDate } = queryOptions
    startDate !== undefined &&
      withQueryParams.push(['startDate', formatServiceDate(startDate)])
    endDate !== undefined &&
      withQueryParams.push(['endDate', formatServiceDate(endDate)])
  }
  return withQueryParams
}

export async function getAdminGeneratedCommissions(params: {
  idToken: string
  queryOptions?: IAdminGeneratedCommissionsQuery
  bypass?: ICommissionTableData
}) {
  const { idToken: withAuthToken, queryOptions, bypass } = params
  const withQueryParams =
    buildAdminGeneratedCommissionsQueryParams(queryOptions)
  return await kliikerService<{}, {}, ICommissionTableData>({
    type: 'obj',
    endpoint: '/admin/generated-commissions',
    method: 'GET',
    withAuthToken,
    withQueryParams,
    bypass
  })
}

export async function sendAdminCommissionsAuthorization(params: {
  idToken: string
  commissionIds: number[]
  bypass?: IAdminCommissionAuthorizationResponse
}) {
  const { idToken: withAuthToken, commissionIds: ids, bypass } = params
  return await kliikerService<
    { ids: number[] },
    { commissionsIds: number[] },
    IAdminCommissionAuthorizationResponse
  >({
    type: 'obj',
    endpoint: '/admin/authorize-commissions',
    method: 'POST',
    bypass,
    withAuthToken,
    withBody: {
      data: { ids },
      parser: (data) => ({ commissionsIds: data.ids })
    }
  })
}

export async function getAdminCommissionsToAuthorize(params: {
  idToken: string
  startDate: Date
  endDate: Date
  bypass?: IAdminCommissionAuthorizationResponse
}) {
  const { idToken: withAuthToken, startDate, endDate, bypass } = params
  startDate.setDate(startDate.getDate() - 1)
  endDate.setDate(endDate.getDate() - 1)

  const withQueryParams = buildAdminDownloadFileCommissionsQueryParams({
    startDate,
    endDate
  })
  return await kliikerService<{}, {}, IAdminCommissionAuthorizationResponse>({
    type: 'obj',
    endpoint: '/admin/download-commissions',
    method: 'GET',
    withAuthToken,
    withQueryParams,
    bypass
  })
}
