import { KLIIKER_SERVICES_ENDPOINT } from '@/utils/constants'
import {
  IErrorCodeResponse,
  IKliikerBooleanServiceParams,
  IKliikerObjectServiceParams
} from '@/interfaces'

export * from './register'
export * from './login'
export * from './user'
export * from './dashboard'
export * from './commissions'
export * from './admin/performance'
export * from './admin/authorization'
export * from './admin/brands'

export async function kliikerService<TData = {}, TBody = {}>(
  params: IKliikerBooleanServiceParams<TData, TBody>
): Promise<boolean>
export async function kliikerService<TData = {}, TBody = {}, TResponse = {}>(
  params: IKliikerObjectServiceParams<TData, TBody, TResponse>
): Promise<TResponse>
export async function kliikerService<TData = {}, TBody = {}, TResponse = {}>(
  params:
    | IKliikerBooleanServiceParams<TData, TBody>
    | IKliikerObjectServiceParams<TData, TBody, TResponse>
): Promise<TResponse | boolean> {
  const {
    endpoint,
    type,
    method = 'GET',
    withBody,
    withAuthToken,
    withQueryParams,
    withHeaders = [],
    bypass
  } = params
  if (bypass !== undefined) return bypass
  if (KLIIKER_SERVICES_ENDPOINT === '') {
    throw new Error('¡Algo salió mal! 😰', { cause: 'Services-URL-Config' })
  }
  let input = `${KLIIKER_SERVICES_ENDPOINT}${endpoint}`
  const headersMeta: Array<[string, string]> = withHeaders
  let body: BodyInit | null | undefined
  if (withBody !== undefined) {
    headersMeta.push(['Content-Type', 'application/json'])
    body = JSON.stringify(
      withBody.parser === undefined
        ? withBody.data
        : withBody.parser(withBody.data)
    )
  }
  if (withAuthToken !== undefined) {
    headersMeta.push(['Authorization', withAuthToken])
  }
  if (withQueryParams !== undefined) {
    withQueryParams.forEach((queryParam, index) => {
      const [paramKey, paramValue] = queryParam
      if (paramKey === '') return
      input += `${index === 0 ? '?' : '&'}${paramKey}${
        paramValue !== '' ? '=' : ''
      }${encodeURIComponent(paramValue)}`
    })
  }
  const response = await fetch(input, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    headers: headersMeta.length === 0 ? undefined : headersMeta,
    body
  })
  if (response.status !== 200) {
    const error = (await response.json()) as IErrorCodeResponse
    throw new Error(
      error.messageEs !== ''
        ? error.messageEs
        : error.messageEn !== ''
        ? error.messageEn
        : '¡Algo salió mal! 😰',
      { cause: 'Not-200-Response' }
    )
  }
  return type === 'bool' ? true : ((await response.json()) as TResponse)
}
