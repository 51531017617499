import {
  IConsecutivenessCommissionParams,
  IEffectiveReferalsCommissionParams,
  IFirstSellCommissionParams,
  IGoalsCommissionParams,
  IPermanenceCommissionParams,
  IReactivationCommissionParams,
  IReferalCommissionParams,
  ISeasonCommissionParams
} from './params'

export enum ECommissionStatus {
  't1' = 'pending',
  't2' = 'cancelled',
  't3' = 'generated',
  't4' = 'authorized'
}

export type TCommissionStatus =
  | 'pending'
  | 'cancelled'
  | 'generated'
  | 'authorized'

interface IBaseCommission {
  commissionId?: number
  document?: string
  commissionStateId: number
  amount: number
  createdAt?: string
  updatedAt?: string
}

interface ICommissionParams<T> {
  brand: string
  ruleParams: T
}

interface ICommissionParamsWithOrderId<T> extends ICommissionParams<T> {
  orderId: string
}

interface ICommissionParamsWithTotalSellsAmount<T>
  extends ICommissionParams<T> {
  totalSellsAmount: number
  description: string
}

interface ISellCommission extends IBaseCommission {
  ruleTypeId: 1
  params: {
    externalId?: string
    orderId: string
    itemParams: Array<{
      sku: string
      name: string
      qty: number
      brand: string
      commission: number
      amount: number
    }>
    sellRuleParams: Array<{
      percentage: number
      brand: string
    }>
  }
}

interface IFirstSellCommission extends IBaseCommission {
  ruleTypeId: 2
  params: ICommissionParams<IFirstSellCommissionParams>
}

interface ISeasonCommission extends IBaseCommission {
  ruleTypeId: 3
  params: ICommissionParamsWithOrderId<ISeasonCommissionParams>
}

interface IPermanenceCommission extends IBaseCommission {
  ruleTypeId: 4
  params: ICommissionParamsWithTotalSellsAmount<IPermanenceCommissionParams>
}

interface IEffectiveReferalsCommission extends IBaseCommission {
  ruleTypeId: 5
  params: ICommissionParams<IEffectiveReferalsCommissionParams>
}

interface IConsecutivenessCommission extends IBaseCommission {
  ruleTypeId: 6
  params: ICommissionParams<IConsecutivenessCommissionParams>
}

interface IReferalCommission extends IBaseCommission {
  ruleTypeId: 7
  params: ICommissionParams<IReferalCommissionParams>
}

interface IReactivationCommission extends IBaseCommission {
  ruleTypeId: 8
  params: ICommissionParams<IReactivationCommissionParams>
}

interface IGoalsCommission extends IBaseCommission {
  ruleTypeId: 9
  params: ICommissionParams<IGoalsCommissionParams>
}

export type TCommission =
  | ISellCommission
  | IPermanenceCommission
  | IFirstSellCommission
  | ISeasonCommission
  | IReferalCommission
  | IEffectiveReferalsCommission
  | IConsecutivenessCommission
  | IReactivationCommission
  | IGoalsCommission
