import {
  formatAmount,
  formatNewDate,
  parseCommissionRuleDetails
} from '@/functions'
import { TCommissionRule } from '@/interfaces'
import styles from './index.module.scss'

interface ICommissionDetailRowProps {
  commissionRule?: TCommissionRule
  visible?: boolean
}

type TTrType = 'message' | 'sell-header' | 'sell-item' | 'sell-footer'

export default function RulesCommissionDetail({
  commissionRule,
  visible = false
}: ICommissionDetailRowProps) {
  const getTrProps = (type: TTrType) => ({
    className: styles.root,
    'data-status': visible ? 'visible' : 'hidden',
    'data-type': type
  })
  if (commissionRule === undefined) return <></>
  const {
    beginDate,
    endDate,
    commissionAmount,
    description,
    percentage,
    period,
    periods,
    quantity,
    steps,
    thresholdAmount
  } = parseCommissionRuleDetails(commissionRule)

  return (
    <tr {...getTrProps('message')} role="group">
      <td colSpan={6}>
        {periods !== undefined && commissionRule.ruleTypeId === 8 && (
          <p>
            <strong>Meses previos sin actividad:</strong> {periods}
          </p>
        )}
        {periods !== undefined && commissionRule.ruleTypeId === 6 && (
          <p>
            <strong>Meses consecutivos con actividad:</strong> {periods}
          </p>
        )}
        {thresholdAmount !== undefined && (
          <p>
            <strong>Monto mínimo de venta:</strong>{' '}
            {formatAmount(thresholdAmount)}
          </p>
        )}
        {commissionAmount !== undefined && commissionAmount !== 0 && (
          <p>
            <strong>Valor de comisión:</strong> {formatAmount(commissionAmount)}
          </p>
        )}
        {description !== undefined && description !== '' && (
          <p>
            <strong>Premio:</strong> {description}
          </p>
        )}
        {beginDate !== undefined && endDate !== undefined && (
          <p>
            <strong>Rango de fecha:</strong> {formatNewDate(beginDate)} -{' '}
            {formatNewDate(endDate)}
          </p>
        )}
        {steps !== undefined && (
          <p>
            <strong>Ventas superiores a:</strong>{' '}
            {steps
              .map(
                (item) =>
                  `${formatAmount(item.thresholdAmount)} (${
                    item.percentage
                  }%) - ${item.description}`
              )
              .join(', ')}
          </p>
        )}
        {quantity !== undefined && (
          <p>
            <strong>Numero de referidos:</strong> {quantity}
          </p>
        )}
        {percentage !== undefined && (
          <p>
            <strong>Porcentaje de comisión por marcas:</strong> {percentage}%
          </p>
        )}
        {period !== undefined && (
          <p>
            <strong>Periodo evaluado:</strong> {period}
          </p>
        )}
      </td>
    </tr>
  )
}
