import Image from 'next/image'
import Link from 'next/link'
import styles from './index.module.scss'
import { IKliikerIdentification, IKliikerPicture } from '@/interfaces'
import FeatherIcon from 'feather-icons-react'
import { useSession } from 'next-auth/react'

interface IUserLinkProps
  extends Partial<IKliikerIdentification>,
    Partial<IKliikerPicture> {
  active?: boolean
}

export default function UserLink({
  firstName = '',
  lastName = '',
  picture = '',
  active = false
}: IUserLinkProps) {
  const session = useSession()
  const userRole = session.data?.user.roleId
  const link = userRole === 1 ? '/admin' : '/portal/perfil'
  return (
    <Link
      href={link}
      title={'Enlace para ir a tu perfil de Kliiker'}
      className={styles.root + (active ? ` ${styles['root--active']}` : '')}>
      <figure className={styles['root__image-wrapper']}>
        {picture === '' ? (
          <FeatherIcon
            icon={'user'}
            className={styles.root__icon}
            role={'img'}
          />
        ) : (
          <Image
            src={picture}
            alt={'Imagen de perfil del Kliiker'}
            width={36}
            height={36}
            className={styles.root__image}
          />
        )}
      </figure>
      <p className={styles['root__info-wrapper']}>
        <span className={styles.root__name}>
          {firstName} {lastName}
        </span>
        {userRole !== 1 && (
          <span className={styles.root__label}>Mi perfil</span>
        )}
      </p>
    </Link>
  )
}
