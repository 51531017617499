import { useCustomFieldRegistration, useFieldMessage } from '@/hooks'
import {
  IKliikerVerificationCodeField,
  IVerificationCodeFormGroupProps
} from '@/interfaces'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FieldValues, Path, PathValue } from 'react-hook-form'
import styles from './index.module.scss'

export default function VerificationCodeField<
  T extends FieldValues = IKliikerVerificationCodeField
>({
  field,
  remainingTime = 0,
  reset = false
}: IVerificationCodeFormGroupProps<T>) {
  const [fieldValues, setFieldValues] = useState(['', '', '', '', '', ''])
  const groupRef = useRef<HTMLDivElement>(null)
  const { isError, message } = useFieldMessage<T>(field)
  const fieldRegistration = useCustomFieldRegistration(field)
  const remaininTimeString = useMemo(() => {
    const fixedMinutes = Math.floor(remainingTime / 60)
    const fixedSeconds = remainingTime - fixedMinutes * 60
    const remainingMinutes =
      fixedMinutes > 9 ? `${fixedMinutes}` : `0${fixedMinutes}`
    const remainingSeconds =
      fixedSeconds > 9 ? `${fixedSeconds}` : `0${fixedSeconds}`
    return `${remainingMinutes}:${remainingSeconds}`
  }, [remainingTime])

  useEffect(() => {
    if (reset) setFieldValues(['', '', '', '', '', ''])
  }, [reset])

  return (
    <div
      ref={groupRef}
      role={'group'}
      className={styles.root + (isError ? ` ${styles['root--error']}` : '')}
      aria-label={'Campos para ingreso de código de verificación'}>
      <label className={styles.root__label}>Código de verificación</label>
      <div className={styles.root__wrapper}>
        {fieldValues.map((value, key) => (
          <input
            key={key}
            className={styles['root__code-field']}
            value={value}
            onKeyUp={({ key, currentTarget }) => {
              const nextField =
                currentTarget.nextElementSibling as HTMLInputElement | null
              const previousField =
                currentTarget.previousElementSibling as HTMLInputElement | null
              if (
                key === 'Backspace' &&
                currentTarget.value === '' &&
                previousField !== null
              ) {
                previousField.focus()
                previousField.select()
              } else if (
                key !== 'Backspace' &&
                currentTarget.value !== '' &&
                nextField !== null
              )
                nextField.focus()
            }}
            onChange={({ currentTarget }) => {
              const nextField =
                currentTarget.nextElementSibling as HTMLInputElement | null
              const previousField =
                currentTarget.previousElementSibling as HTMLInputElement | null
              if (currentTarget.value === ' ') return
              else if (currentTarget.value === '' && previousField !== null) {
                previousField.focus()
                previousField.select()
              } else if (currentTarget.value !== '' && nextField !== null)
                nextField.focus()
              setFieldValues((currentValues) =>
                currentValues.map((currentValue, currentIndex) => {
                  return currentIndex === key
                    ? currentTarget.value
                    : currentValue
                })
              )
              if (groupRef.current === null || field === undefined) return
              const newValue = Array.from(
                groupRef.current.querySelectorAll<HTMLInputElement>(
                  `.${styles.root__wrapper} input`
                )
              )
                .map((input) => input.value)
                .join('') as PathValue<T, Path<T>>
              field.formObject.setValue(field.id, newValue)
            }}
            maxLength={1}
            minLength={1}
          />
        ))}
      </div>
      <input id={field?.id} type={'hidden'} {...fieldRegistration} />
      {message !== '' && (
        <span className={styles.root__message}>{message}</span>
      )}
      <span className={styles['root__time-box']}>
        Tiempo para envío {remaininTimeString}
      </span>
    </div>
  )
}
