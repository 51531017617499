import FeatherIcon from 'feather-icons-react'
import UserPlaceholder from '@/assets/img/user-placeholder.png'
import Image from 'next/image'
import styles from './index.module.scss'
import { useRouter } from 'next/router'
import { formatAmount } from '@/functions'

export interface ICardPerformanceKliiker {
  image: string
  name: string
  email: string
  phone: string
  totalSale: number
  orders: number
  activity: number
  averageTicket: number
}
interface ICardPerformanceKliikerProps {
  kliikerInfo: ICardPerformanceKliiker
}
export default function CardPerformanceKliiker({
  kliikerInfo = {
    image: '',
    name: 'Nombre Kliiker',
    email: 'example@gmail.com',
    phone: '(+00) 0000-0000',
    totalSale: 0,
    orders: 0,
    activity: 0,
    averageTicket: 0
  }
}: ICardPerformanceKliikerProps) {
  const router = useRouter()
  const {
    image,
    name,
    email,
    phone,
    totalSale,
    orders,
    activity,
    averageTicket
  } = kliikerInfo
  return (
    <div className={styles.root__card}>
      <div className={styles.root__kliiker}>
        <div className={styles['root__info-kliiker']}>
          <figure className={styles['root__container-image']}>
            <Image
              src={image !== '' ? image : UserPlaceholder}
              alt="Foto de perfil del Kliiker"
              width={72}
              height={72}
              className={styles.root__image}
            />
          </figure>
          <div className={styles['root__details-kliiker']}>
            <p className={styles['root__name-kliiker']}>{name}</p>
            <span className={styles['root__email-kliiker']}>{email}</span>
            <span className={styles['root__phone-kliiker']}>{phone}</span>
          </div>
        </div>
        <div className={styles.root__performance}>
          <div className={styles.root__item}>
            <p className={styles['root__title-item']}>
              {totalSale !== undefined && formatAmount(totalSale)}
            </p>
            <span className={styles['root__label-item']}>Total venta</span>
          </div>
          <div className={styles.root__item}>
            <p className={styles['root__title-item']}>
              {orders !== undefined && orders}
            </p>
            <span className={styles['root__label-item']}>Pedidos</span>
          </div>
          <div className={styles.root__item}>
            <p className={styles['root__title-item']}>
              {activity !== undefined && activity}%
            </p>
            <span className={styles['root__label-item']}>% Actividad</span>
          </div>
          <div className={styles.root__item}>
            <p className={styles['root__title-item']}>
              {averageTicket !== undefined && formatAmount(averageTicket)}
            </p>
            <span className={styles['root__label-item']}>Ticket promedio</span>
          </div>
        </div>
      </div>
      <div className={styles['root__container-button']}>
        <button
          className={styles['root__return-button']}
          aria-label="Botón para regresar"
          onClick={() => router.back()}>
          <FeatherIcon
            icon={'chevron-left'}
            className={styles['root__return-icon']}
          />
          VOLVER AL LISTADO
        </button>
      </div>
    </div>
  )
}
