import Link from 'next/link'
import { SVGComponent } from '@atoms/index'
import styles from './index.module.scss'

interface IFloatingBUttonProps {
  hide?: boolean
}

export default function FloatingButton({ hide = false }: IFloatingBUttonProps) {
  const buildLinkClassName = () => {
    let linkClassName = styles.root
    linkClassName += hide ? ` ${styles['root--hidden']}` : ''
    return linkClassName
  }

  return (
    <Link
      href={'https://wa.me/573123315399'}
      className={buildLinkClassName()}
      title="Enlace al WhatsApp de Kliiker"
      target={'_blank'}>
      <SVGComponent
        svgPath="floating-button/whatsapp"
        role={'img'}
        className={styles.root__icon}
      />
    </Link>
  )
}
