import React from 'react'
import styles from './index.module.scss'
import Image from 'next/image'

interface Level {
    nameLevel: string;
    imgLevel: {
        url: string;
        width: number;
        height: number;
    };
    accumulatedSales: number;
}

interface Props {
    level: Level;
    index: number;
    handleShowModal: (level: Level) => void;
}

const LevelItems = ({ level, index, handleShowModal }: Props) => {
    return (
        <div key={index} className={styles.root__image__banner_info_container_item}>
            <div className={styles[`root__image__banner_info_container_item-${level.nameLevel}`]}>
                <Image
                    className={styles['root__image__item-level']}
                    src={level.imgLevel.url}
                    alt={'Imagen informativa de los niveles'}
                    width={level.imgLevel.width}
                    height={level.imgLevel.height}
                    priority
                />
            </div>
            {/* <span className={styles.root__image__banner_info_container_item__name}>{level.nameLevel}</span> */}
            {/* <p className={styles.root__image__banner_info_container_item__description}>Ventas acumuladas <br /> hasta {level.accumulatedSales}</p> */}
            <button
                className={styles.root__image__banner_info_container_item__btn}
                onClick={() => handleShowModal(level)}
            >
                VER MÁS</button>
        </div>
    )
}

export default LevelItems
