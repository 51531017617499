import { useEffect, useState } from 'react'
import { TableWrapper } from '..'
import { useSession } from 'next-auth/react'

import styles from './index.module.scss'
import BrandsTable from '@molecules/brands-table'
import { IAdminBrandGroupsFormFields, IBrands, IGroups } from '@/interfaces'
import {
  TAlertStatus,
  BrandsGroupModal,
  Alert,
  GroupsTable
} from '@molecules/index'
import TabsMenu from '@atoms/tabs-menu'
import {
  addEditAdminBrandGroupService,
  deleteAdminBrandService,
  getAdminBrandGroupsService,
  getAdminBrandsService
} from '@/services'
import { IMessageToast } from '@atoms/message-toasts'
import { SubmitHandler } from 'react-hook-form'

export default function BrandsVinculationSection() {
  const [tableData, setTableData] = useState<IBrands[]>([])
  const [tableGroupData, setTableGroupData] = useState<IGroups[]>([])
  const [tableRefreshCount, setTableRefreshCount] = useState(0)
  const [countRegister, setCountRegister] = useState(1)
  const [alertStatus, setAlertStatus] = useState<TAlertStatus>('closed')
  const [alertMessages, setAlertMessages] = useState<IMessageToast[]>([])
  const initialTab = 0
  const [currentTab, setCurrentTab] = useState(initialTab)
  const [actionType, setActionType] = useState<'create' | 'edit' | 'delete'>(
    'create'
  )
  const [activeId, setActiveId] = useState(-1)
  const [activeObject, setActiveObject] = useState<IBrands | IGroups>()
  const { data } = useSession()
  const idToken = data?.idToken

  const handleEditItem = (object: IBrands | IGroups) => {
    setActionType('edit')
    setActiveObject(object)
    if (alertStatus === 'closed') {
      setAlertStatus('open')
    } else {
      setAlertStatus('closed')
    }
  }
  const handleDeleteItem = (id: number) => {
    setActionType('delete')
    setAlertMessages([])
    setActiveId(id)
    if (alertStatus === 'closed') {
      setAlertStatus('open')
    } else {
      setAlertStatus('closed')
    }
  }

  const content = {
    0: {
      titleTable: 'Lista de marcas',
      titleButton: 'AGREGAR MARCA',
      table: (
        <BrandsTable
          brands={tableData}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
        />
      )
    },
    1: {
      titleTable: 'Grupos de marcas',
      titleButton: 'AGREGAR GRUPO',
      table: (
        <GroupsTable
          groups={tableGroupData}
          onEditItem={handleEditItem}
          onDeleteItem={handleDeleteItem}
        />
      )
    }
  }[currentTab]

  const handleFormSubmit: SubmitHandler<IAdminBrandGroupsFormFields> = async (
    data
  ) => {
    try {
      if (idToken === undefined)
        throw new Error('¡Algo salió mal!😰', {
          cause: 'No-Id-Token'
        })
      await addEditAdminBrandGroupService({ idToken, data })
      setTableRefreshCount((oldCount) => oldCount + 1)
      setAlertStatus('closed')
    } catch (ex) {
      setAlertMessages((oldMessages) => [
        ...oldMessages,
        { type: 'error', message: (ex as Error).message }
      ])
      setAlertStatus('open')
    }
  }

  useEffect(() => {
    async function fetchTable() {
      try {
        if (idToken === undefined) return
        if (currentTab === 0) {
          const response = await getAdminBrandsService({ idToken })
          setTableData(response)
          setCountRegister(response.length)
        } else if (currentTab === 1) {
          const response = await getAdminBrandGroupsService({ idToken })
          setTableGroupData(response)
          setCountRegister(response.length)
        }
      } catch (ex) {}
    }
    fetchTable()
  }, [idToken, currentTab, tableRefreshCount])

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección de vinculación de marcas'}
      className={styles.root}>
      <TabsMenu
        tabs={['Marcas', 'Grupos']}
        initialActiveTab={initialTab}
        onChange={(index) => setCurrentTab(index)}
      />
      <div className={styles.root__content}>
        <TableWrapper
          title={content?.titleTable}
          elements={[
            {
              label: content?.titleButton != null ? content.titleButton : '',
              icono: 'plus',
              handleClick: () => {
                setActionType('create')
                if (alertStatus === 'closed') {
                  setAlertStatus('open')
                } else {
                  setAlertStatus('closed')
                }
              }
            }
          ]}
          resultsPerPage={countRegister}
          resultsNumber={countRegister}>
          {content?.table}
        </TableWrapper>
        {actionType !== 'delete' && alertStatus !== 'closed' && (
          <BrandsGroupModal
            activeObject={activeObject}
            brands={tableData.map((brand) => ({
              value: `${brand.brandId}`,
              label: brand.name
            }))}
            messages={alertMessages}
            onMessageDismiss={() => setAlertMessages([])}
            onSubmit={handleFormSubmit}
            contentType={currentTab === 0 ? 'brand' : 'group'}
            actionType={actionType}
            status={alertStatus}
            handleCancell={() => setAlertStatus('closed')}
          />
        )}
        {actionType === 'delete' && alertStatus !== 'closed' && (
          <Alert
            status={alertStatus}
            type={currentTab === 0 ? 'delete-brand' : 'delete-group'}
            messages={alertMessages}
            onResponse={async (response) => {
              if (!response) {
                setAlertStatus('closed')
                return
              }
              setAlertStatus('waiting')
              try {
                if (idToken === undefined)
                  throw new Error('¡Algo salió mal!😰', {
                    cause: 'No-Id-Token'
                  })
                await deleteAdminBrandService({ idToken, brandId: activeId })
                setTableRefreshCount((oldCount) => oldCount + 1)
                setAlertStatus('closed')
              } catch (ex) {
                setAlertMessages((oldMessages) => [
                  ...oldMessages,
                  { type: 'error', message: (ex as Error).message }
                ])
                setAlertStatus('open')
              }
            }}
          />
        )}
      </div>
    </section>
  )
}
