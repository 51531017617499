import FeatherIcon from 'feather-icons-react'
import { SVGComponent } from '@atoms/index'
import { PresentationBubbles } from '@molecules/index'
import styles from './index.module.scss'
import Link from 'next/link'

export default function HomeHero() {
  const handleClick = () => {
    const section = document.querySelector<HTMLElement>(`.${styles.root}`)
    if (section !== null) {
      const height = parseFloat(
        getComputedStyle(section).height.replaceAll('px', '')
      )
      window.scrollTo({ top: height, behavior: 'smooth' })
    }
  }

  return (
    <section role={'contentinfo'} className={styles.root}>
      <div className={styles.root__decoration}>
        <SVGComponent
          svgPath="home-hero/top-decoration"
          data-position={'top'}
          viewBox={'0 0 612.916 421.512'}
        />
        <SVGComponent
          svgPath="home-hero/bottom-decoration"
          data-position={'bottom'}
        />
      </div>
      <div className={styles['root__bubbles-wrapper']}>
        <PresentationBubbles delay={3000} />
      </div>
      <div role={'group'} className={styles['root__content-wrapper']}>
        <h1 className={styles.root__title}>
          ¿Qué es{' '}
          <strong>
            Kl<small>i</small>iker?
          </strong>
        </h1>
        <p className={styles.root__description}>
          Una plataforma virtual, donde vendes online marcas reconocidas y ganas
          comisiones por tus ventas.
        </p>
        <div className={styles['root__actions-wrapper']}>
          <Link
            href={'/registro'}
            title={'Enlace al formulario de registro Kliiker'}
            className={styles.root__cta}>
            Convertirme en Kliiker
          </Link>
          <button
            type="button"
            className={styles.root__button}
            title={'Click para ver más información de Kliiker'}
            onClick={handleClick}>
            <span>Conocer más</span>
            <FeatherIcon icon={'chevron-down'} />
          </button>
        </div>
      </div>
    </section>
  )
}
