import { formatDashboardComissionCardValue } from '@/functions'
import { StaticImageData } from 'next/image'
import background1 from '@/assets/img/kliiker-comision/background1.png'
import background2 from '@/assets/img/kliiker-comision/background2.png'
import background3 from '@/assets/img/kliiker-comision/background3.png'
import background4 from '@/assets/img/kliiker-comision/background4.png'
import { useMemo } from 'react'

export interface IComissionCard {
  background: StaticImageData
  value: string
  label: string
  tooltip?: { text: string; icon: string }
}

export const infoCards = (
  authorized: number,
  canceled: number,
  generated: number,
  inProgress: number
): IComissionCard[] => [
  {
    background: background1,
    value: formatDashboardComissionCardValue(inProgress),
    label: 'En proceso',
    tooltip: {
      text: 'Estas comisiones corresponden a las compras que ya fueron realizadas pero están pendientes de envío.',
      icon: 'info'
    }
  },
  {
    background: background2,
    value: formatDashboardComissionCardValue(generated),
    label: 'Generadas',
    tooltip: {
      text: 'Estas comisiones corresponden a las compras facturadas a tus clientes. (Si eligieron pago contra entrega, se debe completar el pago para ganar tu comisión).',
      icon: 'info'
    }
  },
  {
    background: background3,
    value: formatDashboardComissionCardValue(canceled),
    label: 'Canceladas',
    tooltip: {
      text: 'Estas comisiones corresponden a las compras que tus clientes no pagaron.',
      icon: 'info'
    }
  },
  {
    background: background4,
    value: formatDashboardComissionCardValue(authorized),
    label: 'Autorizadas',
    tooltip: {
      text: 'Estas comisiones ya fueron notificadas a Fisapay. Revisa la plataforma de Fisapay dentro de los siguientes 15 días.',
      icon: 'info'
    }
  }
]

export const useInfoCard = (
  authorized: string = '0',
  canceled: string = '0',
  generated: string = '0',
  inProgress: string = '0'
) => {
  return useMemo(() => {
    return infoCards(
      parseFloat(authorized),
      parseFloat(canceled),
      parseFloat(generated),
      parseFloat(inProgress)
    )
  }, [authorized, canceled, generated, inProgress])
}
