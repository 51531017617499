import styles from './styles.module.scss'

interface IInputCheckProps {
  htmlFor: string
  id: string
  value: string
  dataColor?: string
  checked?: boolean
  handleCheck?: (value: number) => void
}

export default function InputCheck({
  htmlFor = '',
  id = '',
  value = '',
  dataColor = 'primary',
  checked = false,
  handleCheck
}: IInputCheckProps) {
  return (
    <label htmlFor={htmlFor} className={styles.root}>
      <input
        type="checkbox"
        role="checkbox"
        aria-label="Seleccionar"
        id={id}
        value={value}
        className={styles.root__check}
        data-color={dataColor}
        checked={checked}
        onChange={() => handleCheck?.(Number(value))}
      />
    </label>
  )
}
