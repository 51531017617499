import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({progress}) => {

    const [color, setColor] = useState<string>('');

    useEffect(() => {
        if (progress < 50) {
            setColor('#92222C');
        } else if (progress >= 50 && progress <= 99) {
            setColor('#c59b50');
        } else {
            setColor('#43B49C');
        }
    }, [progress]);

    return (
        <div className={styles.root__progress_bar_container}>
            <div
                className={styles.root__progress_bar_container_porcentage}
                style={{ width: `${progress}%`, backgroundColor: color }}
            >
                <span className={styles.root__progress_bar_container_porcentage_text}>{progress}%</span>
            </div>
        </div>
    );
};

export default ProgressBar;
