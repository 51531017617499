import {
  IKliikerFisapayFormFields,
  IKliikerLoginFormFields
} from '@/interfaces'
import { signIn } from 'next-auth/react'
import { kliikerService } from '..'

export const kliikerLoginFormService = async (
  data: IKliikerLoginFormFields,
  bypass?: boolean
) => {
  if (bypass !== undefined) return bypass
  const loginResponse = await signIn('kliiker-services', {
    redirect: false,
    ...data
  })
  if (loginResponse === undefined)
    throw new Error(
      'Algo salió mal al intentar ingresar a tu cuenta, intentalo nuevamente.'
    )
  else if (!loginResponse.ok)
    throw new Error(
      'Tus credenciales de acceso no son correctas, intentalo de nuevo'
    )
  const freshResponse = await signIn('kliiker-services-with-token', {
    redirect: false
  })
  if (
    freshResponse === undefined ||
    (freshResponse !== undefined && !freshResponse.ok)
  ) {
    throw new Error('¡Algo salió mal! 😰', { cause: 'Cant-Auth-With-Token' })
  }
  return true
}

export const kliikerFisapayFormService = async (
  data: IKliikerFisapayFormFields,
  bypass?: boolean
) =>
  await kliikerService<
    IKliikerFisapayFormFields,
    {
      email: string
      acceptFisapayContract: string
      acceptFisapayTreatment: string
    }
  >({
    type: 'bool',
    endpoint: '/registration/pre-signup-fisapay',
    bypass,
    method: 'POST',
    withBody: {
      data,
      parser: (data) => ({
        email: data.email,
        acceptFisapayContract: data.contractAgreement ? 'true' : 'false',
        acceptFisapayTreatment: data.termsAgreement ? 'true' : 'false'
      })
    }
  })
