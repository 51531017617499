import { IBrandRule, TCommissionRule } from '@/interfaces'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import RulesCommissionTBody from '@atoms/rules-commission-tbody'

interface IRuleCommissionsTableProps {
  commissionsRule?: TCommissionRule[]
  brands?: IBrandRule[]
  onChange?: (value: TCommissionRule) => void
  onEdit?: (value: TCommissionRule) => void
  onDuplicate?: (value: TCommissionRule) => void
  onDelete?: (value: number) => void
  onChangeNameRule?: (value: TCommissionRule) => void
}

export default function RulesComissionsTable({
  commissionsRule = [],
  brands = [],
  onChange,
  onEdit,
  onDuplicate,
  onDelete,
  onChangeNameRule
}: IRuleCommissionsTableProps) {
  return (
    <table
      className={styles.root}
      aria-label={'Tabla de comisiones del Kliiker'}>
      <thead>
        <tr>
          <th></th>
          <th>Tipo</th>
          <th>Marca o grupo</th>
          <th>Estado</th>
          <th colSpan={3}></th>
        </tr>
      </thead>
      {commissionsRule.length === 0 ? (
        <tbody className={styles.root__empty}>
          <tr>
            <td colSpan={6}>
              <FeatherIcon icon="inbox" className={styles.root__icon} />
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <p className={styles.root__message}>
                Aún no tienes comisiones registradas
              </p>
            </td>
          </tr>
        </tbody>
      ) : (
        commissionsRule.map((commission, index) => (
          <RulesCommissionTBody
            key={index}
            commissionRule={commission}
            brand={brands.find((brand) => brand.brandId === commission.brandId)}
            handleCheck={onChange}
            checked={commission.active ?? -1}
            onEdit={onEdit}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            onChange={onChangeNameRule}
          />
        ))
      )}
    </table>
  )
}
