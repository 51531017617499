import { IFieldProp, TFieldRegistration } from '@/interfaces'
import { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'
import { ValidationValueMessage } from 'react-hook-form/dist/types/validator'

export function useCustomFieldRegistration<T extends FieldValues>(
  field?: IFieldProp<T>
) {
  return useMemo<TFieldRegistration<T>>(() => {
    if (field === undefined) return {}
    const { id, formObject, options, customValidation, formElement } = field
    const { validate, ...otherOptions } =
      options !== undefined ? options : { validate: undefined }
    return formObject.register(id, {
      ...otherOptions,
      validate:
        customValidation !== undefined
          ? (value) => customValidation(value, formElement)
          : validate
    })
  }, [field])
}

export function useFieldMessage<T extends FieldValues>(
  field?: IFieldProp<T>,
  defaultMessage: string = ''
) {
  return useMemo<{ isError: boolean; message: string }>(() => {
    if (field === undefined) return { isError: false, message: defaultMessage }
    const {
      id,
      formObject: {
        formState: { errors }
      }
    } = field
    const fieldError = errors[id]
    return {
      isError: fieldError !== undefined,
      message:
        fieldError === undefined
          ? defaultMessage
          : fieldError.type === 'required' && fieldError.message === ''
          ? 'Recuerda que este campo es obligatorio'
          : (fieldError.message as string)
    }
  }, [defaultMessage, field])
}

export function useIsDisabledField<T extends FieldValues>(
  field?: IFieldProp<T>
) {
  return useMemo<boolean>(() => {
    return field?.options?.disabled !== undefined
      ? field.options.disabled
      : false
  }, [field?.options?.disabled])
}

export function useIsRequiredField<T extends FieldValues>(
  field?: IFieldProp<T>
) {
  return useMemo<boolean>(() => {
    const required = field?.options?.required
    if (required === undefined) return false
    else if (typeof required === 'string') return true
    const requiredValue = required.valueOf()
    if (typeof requiredValue === 'boolean') return requiredValue
    const requiredObject = requiredValue as ValidationValueMessage<boolean>
    return requiredObject.value
  }, [field?.options?.required])
}
