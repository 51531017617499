import {
  combineReducers,
  configureStore,
  PreloadedState
} from '@reduxjs/toolkit'
import {
  benefitsAccordionReducer,
  heroReducer,
  preRegisterReducer
} from '@organisms/index'
import {
  brandsCarouselReducer,
  fisapayAgreementReducer
} from '@molecules/index'

export const rootReducer = combineReducers({
  brandsCarousel: brandsCarouselReducer,
  benefitsAccordion: benefitsAccordionReducer,
  preRegister: preRegisterReducer,
  fisapayAgreement: fisapayAgreementReducer,
  banners: heroReducer
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
