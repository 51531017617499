import {
  IBrandRule,
  TCommission,
  TCommissionRule,
  TQueryParams
} from '@/interfaces'
import { kliikerService } from '..'
import { formatServiceDate } from '@/functions'
import {
  IConsecutivenessCommissionParams,
  IEffectiveReferalsCommissionParams,
  IGoalsCommissionParams,
  IPercentRewardCommissionParam,
  IPermanenceCommissionParams,
  IReferalCommissionParams,
  ISeasonCommissionParams,
  IStepCommissionParams
} from '@/interfaces/commission/params'

export interface ICommissionTableData {
  currentPage: number
  countPage: number
  countRegister: number
  data: TCommission[]
  hasNextPage: boolean
}

export interface ICommissionTable {
  currentPage: number
  countPage: number
  countRegister: number
  data: TCommissionRule[]
  hasNextPage: boolean
}

export interface IKliikerCommissionsTableResponse {
  commissionsTable: ICommissionTableData
  commissionsTotals: {
    canceled: string
    inProgress: string
    generated: string
    authorized: string
  }
}

export interface IAdminRulesTableResponse {
  rulesPage: ICommissionTable
  brands: IBrandRule[]
}

export interface IKliikerCommissionsDuplicateResponse {
  ruleId: number
  brandId: number
  ruleTypeId: number
  params:
    | IPercentRewardCommissionParam
    | IStepCommissionParams
    | ISeasonCommissionParams
    | IPermanenceCommissionParams
    | IEffectiveReferalsCommissionParams
    | IConsecutivenessCommissionParams
    | IReferalCommissionParams
    | IGoalsCommissionParams
  createdAt: Date
  updatedAt: Date
  active: boolean
}

interface ISizePageFields {
  size?: number
  page?: number
}

export interface IAdminRulesTableQuery extends ISizePageFields {
  ruleType?: number
  brandId?: number
}

export interface IKliikerCommissionsTableQuery extends ISizePageFields {
  startDate?: Date
  endDate?: Date
}

interface IAdminRulesServiceParams {
  idToken: string
  params?: IAdminRulesTableQuery
}

interface ITableServiceParams {
  idToken: string
  params?: IKliikerCommissionsTableQuery
}

interface IComissionRuleParams {
  ruleTypeId: number
  brandId: number
  isActive: boolean
  params: IParams
}

interface IParams {
  beginDate: string
  endDate: string
  percentage: number
  thresholdAmount: number
  commissionAmount: number
  quantity: number
  period: string
  periods: number
  description: string
}

interface ITableServiceBody {
  idToken: string
  comission: TCommissionRule | IComissionRuleParams
}

export function buildAdminRulesTableQuery(queryParams?: IAdminRulesTableQuery) {
  const withQueryParams: TQueryParams = []
  if (queryParams !== undefined) {
    const { brandId, ruleType, page, size } = queryParams
    brandId !== undefined && withQueryParams.push(['brandId', `${brandId}`])
    ruleType !== undefined && withQueryParams.push(['ruleType', `${ruleType}`])
    size !== undefined && withQueryParams.push(['size', `${size}`])
    page !== undefined && withQueryParams.push(['page', `${page}`])
  }
  return withQueryParams
}

export function buildKliikerCommissionTableQuery(
  queryParams?: IKliikerCommissionsTableQuery
) {
  const withQueryParams: TQueryParams = []
  if (queryParams !== undefined) {
    const { startDate, endDate, page, size } = queryParams
    startDate !== undefined &&
      withQueryParams.push(['startDate', formatServiceDate(startDate)])
    endDate !== undefined &&
      withQueryParams.push(['endDate', formatServiceDate(endDate)])
    size !== undefined && withQueryParams.push(['size', `${size}`])
    page !== undefined && withQueryParams.push(['page', `${page}`])
  }
  return withQueryParams
}

export const kliikerCommissionsTableService = async (
  { idToken, params }: ITableServiceParams,
  bypass?: IKliikerCommissionsTableResponse
) => {
  const withQueryParams = buildKliikerCommissionTableQuery(params)

  return await kliikerService<{}, {}, IKliikerCommissionsTableResponse>({
    endpoint: '/dashboard/commissions-table',
    type: 'obj',
    bypass,
    method: 'GET',
    withAuthToken: idToken,
    withQueryParams
  })
}

export const kliikerDashboardCommissionsTableService = async (
  idToken: string,
  bypass?: IKliikerCommissionsTableResponse
) => {
  return await kliikerCommissionsTableService(
    {
      idToken,
      params: {
        size: 5,
        page: 0
      }
    },
    bypass
  )
}

export const getAdminRulesTableService = async (
  { idToken, params }: IAdminRulesServiceParams,
  bypass?: IAdminRulesTableResponse
) => {
  const withQueryParams = buildAdminRulesTableQuery(params)

  return await kliikerService<{}, {}, IAdminRulesTableResponse>({
    endpoint: '/admin/rules',
    type: 'obj',
    bypass,
    method: 'GET',
    withAuthToken: idToken,
    withQueryParams
  })
}

export const adminRulesCommissionsTable = async (
  { idToken, comission }: ITableServiceBody,
  bypass?: IKliikerCommissionsDuplicateResponse
) => {
  return await kliikerService<{}, {}, IKliikerCommissionsDuplicateResponse>({
    endpoint: '/admin/rule',
    type: 'obj',
    bypass,
    method: 'POST',
    withAuthToken: idToken,
    withBody: {
      data: comission
    }
  })
}

export const adminRulesCommissionsTableDelete = async (params: {
  idToken: string
  comissionId: number
  bypass?: boolean
}) => {
  const { idToken: withAuthToken, comissionId, bypass } = params
  return await kliikerService<{ comissionId: number }, { comissionId: number }>(
    {
      endpoint: '/admin/rule',
      type: 'bool',
      bypass,
      method: 'DELETE',
      withAuthToken,
      withQueryParams: [['ruleId', comissionId.toString()]]
    }
  )
}
