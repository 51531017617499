import { svgComponents, TSVGPath } from './index.data'

interface ISVGComponentProps extends React.SVGProps<SVGSVGElement> {
  svgPath?: TSVGPath
}

export default function SVGComponent(props: ISVGComponentProps) {
  const { svgPath, ...extraProps } = props
  if (svgPath !== undefined) {
    const { SvgElement, viewBox } = svgComponents[svgPath]
    return <SvgElement viewBox={viewBox} {...extraProps} />
  } else return <></>
}
