import { TCommission } from '@/interfaces'
import Link from 'next/link'
import { ComissionsTable } from '..'
import styles from './index.module.scss'

interface IDashboardTableProps {
  commissions?: TCommission[]
}
export default function DashboardTable({
  commissions = []
}: IDashboardTableProps) {
  return (
    <div
      role={'group'}
      className={styles.root}
      aria-label={'Contenedor de tabla de comisiones'}>
      <hgroup className={styles.root__heading}>
        <h2 className={styles.root__title}>Comisiones</h2>
        <Link
          aria-disabled={commissions.length === 0}
          className={styles.root__cta}
          href={'/portal/comisiones'}
          title={'Enlace a página de comisiones'}>
          Ver más
        </Link>
      </hgroup>
      <ComissionsTable commissions={commissions} />
    </div>
  )
}
