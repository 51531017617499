import { IBrand, IBrandsCollection } from '@/interfaces'
import placeHolder from '@/assets/img/placeholder.webp'

export const mockedBrand: IBrand = {
  name: 'test',
  logo: { title: 'Test image', url: placeHolder.src, width: 100, height: 100 },
  commissionRate: 2.5,
  isVariable: false
}

export const mockedBrandsCollection: IBrandsCollection = {
  items: [mockedBrand, mockedBrand, mockedBrand],
  total: 3
}
