import InputGroup from '@atoms/input-group'
import FeatherIcon from 'feather-icons-react'
import SelectGroup from '@atoms/select-group'
import { ReactNode, useEffect, useState } from 'react'

import styles from './index.module.scss'
import {
  listOptions,
  typesBonuses,
  typesBonuses2
} from '@/mocks/rules-commission/field-rules'
import { parseTypeBonus } from './index.data'
import { IAdminRulesCommissions, ISelectOptions } from '@/interfaces'
import { UseFormReturn } from 'react-hook-form'
interface IFieldsRulesForm {
  typeRule?: string
  formObject: UseFormReturn<IAdminRulesCommissions>
  handleSelectChange?: (value?: string) => void
  brands?: ISelectOptions[]
  typeBonusNumber?: string
}

interface ITopSellers {
  topSell: ReactNode
  percentage: ReactNode
  description?: ReactNode
}

export default function FieldsRulesForm({
  typeRule,
  formObject,
  handleSelectChange,
  brands = [],
  typeBonusNumber
}: IFieldsRulesForm) {
  const [typeBonus, setTypeBonus] = useState<string | undefined>(undefined)
  const [topSellers, setTopSellers] = useState<ITopSellers[]>([
    {
      topSell: (
        <InputGroup
          label="Ventas superiores a*"
          field={{ id: `steps.${0}.thresholdAmount`, formObject }}
          type="number"
        />
      ),
      percentage: (
        <InputGroup
          label="% de comisión*"
          field={{ id: `steps.${0}.percentage`, formObject }}
          type="number"
        />
      ),
      description: (
        <InputGroup
          label="Premio"
          placeholder='Ej: "Camiseta"'
          field={{ id: `steps.${0}.description`, formObject }}
          type="text"
        />
      )
    }
  ])

  const handleAddTopSell = () => {
    const count = topSellers.length
    setTopSellers((oldValue) => [
      ...oldValue,
      {
        topSell: (
          <InputGroup
            label={`${oldValue.length + 1} - Ventas superiores a`}
            field={{ id: `steps.${count}.thresholdAmount`, formObject }}
            type="number"
          />
        ),
        percentage: (
          <InputGroup
            label="% de comisión*"
            field={{ id: `steps.${count}.percentage`, formObject }}
            type="number"
          />
        ),
        description: (
          <InputGroup
            label="Premio"
            placeholder='Ej: "Camiseta"'
            field={{ id: `steps.${count}.description`, formObject }}
            type="text"
          />
        )
      }
    ])
  }

  const handleDeleteTopSell = (index: number) => {
    setTopSellers((oldValue) =>
      oldValue.length === 1
        ? oldValue
        : oldValue.filter((_, oldIndex) => oldIndex !== index)
    )
    formObject.reset({
      ...formObject.formState.defaultValues,
      steps:
        formObject.formState.defaultValues?.steps !== undefined
          ? formObject.formState.defaultValues?.steps.filter(
              (_, oldIndex) => oldIndex !== index
            )
          : []
    })
  }

  const handleSelectBonusType = (value?: string) => {
    setTypeBonus(value)
  }
  useEffect(() => {
    if (typeBonusNumber !== undefined) {
      setTypeBonus(typeBonusNumber)
    }
  }, [typeBonusNumber])

  useEffect(() => {
    if (formObject.formState.defaultValues?.steps !== undefined) {
      const steps = formObject.formState.defaultValues?.steps
      const newSteps = steps.map((step: any, index: number) => ({
        topSell: (
          <InputGroup
            label={`${index + 1} - Ventas superiores a`}
            field={{ id: `steps.${index}.thresholdAmount`, formObject }}
            type="number"
          />
        ),
        percentage: (
          <InputGroup
            label="% de comisión*"
            field={{ id: `steps.${index}.percentage`, formObject }}
            type="number"
          />
        ),
        description: (
          <InputGroup
            label="Premio"
            placeholder='Ej: "Camiseta"'
            field={{ id: `steps.${index}.description`, formObject }}
            type="text"
          />
        )
      }))
      setTopSellers(newSteps)
    }
  }, [formObject, formObject.formState.defaultValues?.steps])

  return (
    <>
      <div className={styles.root__container}>
        <div className={styles['root__first-child']}>
          <SelectGroup
            label="Tipo*"
            activeLabel="Tipo*"
            onChange={handleSelectChange}
            field={{
              id: 'ruleTypeId',
              formObject
            }}
            itemsFiller={listOptions}
          />
        </div>
        {typeRule !== undefined && (
          <SelectGroup
            label="Marca o grupo"
            activeLabel="Marca o grupo"
            itemsFiller={() => brands}
            field={{
              id: 'brandId',
              formObject
            }}
          />
        )}
        {['8'].includes(typeRule ?? '') && (
          <InputGroup
            label="Numero de meses inactivos"
            placeholder="Ej: 3"
            field={{
              id: 'periods',
              formObject
            }}
            type="number"
          />
        )}
        {['9'].includes(typeRule ?? '') && (
          <InputGroup
            label="Periodo"
            placeholder="Ej: 202301"
            field={{
              id: 'period',
              formObject
            }}
            type="text"
          />
        )}
        {['6'].includes(typeRule ?? '') && (
          <InputGroup
            label="Numero de meses"
            placeholder="Ej: 3"
            type="number"
            field={{
              id: 'periods',
              formObject
            }}
          />
        )}
        {['2', '3', '6', '7', '8', '9'].includes(typeRule ?? '') && (
          <InputGroup
            label="Monto mínimo de venta"
            placeholder="Ej: $100.000"
            field={{
              id: 'thresholdAmount',
              formObject
            }}
            type="number"
          />
        )}
        {['6', '7', '8', '9'].includes(typeRule ?? '') && (
          <SelectGroup
            label="Tipo de bono"
            activeLabel="Tipo de bono"
            itemsFiller={typeRule !== '7' ? typesBonuses : typesBonuses2}
            onChange={handleSelectBonusType}
            field={{
              id: 'bono',
              formObject
            }}
          />
        )}
        {parseTypeBonus(typeRule ?? '', typeBonus ?? '', formObject)}
        {['2'].includes(typeRule ?? '') && (
          <InputGroup
            label="Valor de comisión"
            field={{
              id: 'commissionAmount',
              formObject
            }}
            type="number"
          />
        )}
        {['1', '3'].includes(typeRule ?? '') && (
          <InputGroup
            label="% de comisión por venta"
            field={{
              id: 'percentage',
              formObject
            }}
            type="number"
          />
        )}
      </div>

      <div className={styles.root__secondary}>
        {['3', '4', '5'].includes(typeRule ?? '') && (
          <p className={styles.root__title}>
            <strong>Rango de fecha</strong>
          </p>
        )}
        <div className={styles.root__container}>
          {['3', '4', '5'].includes(typeRule ?? '') && (
            <>
              <InputGroup
                label="Desde*"
                type="date"
                field={{
                  id: 'beginDate',
                  formObject
                }}
              />
              <InputGroup
                label="Hasta*"
                type="date"
                field={{
                  id: 'endDate',
                  formObject
                }}
              />
            </>
          )}
          {['4'].includes(typeRule ?? '') && (
            <div className={styles.root__topsell}>
              <p className={styles.root__title}>
                <strong>Rango de venta</strong>
              </p>
              {topSellers.map((item, index) => (
                <div className={styles.root__group} key={index}>
                  {item.topSell}
                  {item.percentage}
                  {item.description}
                  {topSellers.length > 1 && (
                    <button
                      className={styles.root__delete}
                      type="button"
                      onClick={() => handleDeleteTopSell(index)}>
                      <FeatherIcon icon="trash" />
                    </button>
                  )}
                </div>
              ))}
              <div className={styles.root__group}>
                <InputGroup
                  label="Ventas superiores a*"
                  placeholder="Ventas superiores a*"
                  readOnly
                />
                <InputGroup
                  label="% de comisión*"
                  readOnly
                  placeholder="% de comisión*"
                />
                <button
                  className={styles.root__button}
                  type="button"
                  onClick={() => handleAddTopSell()}>
                  <FeatherIcon icon="plus" /> Agregar
                </button>
              </div>
            </div>
          )}
          {['5'].includes(typeRule ?? '') && (
            <>
              <InputGroup
                label="Numero de referidos"
                field={{
                  id: 'quantity',
                  formObject
                }}
                type="number"
              />
              <InputGroup
                label="Monto mínimo de venta*"
                field={{
                  id: 'thresholdAmount',
                  formObject
                }}
                type="number"
              />
              <InputGroup
                label="Valor de comisión"
                field={{
                  id: 'commissionAmount',
                  formObject
                }}
                type="number"
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
