import { IFaq } from '@/interfaces'
import { Accordion, IAccordionItem } from '@molecules/index'
import styles from './index.module.scss'

interface IFaqsSectionProps {
  faqs?: IFaq[]
}

export default function FaqsSection({ faqs = [] }: IFaqsSectionProps) {
  const accordionItems = faqs.map(
    (faq): IAccordionItem => ({
      title: faq.title,
      content: faq.shortAnswer
    })
  )
  return (
    <section role={'contentinfo'} className={styles.root}>
      <hgroup className={styles.root__heading}>
        <h2 className={styles.root__title}>
          <strong>Preguntas</strong> frecuentes
        </h2>
        <p className={styles.root__description}>
          Estas son las inquietudes más frecuentes de nuestros Kliikers
        </p>
      </hgroup>
      <div role={'group'} className={styles.root__wrapper}>
        {accordionItems.length > 0 ? (
          <Accordion items={accordionItems} />
        ) : (
          <></>
        )}
      </div>
    </section>
  )
}
