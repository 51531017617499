import { useCustomFieldRegistration } from '@/hooks'
import { IPictureSelectorFormGroupProps } from '@/interfaces'
import Image from 'next/image'
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import { FieldValues, Path, PathValue } from 'react-hook-form'
import styles from './index.module.scss'
import { normalizeSize } from '@/functions'

export default function BrandLogoSelector<T extends FieldValues>({
  field
}: IPictureSelectorFormGroupProps<T>) {
  const [previewImage, setPreviewImage] = useState('')
  const [isFirstRender, setIsFirstRender] = useState(true)
  const fieldRegistration = useCustomFieldRegistration<T>(field)
  const fieldValue = field?.formObject.watch(field.id) ?? ''
  const [nameFile, setNameFile] = useState('')
  const [sizeFile, setSizeFile] = useState('')

  useEffect(() => {
    if (!isFirstRender) return
    setIsFirstRender(false)
    if (fieldValue === '') return
    setPreviewImage(fieldValue)
    field?.formObject.setValue(field.id, '' as PathValue<T, Path<T>>)
  }, [isFirstRender, fieldValue, field?.formObject, field?.id])

  const handleRemove = () => {
    setPreviewImage('')
    field?.formObject.setValue(field.id, '' as PathValue<T, Path<T>>)
  }

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      if (target.files === null || target.files.length === 0) return
      setNameFile(target.files[0].name)
      setSizeFile(normalizeSize(target.files[0].size))
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          const base64 = reader.result.replace('data:', '').replace(/^.+,/, '')
          setPreviewImage(reader.result)
          field?.formObject.setValue(field.id, base64 as PathValue<T, Path<T>>)
        }
      })
      reader.readAsDataURL(target.files[0])
    },
    [field?.formObject, field?.id]
  )

  return (
    <div
      role={'group'}
      aria-label={'Campo de ingreso de archivo'}
      className={styles.root}>
      {previewImage !== '' ? (
        <div className={styles.root__wrapper}>
          <figure className={styles.root__mask}>
            <Image
              id="picture-preview"
              className={styles.root__picture}
              src={previewImage}
              alt="Vista previa de la imagen de perfil del Kliiker"
              width={86}
              height={60}
            />
          </figure>
          <div className={styles['root__details-logo']}>
            <p className={styles['root__name-file']}>{nameFile}</p>
            <p className={styles['root__size-file']}>{sizeFile}</p>
          </div>
          <button
            className={styles.root__remove}
            title={'Eliminar la imagen'}
            aria-label={'Eliminar la imagen'}
            onClick={() => handleRemove()}>
            Eliminar
          </button>
        </div>
      ) : (
        <div className={styles.root__inputs}>
          <label htmlFor="picture-selector" className={styles.root__label}>
            Logo de marca
            <input type={'hidden'} {...fieldRegistration} />
            <input
              id="picture-selector"
              type="file"
              accept="image/*"
              onChange={handleChange}
            />
          </label>
          <label htmlFor="picture-selector" className={styles.root__button}>
            Adjuntar archivo
          </label>
        </div>
      )}
    </div>
  )
}
