import { IAdminBrandGroupsFormFields } from '@/interfaces'
import { kliikerService } from '..'

interface IAdminBaseBrand {
  brandId: number
  name: string
  isGroup: boolean
  createdAt: string
  updatedAt: string
}

interface IAdminBrandsLogoField {
  logo: string
}

interface IAdminBrandsResponse extends IAdminBaseBrand, IAdminBrandsLogoField {}

interface IAddEditAdminBrandsGroupsResponse
  extends IAdminBaseBrand,
    Partial<IAdminBrandsLogoField> {}

interface IAdminBrandGroupsResponse {
  brandsGroup: IAdminBaseBrand
  childrenBrands: Array<{
    brandId: number
    name: string
  }>
  childrenBrandNames: string
}

export async function getAdminBrandsService(params: {
  idToken: string
  bypass?: IAdminBrandsResponse[]
}) {
  const { idToken: withAuthToken, bypass } = params
  return await kliikerService<{}, {}, IAdminBrandsResponse[]>({
    type: 'obj',
    endpoint: '/admin/brands',
    bypass,
    method: 'GET',
    withAuthToken
  })
}

export async function getAdminBrandGroupsService(params: {
  idToken: string
  bypass?: IAdminBrandGroupsResponse[]
}) {
  const { idToken: withAuthToken, bypass } = params
  return await kliikerService<{}, {}, IAdminBrandGroupsResponse[]>({
    type: 'obj',
    endpoint: '/admin/brand-groups',
    bypass,
    method: 'GET',
    withAuthToken
  })
}

export async function deleteAdminBrandService(params: {
  idToken: string
  brandId: number
  bypass?: boolean
}) {
  const { idToken: withAuthToken, brandId, bypass } = params
  return await kliikerService<{ brandId: number }, { brandId: number }>({
    type: 'bool',
    endpoint: '/admin/brand',
    bypass,
    method: 'DELETE',
    withAuthToken,
    withBody: {
      data: { brandId },
      parser: (data) => ({ brandId: data.brandId })
    }
  })
}

type TAddEditBody = Partial<IAdminBrandGroupsFormFields>

export async function addEditAdminBrandGroupService(params: {
  idToken: string
  data: TAddEditBody
  bypass?: IAddEditAdminBrandsGroupsResponse
}) {
  const { idToken: withAuthToken, data, bypass } = params
  return await kliikerService<
    TAddEditBody,
    TAddEditBody,
    IAddEditAdminBrandsGroupsResponse
  >({
    type: 'obj',
    endpoint: '/admin/brand',
    bypass,
    method: 'POST',
    withAuthToken,
    withBody: { data }
  })
}
