interface MonthObject {
    [key: string]: {
        ammount: number,
        status: string
    }
}

export const orderMonths = (monthsObject: MonthObject): MonthObject => {
    const monthOrder = [
        "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
    ]

    const sortedMonths: MonthObject = {};

    monthOrder.forEach((month) => {
        // eslint-disable-next-line no-prototype-builtins
        if (monthsObject.hasOwnProperty(month)) {
            sortedMonths[month] = monthsObject[month];
        }
    });

    return sortedMonths;
}