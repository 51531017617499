import { PUBLIC_HOST } from '@/utils/constants'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { HTMLProps } from 'react'

export type TLinkTagsList = Array<HTMLProps<HTMLLinkElement>>

export type TMetaTagsList = Array<HTMLProps<HTMLMetaElement>>

interface ISEOProps {
  title?: string
  description?: string
  keywords?: string
  linkTags?: TLinkTagsList
  metaTags?: TMetaTagsList
}

export default function SEO({
  title,
  description,
  keywords,
  linkTags = [],
  metaTags = []
}: ISEOProps) {
  const pageHost = PUBLIC_HOST
  const { asPath: path } = useRouter()
  const defaultTitle = 'Kliiker'
  const rawTitle = title ?? ''
  const pageTitle =
    rawTitle !== '' ? `${rawTitle} | ${defaultTitle}` : defaultTitle
  const pageDescription =
    description ??
    'Portal Kliiker, una plataforma virtual, donde vendes online marcas reconocidas y ganas comisiones por tus ventas.'
  const pageMetaKeywords = keywords ?? 'Comunidad, Ventas, Comisiones'
  const pageLinkTags: TLinkTagsList = [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png'
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest'
    },
    { rel: 'canonical', href: pageHost + path, key: 'canonical' },
    ...linkTags
  ]
  const pageMetaTags: TMetaTagsList = [
    { name: 'description', content: pageDescription },
    { name: 'keywords', content: pageMetaKeywords },
    { property: 'og:title', content: pageTitle },
    { property: 'og:type', content: 'website' },
    { property: 'og:image', content: `${pageHost}/og-kliiker-logo.png` },
    { property: 'og:url', content: pageHost + path },
    ...metaTags
  ]

  return (
    <Head>
      <title>{pageTitle}</title>
      {pageLinkTags.map((linkProps, key) => (
        <link key={key} {...linkProps} />
      ))}
      {pageMetaTags.map((metaProps, key) => (
        <meta key={key} {...metaProps} />
      ))}
    </Head>
  )
}
