import Link from 'next/link'
import FeatherIcon from 'feather-icons-react'
import { SVGComponent } from '@atoms/index'
import { socialLinksArray } from './index.data'
import styles from './index.module.scss'
import { TSVGPath } from '@atoms/svg-component/index.data'

export default function SocialLinks() {
  return (
    <ul className={styles.root}>
      {socialLinksArray.map((socialLink, key) => (
        <li key={key} className={styles.root__item}>
          <Link
            href={socialLink.url}
            target="_blank"
            className={styles.root__link}
            title={`Enlace al perfil de ${socialLink.name} de Kliiker`}>
            {socialLink.type === 'feather' ? (
              <FeatherIcon
                icon={socialLink.icon}
                className={styles['root__link-icon']}
              />
            ) : (
              <SVGComponent
                svgPath={socialLink.icon as TSVGPath}
                className={styles['root__link-icon']}
              />
            )}
          </Link>
        </li>
      ))}
    </ul>
  )
}
