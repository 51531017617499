import styles from './index.module.scss'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useRef, useState } from 'react'
import Link from 'next/link'
import { useAppSelector } from '@/state/hooks'
import { selectPreRegister } from '@organisms/index'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  CheckboxGroup,
  IMessageToast,
  InputGroup,
  MessageToasts
} from '@atoms/index'
import {
  ICheckboxFormGroup,
  IInputFormGroup,
  IKliikerPreRegisterFormFields
} from '@/interfaces'
import { useFormGroups, useKliikerPreRegisterFormFields } from '@/hooks'
import { getFieldProp } from '@/functions'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface IPreRegisterFormProps {
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onSubmit?: SubmitHandler<IKliikerPreRegisterFormFields>
}

export default function PreRegisterForm({
  messages = [],
  onMessageDismiss = () => {},
  onSubmit = () => {}
}: IPreRegisterFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { termsAgreement } = useAppSelector(selectPreRegister)
  const formRef = useRef<HTMLFormElement>(null)
  const preRegisterForm = useKliikerPreRegisterFormFields()
  const inputGroups = useFormGroups<
    IKliikerPreRegisterFormFields,
    IInputFormGroup<IKliikerPreRegisterFormFields>
  >(preRegisterForm, [
    'firstName',
    'lastName',
    'docNumber',
    'phoneNumber',
    'email',
    'emailConfirmation',
    'password',
    'passwordConfirmation'
  ])
  const termsCheckbox = useFormGroups<
    IKliikerPreRegisterFormFields,
    ICheckboxFormGroup<IKliikerPreRegisterFormFields>
  >(preRegisterForm, ['termsAgreement'])
  const formObject = useForm<IKliikerPreRegisterFormFields>({ mode: 'all' })

  const handleFormSubmit: SubmitHandler<IKliikerPreRegisterFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    if (executeRecaptcha !== undefined)
      data.gCaptcha = await executeRecaptcha('kliikerPreReegisterFormSubmit')
    await onSubmit(data)
    setIsLoading(false)
  }

  return (
    <form
      ref={formRef}
      className={styles.root}
      role={'form'}
      onSubmit={formObject.handleSubmit(handleFormSubmit)}>
      {inputGroups.map((inputGroup, key) => {
        const { field, ...props } = inputGroup
        const fieldProp = getFieldProp<IKliikerPreRegisterFormFields>(
          formObject,
          field,
          formRef.current
        )
        return (
          <InputGroup<IKliikerPreRegisterFormFields>
            key={key}
            field={fieldProp}
            {...props}
            className={styles['root__input-group']}
          />
        )
      })}
      {termsAgreement !== null && (
        <div className={styles['root__termsbox-wrapper']}>
          <div className={styles['root__termsbox-content']}>
            {documentToReactComponents(termsAgreement)}
          </div>
        </div>
      )}
      {termsCheckbox.map((checkboxGroup, key) => {
        const { field, ...props } = checkboxGroup
        const fieldProp = getFieldProp<IKliikerPreRegisterFormFields>(
          formObject,
          field
        )
        return (
          <CheckboxGroup<IKliikerPreRegisterFormFields>
            key={key}
            className={styles['root__checkbox-group']}
            field={fieldProp}
            {...props}
          />
        )
      })}
      <button
        aria-label="Quiero ser kliiker"
        type={'submit'}
        className={styles['root__submit-button']}
        disabled={isLoading}>
        Quiero ser Kliiker
      </button>
      {isLoading && <div className={styles.root__loading}></div>}
      {messages.length !== 0 && (
        <div className={styles.root__messages}>
          <MessageToasts
            dismissible
            messages={messages}
            onDismiss={onMessageDismiss}
          />
        </div>
      )}
      <p className={styles['root__login-cta-text']}>
        ¿Ya eres miembro de nuestra comunidad Kliiker?{' '}
        <Link href={'/ingreso'}>Ingresa aquí</Link>
      </p>
    </form>
  )
}
