import { getFieldProp } from '@/functions'
import { useFormGroups, useKliikerResetPasswordFormFields } from '@/hooks'
import {
  IKliikerResetPasswordFormFields,
  IVerificationCodeFormGroup
} from '@/interfaces'
import {
  IMessageToast,
  MessageToasts,
  VerificationCodeField
} from '@atoms/index'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { PasswordFields } from '..'
import styles from './index.module.scss'

interface IResetPasswordFormProps {
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  resetTime?: number
  onSubmit?: SubmitHandler<IKliikerResetPasswordFormFields>
  position?: string
  textButton?: string
  disabled?: boolean
}

export default function ResetPasswordForm({
  resetTime = 0,
  messages = [],
  onMessageDismiss = () => {},
  onSubmit = () => {},
  position,
  textButton = 'Establecer contraseña',
  disabled = false
}: IResetPasswordFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [resetVerification, setResetVerification] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const formObject = useForm<IKliikerResetPasswordFormFields>({
    mode: 'all'
  })
  const fields = useKliikerResetPasswordFormFields()
  const [{ field: verificationCode, ...verificationCodeProps }] = useFormGroups<
    IKliikerResetPasswordFormFields,
    IVerificationCodeFormGroup<IKliikerResetPasswordFormFields>
  >(fields, ['verificationCode'])
  const verificationCodeFieldProp =
    getFieldProp<IKliikerResetPasswordFormFields>(formObject, verificationCode)

  const handleFormSubmit: SubmitHandler<
    IKliikerResetPasswordFormFields
  > = async (data) => {
    setIsLoading(true)
    await onSubmit(data)
    formObject.reset()
    setResetVerification(true)
    setIsLoading(false)
  }

  useEffect(() => {
    if (resetVerification) setResetVerification(false)
  }, [resetVerification])

  return (
    <form
      ref={formRef}
      role={'form'}
      className={styles.root}
      aria-label={'Formulario de restablecimiento de contraseña'}
      onSubmit={formObject.handleSubmit(handleFormSubmit)}>
      <VerificationCodeField<IKliikerResetPasswordFormFields>
        field={verificationCodeFieldProp}
        {...verificationCodeProps}
        remainingTime={resetTime}
        reset={resetVerification}
      />
      <PasswordFields<IKliikerResetPasswordFormFields>
        formObject={formObject}
        formElement={formRef.current}
      />
      <button
        type="submit"
        aria-label={'Enviar formulario de restablecimiento'}
        className={styles.root__submit}
        data-position={position}
        disabled={isLoading || disabled}>
        {textButton}
      </button>
      {isLoading && <div className={styles.root__loading}></div>}
      {messages.length !== 0 && (
        <div className={styles.root__messages}>
          <MessageToasts
            dismissible
            messages={messages}
            onDismiss={onMessageDismiss}
          />
        </div>
      )}
    </form>
  )
}
