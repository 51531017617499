import { IField, IFieldProp, TFormGroup, TFormGroupType } from '@/interfaces'
import {
  CheckboxGroup,
  InputGroup,
  PictureSelectorGroup,
  SelectGroup,
  VerificationCodeField
} from '@atoms/index'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

export * from './kliiker'

export function getFieldProp<T extends FieldValues>(
  formObject?: UseFormReturn<T>,
  field?: IField<T>,
  formElement?: HTMLFormElement | null
): IFieldProp<T> | undefined {
  return field === undefined || formObject === undefined
    ? undefined
    : {
        ...field,
        formObject,
        formElement
      }
}

export function getFormGroups<T extends FieldValues, U extends TFormGroup<T>>(
  fieldsMap: Map<keyof T, TFormGroup<T>>,
  fieldsArray: Array<keyof T>
) {
  const result: U[] = []
  fieldsArray.forEach((field) => {
    const fieldData = fieldsMap.get(field)
    if (fieldData !== undefined) result.push(fieldData as U)
  })
  return result
}

export function getFormGroupsMap<
  T extends FieldValues,
  U extends TFormGroup<T>
>(
  type: TFormGroupType,
  groups: U[],
  formObject: UseFormReturn<T>,
  disabledFields: Array<Path<T>> = [],
  readOnlyFields: Array<Path<T>> = []
) {
  const groupsMap = new Map<keyof T, JSX.Element>()
  groups.forEach(({ field, ...props }) => {
    if (field === undefined) return
    const fixedField: IField<T> = disabledFields.includes(field.id)
      ? {
          ...field,
          options: {
            ...field.options,
            disabled: true
          }
        }
      : field
    const fieldProp = getFieldProp<T>(formObject, fixedField)
    if (type === 'input') {
      groupsMap.set(
        field.id,
        <InputGroup<T>
          field={fieldProp}
          {...props}
          readOnly={readOnlyFields.includes(field.id)}
        />
      )
    } else if (type === 'picture') {
      groupsMap.set(
        field.id,
        <PictureSelectorGroup<T> field={fieldProp} {...props} />
      )
    } else if (type === 'select') {
      groupsMap.set(field.id, <SelectGroup<T> field={fieldProp} {...props} />)
    } else if (type === 'checkbox') {
      groupsMap.set(field.id, <CheckboxGroup<T> field={fieldProp} {...props} />)
    } else if (type === 'verification-code') {
      groupsMap.set(
        field.id,
        <VerificationCodeField<T> field={fieldProp} {...props} />
      )
    }
  })
  return groupsMap
}
