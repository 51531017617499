import { IKliikerPreRegisterFormFields } from '@/interfaces'
import { kliikerPreRegisterFormService } from '@/services'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { IMessageToast, SVGComponent } from '@atoms/index'
import { PreRegisterForm } from '@molecules/index'
import Link from 'next/link'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styles from './index.module.scss'
import { selectPreRegister, setFulfillment } from './index.slice'

export default function PreRegisterSection() {
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const { fulfillment } = useAppSelector(selectPreRegister)
  const dispatch = useAppDispatch()

  const handleFormSubmit: SubmitHandler<IKliikerPreRegisterFormFields> = async (
    data
  ) => {
    try {
      await kliikerPreRegisterFormService(data)
      dispatch(setFulfillment(true))
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  return (
    <section role={'group'} className={styles.root}>
      <SVGComponent
        svgPath="home-hero/top-decoration"
        className={styles.root__decoration}
      />
      <hgroup className={styles.root__heading}>
        <h1 className={styles.root__title}>
          Registrarte a Kliiker <strong>es muy fácil.</strong>
        </h1>
        {!fulfillment && (
          <p className={styles.root__description}>
            Por favor diligencia los siguientes datos y haz parte de nuestra
            comunidad de vendedores.
          </p>
        )}
      </hgroup>
      <div className={styles['root__content-wrapper']}>
        {!fulfillment ? (
          <div className={styles['root__form-wrapper']}>
            <PreRegisterForm
              messages={messages}
              onMessageDismiss={(index) =>
                setMessages((currentMessages) =>
                  currentMessages.filter(
                    (_, messageIndex) => index !== messageIndex
                  )
                )
              }
              onSubmit={handleFormSubmit}
            />
          </div>
        ) : (
          <div className={styles['root__fulfillment-wrapper']}>
            <h2 className={styles.root__subtitle}>¡Revisa tu correo!</h2>
            <p className={styles['root__fulfillment-message']}>
              Dirígete a la bandeja de tu correo electrónico para terminar tu
              registro de manera exitosa. Ahí encontrarás todas las
              instrucciones para finalizar tu registro.
            </p>
            <p className={styles['root__fulfillment-footnote']}>
              * Échale un ojo también a tu bandeja de correo no deseado.
            </p>
            <Link href={'/'} className={styles['root__fulfillment-button']}>
              Aceptar
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}
