import { StaticImageData } from 'next/image'
import youImage from '@/assets/img/bmodel-section/tu.png'
import yourFriendsImage from '@/assets/img/bmodel-section/tus-amigos.png'
import usImage from '@/assets/img/bmodel-section/nosotros.png'

interface IBmodelSectionData {
  title: string
  description: string
  image: StaticImageData
}

export const bmodelSectionData: IBmodelSectionData[] = [
  {
    title: 'Tú',
    description:
      'Recibes y compartes en tus redes sociales el link de tu tienda virtual con tus amigos.',
    image: youImage
  },
  {
    title: 'Tus amigos',
    description: 'Navegan en tu tienda virtual, compran y pagan su pedido.',
    image: yourFriendsImage
  },
  {
    title: 'Nosotros',
    description:
      'Cobramos y enviamos el pedido a casa de tus amigos y pagamos tus comisiones a través de FisaPay.',
    image: usImage
  }
]
