import FeatherIcon from 'feather-icons-react'
import { MessageToasts, IMessageToast } from '@atoms/index'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'

import styles from './index.module.scss'
import { IUploadFileFormFields } from '@/interfaces/upload-file-modal'
import FileSelector from '@atoms/file-selector'

export type TAlertStatus = 'open' | 'waiting' | 'closed'

interface IUploadFileModalProps {
  status?: TAlertStatus
  messages?: IMessageToast[]
  handleCancell: () => void
  onMessageDismiss: () => void
  onSubmit?: SubmitHandler<IUploadFileFormFields>
}

export default function UploadFileModal({
  status = 'closed',
  messages = [],
  handleCancell,
  onMessageDismiss,
  onSubmit = () => {}
}: IUploadFileModalProps) {
  const [isLoading, setIsLoading] = useState(false)

  const formObject = useForm<IUploadFileFormFields>({
    mode: 'all'
  })

  const handleFormSubmit: SubmitHandler<IUploadFileFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    await onSubmit(data)
    setIsLoading(false)
  }

  const handleClearForm = () => {
    handleCancell()
    formObject.reset()
    formObject.clearErrors()
  }

  return status === 'open' ? (
    <div
      className={styles.root}
      role={'group'}
      aria-label={'Contenedor de modal de confirmación'}
      data-status={status}>
      <dialog
        open
        className={styles.root__dialog}
        aria-label={'Modal de marcas'}>
        <button
          className={styles.root__close}
          title={'Cerrar el modal'}
          aria-label={'Cerrar el modal'}>
          <FeatherIcon
            icon="x"
            role={'img'}
            className={styles['root__close-icon']}
            onClick={() => handleClearForm()}
          />
        </button>
        <div className={styles.root__content}>
          <h2 className={styles.root__title}>Cargar Archivo</h2>
          <form
            className={styles.root__form}
            role={'form'}
            onSubmit={formObject.handleSubmit(handleFormSubmit)}>
            <FileSelector field={{ id: 'file', formObject }} />
            {isLoading && <div className={styles.root__loading}></div>}
            {messages.length !== 0 && (
              <div className={styles.root__messages}>
                <MessageToasts
                  dismissible
                  messages={messages}
                  onDismiss={onMessageDismiss}
                />
              </div>
            )}
            <div className={styles.root__actions}>
              <button
                className={styles.root__cancel}
                type={'reset'}
                aria-label={'Botón para cancelar el envio del formulario'}
                onClick={() => handleClearForm()}
                disabled={isLoading}>
                Cancelar
              </button>
              <button
                className={styles.root__submit}
                type={'submit'}
                aria-label={'Botón para enviar el formulario'}
                disabled={isLoading}>
                Autorizar
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  ) : null
}
