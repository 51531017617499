import { InputGroup } from '@atoms/index'
import { useEffect, useState } from 'react'
import { Path } from 'react-hook-form'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import {
  IFieldPropBaseWithElement,
  IInputFormGroup,
  IKliikerPasswordCreationFields
} from '@/interfaces'
import {
  useFormGroups,
  useKliikerPasswordCreationFields,
  usePasswordConditions
} from '@/hooks'
import { getFieldProp } from '@/functions'

interface IPasswordFieldsProps<T extends IKliikerPasswordCreationFields>
  extends Partial<IFieldPropBaseWithElement<T>> {}

export default function PasswordFields<
  T extends IKliikerPasswordCreationFields
>({ formObject, formElement = null }: IPasswordFieldsProps<T>) {
  const passwordConditions = usePasswordConditions()
  const [isValidCondition, setIsValidCondition] = useState(
    passwordConditions.map(() => false)
  )
  const wathId = 'password' as Path<T>
  const watchPassword = formObject !== undefined ? formObject.watch(wathId) : ''
  const fields = useKliikerPasswordCreationFields<T>()
  const [
    { field: passwordField, ...passwordProps },
    { field: passwordConfirmationField, ...passwordConfirmationProps }
  ] = useFormGroups<T, IInputFormGroup<T>>(fields, [
    'password',
    'passwordConfirmation'
  ])
  const [passwordFieldProp, passwordConfirmationFieldProp] =
    formObject === undefined
      ? [undefined, undefined]
      : [
          getFieldProp<T>(formObject, passwordField, formElement),
          getFieldProp<T>(formObject, passwordConfirmationField, formElement)
        ]

  useEffect(() => {
    if (typeof watchPassword !== 'string') return
    setIsValidCondition(
      passwordConditions.map((condition) => condition.rule(watchPassword))
    )
  }, [watchPassword, passwordConditions])

  return (
    <div
      role={'group'}
      aria-label={'Contenedor de los campos de asignación de contraseña'}
      className={styles.root}>
      <InputGroup<T>
        field={passwordFieldProp}
        {...passwordProps}
        className={styles['root__input-group']}
      />
      <ul className={styles.root__conditions}>
        {passwordConditions.map((condition, key) => (
          <li
            key={key}
            className={
              styles.root__condition +
              (isValidCondition[key]
                ? ''
                : ` ${styles['root__condition--invalid']}`)
            }>
            <FeatherIcon
              icon={isValidCondition[key] ? 'check-circle' : 'x-circle'}
              className={styles['root__condition-icon']}
            />
            <span className={styles['root__condition-text']}>
              {condition.text}
            </span>
          </li>
        ))}
      </ul>
      <InputGroup<T>
        field={passwordConfirmationFieldProp}
        {...passwordConfirmationProps}
        className={styles['root__input-group']}
      />
    </div>
  )
}
