import { useAppSessionWatchdog } from '@/hooks'
import { IKliikerFisapayFormFields } from '@/interfaces'
import { kliikerFisapayFormService } from '@/services'
import { IMessageToast, SVGComponent } from '@atoms/index'
import { FisapayModal, OnboardingStep } from '@molecules/index'
import { signIn } from 'next-auth/react'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { stepsList } from './index.data'
import styles from './index.module.scss'

export default function OnboardingSection() {
  const session = useAppSessionWatchdog()
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [displayModal, setDisplayModal] = useState(false)
  const uniqueAlias: string | undefined | null = session.data?.user.uniqueAlias
  // const kliikerLink: string = `https://www.tienda.kliiker.com/?kliikerId=${uniqueAlias ?? ''
  //   }`

  const handleStepChange = () => {
    if (activeStepIndex === 0) {
      setDisplayModal(true)
    } else if (activeStepIndex < stepsList.length - 1) {
      setActiveStepIndex((current) => current + 1)
    }
  }

  const handleFormSubmit: SubmitHandler<IKliikerFisapayFormFields> = async (
    data
  ) => {
    try {
      if (session.data?.user.email === undefined) {
        throw new Error('Algo salió mal validando tu sesión.')
      } else {
        data.email = session.data.user.email
        await kliikerFisapayFormService(data)
        const loginResponse = await signIn('kliiker-services-with-token', {
          redirect: false
        })
        if (
          loginResponse === undefined ||
          (loginResponse !== undefined && !loginResponse.ok)
        )
          throw new Error('Algo salió mal validando tu sesión.')
        setDisplayModal(false)
        setActiveStepIndex((current) => current + 1)
      }
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  return (
    <>
      <section
        role={'contentinfo'}
        className={styles.root}
        aria-label={'Sección de introducción a la plataforma Kliiker'}>
        <SVGComponent
          svgPath="brands-section/decoration"
          className={styles.root__decoration}
          role={'img'}
          aria-label={'Decoración de página'}
        />
        <div className={styles.root__wrapper}>
          {activeStepIndex === stepsList.length - 1 ? (
            <OnboardingStep
              {...stepsList[activeStepIndex]}
              uniqueAlias={uniqueAlias}
              callToAction={{
                type: 'link',
                text: 'Ir al Portal',
                href: '/ingreso'
              }}
              indicator={{ current: activeStepIndex, total: stepsList.length }}
            />
          ) : (
            <OnboardingStep
              {...stepsList[activeStepIndex]}
              callToAction={{
                type: 'button',
                text: 'Siguiente',
                callback: handleStepChange
              }}
              indicator={{ current: activeStepIndex, total: stepsList.length }}
            />
          )}
        </div>
      </section>
      {displayModal && (
        <FisapayModal
          messages={messages}
          onMessageDismiss={(index) =>
            setMessages((currentMessages) =>
              currentMessages.filter(
                (_, messageIndex) => index !== messageIndex
              )
            )
          }
          onSubmit={handleFormSubmit}
        />
      )}
    </>
  )
}
