import { IBrandRule, TCommissionRule } from '@/interfaces'
import { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { formatCommissionType, parseCommissionType } from '@/functions'

import styles from './index.module.scss'
import { RulesCommissionDetail } from '..'
import ToggleSwitch from '@atoms/toggle-switch'

type TDetailsStatus = 'collapsed' | 'expanded'

interface ICommissionTBodyProps {
  commissionRule: TCommissionRule
  brand?: IBrandRule
  checked?: boolean
  handleCheck?: (value: TCommissionRule) => void
  onEdit?: (value: TCommissionRule) => void
  onDuplicate?: (value: TCommissionRule) => void
  onDelete?: (value: number) => void
  onChange?: (value: TCommissionRule) => void
}

export default function RulesCommissionTBody({
  commissionRule,
  brand,
  checked,
  handleCheck,
  onEdit,
  onDuplicate,
  onDelete,
  onChange
}: ICommissionTBodyProps) {
  const [detailsStatus, setDetailsStatus] =
    useState<TDetailsStatus>('collapsed')

  return (
    <tbody
      className={styles.root}
      aria-label={'Detalle de comisión'}
      role={'group'}
      data-status={detailsStatus}>
      <tr
        className={styles.root__data}
        onClick={() => {
          setDetailsStatus((current) =>
            current === 'collapsed' ? 'expanded' : 'collapsed'
          )
        }}>
        <td>
          {commissionRule !== undefined && (
            <FeatherIcon
              className={styles.root__icon}
              icon={
                detailsStatus === 'collapsed' ? 'chevron-down' : 'chevron-up'
              }
            />
          )}
        </td>
        <td>
          {commissionRule !== undefined &&
            formatCommissionType(
              parseCommissionType(commissionRule.ruleTypeId)
            )}
        </td>
        <td>{brand?.name}</td>
        <td>
          <ToggleSwitch
            htmlFor={`rule_${commissionRule?.ruleId ?? -1}`}
            id={`rule_${commissionRule?.ruleId ?? -1}`}
            value={`${commissionRule?.ruleId ?? -1}`}
            checked={checked}
            handleCheck={() => {
              handleCheck?.(commissionRule)
            }}
          />
        </td>
        <td>
          <FeatherIcon
            icon="edit-2"
            role="buttonEdit"
            className={styles.root__icon}
            onClick={() => {
              onEdit?.(commissionRule)
              onChange?.(commissionRule)
            }}
          />
        </td>
        <td>
          <FeatherIcon
            icon="copy"
            role="buttonDuplicate"
            className={styles.root__icon}
            onClick={() => {
              onDuplicate?.(commissionRule)
              onChange?.(commissionRule)
            }}
          />
        </td>
        <td>
          <FeatherIcon
            icon="trash"
            role="buttonDelete"
            className={styles.root__icon}
            onClick={() => {
              onDelete?.(commissionRule?.ruleId ?? -1)
              onChange?.(commissionRule)
            }}
          />
        </td>
      </tr>
      {commissionRule !== undefined && (
        <RulesCommissionDetail
          commissionRule={commissionRule}
          visible={detailsStatus === 'expanded'}
        />
      )}
    </tbody>
  )
}
