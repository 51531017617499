import { TCommission } from '@/interfaces'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import AutorizationCommissionTBody from '@atoms/autorization-commission-tbody'
import { useEffect, useState } from 'react'
import InputCheck from '@atoms/input-check'

interface ICommissionsTableProps {
  refreshCount?: number
  commissions?: TCommission[]
  onChange?: (value: number[]) => void
}

export default function AutorizationComissionsTable({
  refreshCount = 0,
  commissions = [],
  onChange = () => {}
}: ICommissionsTableProps) {
  const [commissionSelecteds, setCommissionSelecteds] = useState<number[]>([])

  const handleCheck = (id: number) => {
    if (id === -1) return
    const index = commissionSelecteds.indexOf(id)
    if (index === -1) {
      setCommissionSelecteds([...commissionSelecteds, id])
    } else {
      setCommissionSelecteds((oldCommissionSelecteds) =>
        oldCommissionSelecteds.filter((brand) => brand !== id)
      )
    }
  }

  useEffect(() => {
    onChange(commissionSelecteds)
  }, [commissionSelecteds, onChange])

  useEffect(() => {
    setCommissionSelecteds([])
  }, [refreshCount])

  return (
    <table
      className={styles.root}
      aria-label={'Tabla de autorización de comisiones'}>
      <thead>
        <tr>
          <th>
            <InputCheck
              htmlFor="-1"
              id="-1"
              value="-1"
              checked={commissionSelecteds.length === commissions.length}
              handleCheck={() => {
                if (commissionSelecteds.length === commissions.length)
                  setCommissionSelecteds([])
                else
                  setCommissionSelecteds(
                    commissions.map(
                      (commission) => commission.commissionId ?? -1
                    )
                  )
              }}
            />
          </th>
          <th></th>
          <th>Fecha</th>
          <th>Cédula</th>
          <th>Tipo</th>
          <th>Marca o grupo</th>
          <th>Estado</th>
        </tr>
      </thead>
      {commissions.length === 0 ? (
        <tbody className={styles.root__empty}>
          <tr>
            <td colSpan={6}>
              <FeatherIcon icon="inbox" className={styles.root__icon} />
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <p className={styles.root__message}>
                Aún no tienes comisiones registradas
              </p>
            </td>
          </tr>
        </tbody>
      ) : (
        commissions.map((commission, index) => (
          <AutorizationCommissionTBody
            key={index}
            commission={commission}
            handleCheck={handleCheck}
            checked={commissionSelecteds.includes(
              commission.commissionId ?? -1
            )}
          />
        ))
      )}
    </table>
  )
}
