import { TSelectItemsFiller } from '@/interfaces'

export const listOptions: TSelectItemsFiller = () => {
  return [
    {
      label: 'Venta',
      value: '1'
    },
    {
      label: 'Primera venta',
      value: '2'
    },
    {
      label: 'Ventas por temporada',
      value: '3'
    },
    {
      label: 'Permanencia',
      value: '4'
    },
    {
      label: 'Referidos efectivos',
      value: '5'
    },
    {
      label: 'Desempeño',
      value: '6'
    },
    {
      label: 'Primera venta de referido',
      value: '7'
    },
    {
      label: 'Reactivación de kliiker',
      value: '8'
    },
    {
      label: 'Meta',
      value: '9'
    }
  ]
}

export const typesBonuses = () => [
  {
    label: 'Valor de comisión',
    value: '1'
  },
  {
    label: 'Premio',
    value: '2'
  },
  {
    label: 'Ambos',
    value: '3'
  }
]

export const typesBonuses2 = () => [
  {
    label: 'Porcentaje de comisión',
    value: '1'
  },
  {
    label: 'Valor de comisión',
    value: '2'
  }
]
