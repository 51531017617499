import { IMessageToast, MessageToasts } from '@atoms/index'
import FeatherIcon from 'feather-icons-react'
import { alerts, TAlertType } from './index.data'
import styles from './index.module.scss'

export type TAlertStatus = 'open' | 'waiting' | 'closed'

interface IAlertProps {
  type?: TAlertType
  status?: TAlertStatus
  messages?: IMessageToast[]
  onResponse?: (response: boolean) => void | Promise<void>
  nameRule?: string
}

export default function Alert({
  type = 'logout',
  status = 'closed',
  messages = [],
  onResponse = () => {},
  nameRule = ''
}: IAlertProps) {
  const {
    icon,
    title,
    description,
    actions: { affirmative, negative }
  } = alerts[type]

  return (
    <div
      role={'group'}
      aria-label={'Contenedor de modal de confirmación'}
      className={styles.root}
      data-status={status}>
      {status !== 'closed' && (
        <dialog open className={styles.root__dialog}>
          <button
            className={styles.root__close}
            title={'Cerrar el modal'}
            aria-label={'Cerrar el modal'}
            onClick={() => {
              onResponse(false)
            }}>
            <FeatherIcon
              icon="x"
              role={'img'}
              className={styles['root__close-icon']}
            />
          </button>
          <div className={styles.root__content}>
            <hgroup className={styles.root__heading}>
              <FeatherIcon
                icon={icon}
                role={'img'}
                className={styles.root__icon}
              />
              <h2 className={styles.root__title}>{title}</h2>
            </hgroup>
            {type !== 'delete-rule' && type !== 'duplicate-rule' ? (
              <p className={styles.root__description}>{description}</p>
            ) : (
              <p className={styles.root__description}>{nameRule}</p>
            )}
            {status === 'waiting' && (
              <div className={styles.root__loading}></div>
            )}
            {messages.length !== 0 && (
              <div className={styles.root__messages}>
                <MessageToasts messages={messages} />
              </div>
            )}
            <div className={styles.root__actions}>
              <button
                className={styles.root__negative}
                type={'button'}
                onClick={() => {
                  onResponse(false)
                }}>
                {negative}
              </button>
              <button
                className={styles.root__affirmative}
                type={'button'}
                disabled={status === 'waiting'}
                onClick={() => {
                  onResponse(true)
                }}>
                {affirmative}
              </button>
            </div>
          </div>
        </dialog>
      )}
    </div>
  )
}
