import { ISelectOptions } from '@/interfaces'

export const itemList: ISelectOptions[] = [
  {
    label: 'Adidas',
    value: '1'
  },
  {
    label: 'Nike',
    value: '2'
  },
  {
    label: 'Puma',
    value: '3'
  },
  {
    label: 'Reebok',
    value: '4'
  },
  {
    label: 'Vans',
    value: '5'
  },
  {
    label: 'New Balance',
    value: '6'
  },
  {
    label: 'Converse',
    value: '7'
  },
  {
    label: 'Asics',
    value: '8'
  },
  {
    label: 'Fila',
    value: '9'
  },
  {
    label: 'Under Armour',
    value: '10'
  }
]
