import styles from './index.module.scss'
import { SVGComponent } from '@atoms/index'
import { PropsWithChildren } from 'react'
import Image from 'next/image'
import placeholderImage from '@/assets/img/placeholder.webp'

interface ITestimonialCardProps extends PropsWithChildren {
  author?: ITestimonialAuthor
}

export interface ITestimonialAuthor {
  name?: string
  city?: string
  pictureUrl?: string
}

export default function TestimonialCard({
  children,
  author
}: ITestimonialCardProps) {
  return (
    <div className={styles.root} role={'group'}>
      <SVGComponent
        svgPath="testimonials-section/quote-icon"
        className={styles.root__decoration}
      />
      <blockquote className={styles.root__quote}>{children}</blockquote>
      {author !== undefined ? (
        <figure className={styles['root__author-wrapper']}>
          <div className={styles['root__picture-mask']}>
            <Image
              className={styles.root__picture}
              src={author.pictureUrl ?? placeholderImage}
              alt={`Imagen de perfil del Kliiker ${author.name ?? ''}`}
              width={69}
              height={69}
              priority
            />
          </div>
          <figcaption className={styles['root__author-info']}>
            <span className={styles['root__author-name']}>{author.name}</span>
            <span className={styles['root__author-city']}>{author.city}</span>
          </figcaption>
        </figure>
      ) : (
        <></>
      )}
    </div>
  )
}
