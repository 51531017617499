import profileCard1 from '@/assets/img/kliiker-profile/1card4x.png'
import profileCard2 from '@/assets/img/kliiker-profile/2card4x.png'
import profileCard3 from '@/assets/img/kliiker-profile/3card4x.png'
import { formatPercent } from '@/functions'
import { IMetricskliiker } from '@/interfaces'
import { StaticImageData } from 'next/image'

interface IMetricCard {
  background: StaticImageData
  value: number | string
  label: string
}

// interface IMetricCardValues {
//   views: number
//   sells: number
// }

export const builMetricCards = ({
  sales: sells,
  visualizations: views
}: IMetricskliiker): IMetricCard[] => [
  {
    background: profileCard1,
    value: views,
    label: 'Visualizaciones'
  },
  {
    background: profileCard2,
    value: sells,
    label: 'Compras realizadas'
  },
  {
    background: profileCard3,
    value: views === 0 ? '-' : formatPercent(sells / views),
    label: 'Tasa de conversión'
  }
]
