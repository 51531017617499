import Image, { StaticImageData } from 'next/image'
import { PropsWithChildren } from 'react'
import styles from './index.module.scss'

interface IBmodelCardProps extends PropsWithChildren {
  placeholder?: string | StaticImageData
  title?: string
}

export default function BmodelCard({
  placeholder,
  title,
  children
}: IBmodelCardProps) {
  return (
    <div role={'group'} className={styles.root}>
      <figure className={styles.root__wrapper}>
        {placeholder !== undefined ? (
          <Image
            className={styles.root__placeholder}
            src={placeholder}
            alt={''}
            priority
          />
        ) : (
          <></>
        )}
      </figure>
      <h3 className={styles.root__title}>{title}</h3>
      <p className={styles.root__description}>{children}</p>
    </div>
  )
}
