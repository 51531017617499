import { TCommission } from '@/interfaces'

export const mockedCommissions: TCommission[] = [
  {
    ruleTypeId: 1,
    amount: 100000,
    commissionStateId: 1,
    commissionId: 1,
    document: '123456',
    updatedAt: new Date().toISOString(),
    params: {
      orderId: 'abc123',
      itemParams: [
        {
          sku: '00123',
          brand: 'Americanino',
          name: 'Pantalon',
          qty: 2,
          amount: 200000,
          commission: 25000
        },
        {
          sku: '00124',
          brand: 'Americanino',
          name: 'Pantalon',
          qty: 1,
          amount: 200000,
          commission: 25000
        },
        {
          sku: '00125',
          brand: 'Chevignon',
          name: 'Camisa',
          qty: 1,
          amount: 200000,
          commission: 25000
        },
        {
          sku: '00126',
          brand: 'Chevignon',
          name: 'Chaleco',
          qty: 2,
          amount: 200000,
          commission: 25000
        }
      ],
      sellRuleParams: [
        { brand: 'Americanino', percentage: 15 },
        { brand: 'Chevignon', percentage: 15 }
      ]
    }
  },
  {
    ruleTypeId: 2,
    commissionStateId: 2,
    commissionId: 2,
    document: '123456',
    amount: 10000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        thresholdAmount: 150000,
        commissionAmount: 10000
      }
    }
  },
  {
    ruleTypeId: 3,
    commissionStateId: 3,
    commissionId: 3,
    document: '123456',
    amount: 25000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      orderId: 'asd134',
      ruleParams: {
        beginDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        thresholdAmount: 150000,
        percentage: 10
      }
    }
  },
  {
    ruleTypeId: 4,
    commissionStateId: 4,
    commissionId: 4,
    document: '123456',
    amount: 25000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      description: 'Polo Americanino con ref: DSF123',
      totalSellsAmount: 250000,
      ruleParams: {
        beginDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        steps: [
          { thresholdAmount: 100000, percentage: 10, description: 'Paso 1' },
          { thresholdAmount: 300000, percentage: 15, description: 'Paso 2' }
        ]
      }
    }
  },
  {
    ruleTypeId: 5,
    commissionStateId: 3,
    commissionId: 5,
    document: '123456',
    amount: 15000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        beginDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        thresholdAmount: 150000,
        quantity: 3,
        commissionAmount: 15000
      }
    }
  },
  {
    ruleTypeId: 6,
    commissionStateId: 3,
    commissionId: 6,
    document: '123456',
    amount: 15000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        periods: 3,
        thresholdAmount: 150000,
        commissionAmount: 15000,
        description: ''
      }
    }
  },
  {
    ruleTypeId: 6,
    commissionStateId: 3,
    commissionId: 7,
    document: '123456',
    amount: 0,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        periods: 3,
        thresholdAmount: 150000,
        commissionAmount: 0,
        description: 'Polo Americanino con ref: DSF123'
      }
    }
  },
  {
    ruleTypeId: 7,
    commissionStateId: 3,
    commissionId: 8,
    document: '123456',
    amount: 15000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        thresholdAmount: 150000,
        commissionAmount: 15000,
        percentage: 0
      }
    }
  },
  {
    ruleTypeId: 7,
    commissionStateId: 3,
    commissionId: 9,
    document: '123456',
    amount: 15000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        thresholdAmount: 150000,
        commissionAmount: 0,
        percentage: 10
      }
    }
  },
  {
    ruleTypeId: 8,
    commissionStateId: 3,
    commissionId: 10,
    document: '123456',
    amount: 15000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        periods: 3,
        thresholdAmount: 150000,
        commissionAmount: 15000,
        description: ''
      }
    }
  },
  {
    ruleTypeId: 8,
    commissionStateId: 3,
    commissionId: 11,
    document: '123456',
    amount: 0,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino',
      ruleParams: {
        periods: 3,
        thresholdAmount: 150000,
        commissionAmount: 0,
        description: 'Polo Americanino con ref: DSF123'
      }
    }
  },
  {
    ruleTypeId: 9,
    commissionStateId: 3,
    commissionId: 12,
    document: '123456',
    amount: 0,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino, Chevignon, Esprit',
      ruleParams: {
        period: '202302',
        thresholdAmount: 150000,
        commissionAmount: 0,
        description: 'Jean marca Chevignon ref: qwe123'
      }
    }
  },
  {
    ruleTypeId: 9,
    commissionStateId: 3,
    commissionId: 13,
    document: '123456',
    amount: 25000,
    updatedAt: new Date().toISOString(),
    params: {
      brand: 'Americanino, Chevignon, Esprit',
      ruleParams: {
        period: '202302',
        thresholdAmount: 150000,
        commissionAmount: 25000,
        description: ''
      }
    }
  }
]
