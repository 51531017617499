import Link from 'next/link'
import { adminMenuItems, kliikerMenuItems, TButtonAction } from './index.data'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { useSession } from 'next-auth/react'

interface IUserMenuProps {
  activeIndex?: number
  onButtonClick?: (action: TButtonAction) => void
}

export default function UserMenu({
  activeIndex = -1,
  onButtonClick = () => {}
}: IUserMenuProps) {
  const session = useSession()
  const userRole = session.data?.user.roleId
  const listItem = userRole === 1 ? adminMenuItems : kliikerMenuItems
  return (
    <menu
      aria-label="Opciones de navegación de usuario"
      role={'menu'}
      className={styles.root}>
      {listItem.map((item, key) => (
        <li
          key={key}
          className={
            styles.root__item +
            (activeIndex === key ? ` ${styles['root__item--active']}` : '')
          }>
          {item.type === 'link' ? (
            <Link href={item.href} className={styles.root__link}>
              <FeatherIcon icon={item.icon} className={styles.root__icon} />
              {item.label}
            </Link>
          ) : (
            <button
              type="button"
              className={styles.root__button}
              onClick={() => {
                onButtonClick(item.action)
              }}>
              <FeatherIcon icon={item.icon} className={styles.root__icon} />
              {item.label}
            </button>
          )}
        </li>
      ))}
    </menu>
  )
}
