import Image from 'next/image'
import placeholder from '@/assets/img/dashboard/banner2.jpg'
import styles from './index.module.scss'
import Link from 'next/link';

export default function KliikerCommunity() {
  return (
    <div
      role={'group'}
      className={styles.root}
      aria-label={'Contendor de la comunidad del Kliiker'}>
      <Link target='_blank' href='https://www.tienda.kliiker.com/bonlife'>
        <figure
          aria-label={'Máscara para la imagen de fondo'}
          className={styles.root__mask}>
          <Image
            src={placeholder}
            alt={'Banner respecto a la comunidad del Kliiker'}
            className={styles.root__image}
            priority
          />
        </figure>
      </Link>
      <hgroup className={styles.root__heading}>
        <h2 className={styles.root__title}>Bonlife</h2>
        <p className={styles.root__description}>
          Una nueva marca llega a Kliiker, ropa deportiva con diseño y tecnología
        </p>
      </hgroup>
    </div>
  )
}
