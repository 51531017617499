import { getFieldProp } from '@/functions'
import { useFormGroups, useKliikerLinkCreationFormFields } from '@/hooks'
import {
  IInputFormGroup,
  IKliikerLinkCreationFormFields,
  IKliikerProfile,
  IMetricskliiker
} from '@/interfaces'
import { mockedEmptyKliikerProfile, mockedMetricsKliiker } from '@/mocks'
import { IMessageToast, InputGroup, MessageToasts } from '@atoms/index'
import FeatherIcon from 'feather-icons-react'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Alert, TAlertStatus } from '..'
import { builMetricCards } from './index.data'
import styles from './index.module.scss'
import { useRouter } from 'next/router'

interface IKliikerAccountBoxProps {
  kliiker?: IKliikerProfile
  metrics?: IMetricskliiker
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onError?: (message: IMessageToast) => void
  onLinkSubmit?: SubmitHandler<IKliikerLinkCreationFormFields>
}

export default function KliikerAccountBox({
  kliiker = mockedEmptyKliikerProfile,
  metrics = mockedMetricsKliiker,
  messages = [],
  onMessageDismiss = () => {},
  onError = () => {},
  onLinkSubmit = () => {}
}: IKliikerAccountBoxProps) {
  const formRef = useRef<HTMLFormElement>(null)
  const submmitedValues = useRef<IKliikerLinkCreationFormFields>()
  const [alertStatus, setAlertStatus] = useState<TAlertStatus>('closed')
  const formObject = useForm<IKliikerLinkCreationFormFields>({ mode: 'all' })
  const fields = useKliikerLinkCreationFormFields()
  const [{ field, ...props }] = useFormGroups<
    IKliikerLinkCreationFormFields,
    IInputFormGroup<IKliikerLinkCreationFormFields>
  >(fields, ['linkId'])
  const fieldProp = getFieldProp<IKliikerLinkCreationFormFields>(
    formObject,
    field
  )
  const {
    linkId,
    picture,
    identification: { firstName, lastName }
  } = kliiker
  const metricCards = builMetricCards(metrics)
  const router = useRouter()

  const handleFormSubmit: SubmitHandler<IKliikerLinkCreationFormFields> = (
    data
  ) => {
    submmitedValues.current = data
    setAlertStatus('open')
  }

  const handleLinksSubmit = async (response: boolean) => {
    if (response) {
      setAlertStatus('waiting')
      if (submmitedValues.current === undefined) {
        onError({ type: 'error', message: '¡Algo salío mal con tu link 😰!' })
      } else {
        await onLinkSubmit(submmitedValues.current)
        router.push('/portal')
      }
    }
    setAlertStatus('closed')
  }

  return (
    <div
      role={'group'}
      aria-label={'Opciones de la pestaña de cuenta'}
      className={
        styles.root + (linkId !== '' ? ` ${styles['root--filled']}` : '')
      }>
      <hgroup className={styles.root__heading}>
        <figure className={styles.root__info}>
          <div className={styles['root__picture-mask']}>
            {picture === '' ? (
              <FeatherIcon
                icon="user"
                className={styles['root__user-icon']}
                role={'img'}
              />
            ) : (
              <Image
                src={picture}
                className={styles['root__user-picture']}
                alt={'Imagen de perfil del Kliiker'}
                width={64}
                height={64}
                priority
              />
            )}
          </div>
          {linkId !== '' && (
            <figcaption className={styles['root__user-data']}>
              <span
                className={
                  styles['root__user-name']
                }>{`${firstName} ${lastName}`}</span>
              <span className={styles['root__user-link']}>
                www.tienda.kliiker.com/?kliikerId={linkId}
              </span>
            </figcaption>
          )}
        </figure>
        <h2 className={styles.root__title}>
          {linkId === '' ? 'Tu cuenta Kliiker' : 'Tu avance Kliiker,'}
        </h2>
      </hgroup>
      {linkId === '' ? (
        <>
          <form
            ref={formRef}
            className={styles.root__form}
            onSubmit={formObject.handleSubmit(handleFormSubmit)}>
            <div className={styles['root__input-wrapper']}>
              <InputGroup<IKliikerLinkCreationFormFields>
                field={fieldProp}
                {...props}
              />
            </div>
            <h3 className={styles.root__subtitle}>¡Ten en cuenta!</h3>
            <p className={styles.root__advice}>
              Este link solo lo podrás crear una única vez. Con este, tus
              conocidos te reconocerán fácilmente y nosotros lo utilizaremos
              para generar todas tus comisiones.
            </p>
            {messages.length !== 0 && (
              <div className={styles.root__messages}>
                <MessageToasts
                  dismissible
                  messages={messages}
                  onDismiss={onMessageDismiss}
                />
              </div>
            )}
            <button
              className={styles.root__submit}
              type="submit"
              aria-label="Guardar ID de Kliiker"
              title="Guardar ID de Kliiker"
              disabled={alertStatus !== 'closed'}>
              Guardar cambios
            </button>
          </form>
          <Alert
            type="link-creation"
            status={alertStatus}
            onResponse={handleLinksSubmit}
          />
        </>
      ) : (
        <div className={styles.root__metrics}>
          {metricCards.map((metric, index) => (
            <div key={index} className={styles.root__metric}>
              <figure className={styles['root__metric-mask']}>
                <Image
                  className={styles['root__metric-background']}
                  src={metric.background}
                  alt={''}
                  width={414}
                  height={162}
                  priority
                />
              </figure>
              <span className={styles['root__metric-value']}>
                {metric.value}
              </span>
              <span className={styles['root__metric-label']}>
                {metric.label}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
