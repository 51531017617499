import styles from './styles.module.scss'

interface IToggleSwitchProps {
  className?: string
  id: string
  htmlFor: string
  value: string
  checked?: boolean
  handleCheck?: (value: number) => void
}

export default function ToggleSwitch({
  className = '',
  id = '',
  htmlFor = '',
  value = '',
  checked,
  handleCheck
}: IToggleSwitchProps) {
  return (
    <label
      htmlFor={htmlFor}
      className={`${styles['root__toggle-switch']} ${className}`}>
      <input
        type="checkbox"
        id={id}
        value={value}
        aria-label={'switch'}
        checked={checked}
        onChange={() => handleCheck?.(Number(value))}
      />
      <span className={styles.root__slider} />
    </label>
  )
}
