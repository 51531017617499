import { TSelectItemsFiller } from '@/interfaces'

export const listOptions: TSelectItemsFiller = () => {
  return [
    {
      label: 'Venta',
      value: '1'
    },
    {
      label: 'Primera venta',
      value: '2'
    },
    {
      label: 'Desempeño',
      value: '6'
    }
  ]
}
