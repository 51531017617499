import { ISelectFormGroupProps, ISelectOptions } from '@/interfaces'
import { FieldValues, Path, PathValue } from 'react-hook-form'
import FeatherIcon from 'feather-icons-react'
import { useCallback, useEffect, useState } from 'react'
import {
  useCustomFieldRegistration,
  useFieldMessage,
  useIsRequiredField
} from '@/hooks'

import styles from './index.module.scss'

export default function SelectBrands<T extends FieldValues>({
  field,
  label = '',
  activeLabel = '',
  dependencyValue,
  itemsFiller,
  onChange
}: ISelectFormGroupProps<T>) {
  const [isFilled, setIsFilled] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [items, setItems] = useState<ISelectOptions[]>([])
  const [brandsSelecteds, setBrandsSelecteds] = useState<number[]>([])
  const isRequired = useIsRequiredField(field)
  const { isError, message } = useFieldMessage(field)
  const fieldRegister = useCustomFieldRegistration<T>(field)

  const handleCheck = (brand: number) => {
    const index = brandsSelecteds?.indexOf(brand)
    if (index === -1) {
      setBrandsSelecteds([...brandsSelecteds, brand])
    } else {
      const newBrandsSelecteds = brandsSelecteds?.filter(
        (item) => item !== brand
      )
      setBrandsSelecteds(newBrandsSelecteds)
    }
  }
  const setValueCallback = useCallback(
    (brandsId: string[]) => {
      field?.formObject.setValue(field.id, brandsId as PathValue<T, Path<T>>)
    },
    [field?.id, field?.formObject]
  )

  useEffect(() => {
    if (itemsFiller === undefined) return
    const getItems = async () => {
      const newItems = await itemsFiller(dependencyValue)
      setItems(newItems)
    }
    getItems()
  }, [itemsFiller, dependencyValue])

  useEffect(() => {
    setIsFilled(brandsSelecteds.length > 0)
    setValueCallback(brandsSelecteds.map((brand) => `${brand}`))
  }, [brandsSelecteds, setValueCallback])
  useEffect(() => {
    if (field?.formObject.formState.defaultValues !== undefined) {
      if (field?.formObject.formState.defaultValues.brands === undefined) {
        setBrandsSelecteds([])
      } else {
        setBrandsSelecteds(field?.formObject.formState.defaultValues.brands)
      }
    }
  }, [
    field?.formObject.formState.defaultValues,
    field?.formObject.formState.isSubmitted,
    field?.id
  ])

  return (
    <div
      className={
        styles.root +
        (isFilled ? ` ${styles['root--filled']}` : '') +
        (isActive ? ` ${styles['root--active']}` : '') +
        (isError ? ` ${styles['root--error']}` : '')
      }
      role={'group'}
      aria-label="Campo de selección">
      <input type={'hidden'} {...fieldRegister} />
      <div className={styles.root__wrapper}>
        <label className={styles.root__label}>
          {brandsSelecteds.length > 0 ? activeLabel : label}
          {isRequired ? ' *' : ''}
        </label>
        <div
          className={styles.root__input}
          onClick={() => setIsActive(!isActive)}>
          <div className={styles.root__value}>
            {brandsSelecteds.map((brandId, index) => (
              <p key={index} className={styles.root__brand}>
                {items.find((item) => item.value === `${brandId}`)?.label}
                <FeatherIcon
                  icon="x"
                  className={styles['root__icon-brand']}
                  onClick={() => {
                    handleCheck(brandId)
                  }}
                />
              </p>
            ))}
          </div>
          <button className={styles.root__toggle} type="button">
            <FeatherIcon icon="chevron-down" className={styles.root__icon} />
          </button>
        </div>
        {isActive && (
          <div className={styles.root__options}>
            <div className={styles['root__options-content']}>
              <ul className={styles['root__options-list']}>
                {items.map((item, itemIndex) => (
                  <li
                    key={itemIndex}
                    className={styles.root__option}
                    onClick={() => {
                      handleCheck(Number(item.value))
                    }}>
                    <label htmlFor={item.label}>
                      <input
                        type="checkbox"
                        role="checkbox"
                        id={item.label}
                        value={item.value}
                        className={styles.root__check}
                        checked={brandsSelecteds
                          .map((brand) => `${brand}`)
                          .includes(item.value)}
                        onChange={() => {
                          handleCheck(Number(item.value))
                        }}
                      />
                    </label>
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {isError && <span className={styles.root__message}>{message}</span>}
    </div>
  )
}
