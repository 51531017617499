import Link from 'next/link'
import { SVGComponent } from '@atoms/index'
import styles from './index.module.scss'
import { UrlObject } from 'url'

interface IBrandLinkProps {
  className?: string
  href?: string | UrlObject
}

export default function BrandLink({
  className = '',
  href = '/'
}: IBrandLinkProps) {
  const buildBrandClassName = () => {
    let brandClassName = styles.root
    brandClassName += className !== '' ? ` ${className}` : ''
    return brandClassName
  }

  return (
    <Link
      href={href}
      className={buildBrandClassName()}
      title={'Enlace a la página principal del Portal Kliiker'}>
      <SVGComponent
        svgPath="logo-kliiker"
        className={styles.root__image}
        role={'img'}
        aria-label={'Logo de Kliiker'}
      />
    </Link>
  )
}
