import { kliikerService } from '..'
import { IInfoSegmentation } from '@/interfaces/module-segmentation/segmentation'

export const kliikerGetInfoService = async (email: string): Promise<IInfoSegmentation> => {
    return await kliikerService<{}, { email: string }, IInfoSegmentation>({
        type: 'obj',
        endpoint: `/segmentation/getinfo/${email}`,
        method: 'GET',
        withQueryParams: [['email', email]]
    });
}
