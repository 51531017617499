import FeatherIcon from 'feather-icons-react'
import { MessageToasts } from '@atoms/index'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  IAdminRulesCommissions,
  IRuleCommissionModalProps,
  ITextRuleModal,
  TCommissionRule
} from '@/interfaces'

import styles from './index.module.scss'
import FieldsRulesForm from '@organisms/fields-rules'
import { listOptions } from '@/mocks/rules-commission/list-options'

export type TAlertStatus = 'open' | 'waiting' | 'closed'

type TDefaults = Partial<IAdminRulesCommissions> & Partial<TCommissionRule>

function parseBono(activeObject?: TDefaults) {
  if (activeObject !== undefined) {
    const { ruleTypeId } = activeObject
    if (ruleTypeId === 6 || ruleTypeId === 8 || ruleTypeId === 9) {
      if (
        activeObject.params?.description !== undefined &&
        activeObject.params?.commissionAmount !== undefined
      ) {
        return '3'
      } else if (activeObject.params?.description !== undefined) {
        return '2'
      } else if (activeObject.params?.commissionAmount !== undefined) {
        return '1'
      }
    } else if (ruleTypeId === 7) {
      if (activeObject.params?.percentage !== undefined) {
        return '1'
      } else if (activeObject.params?.commissionAmount !== undefined) {
        return '2'
      }
    }
  }
  return undefined
}

function getDefaults(
  actionType: 'add-rule' | 'edit-rule',
  activeObject?: TDefaults
) {
  const result: TDefaults = {}
  if (activeObject !== undefined && actionType === 'edit-rule') {
    const typedObject = activeObject
    const { ruleTypeId } = typedObject

    result.steps = ruleTypeId === 4 ? typedObject.params?.steps : undefined
    result.brandId = typedObject.brandId
    result.ruleId = typedObject.ruleId
    result.ruleTypeId = typedObject.ruleTypeId
    result.beginDate =
      ruleTypeId === 3 || ruleTypeId === 4 || ruleTypeId === 5
        ? typedObject.params?.beginDate
        : undefined
    result.commissionAmount =
      ruleTypeId === 2 ||
      ruleTypeId === 5 ||
      ruleTypeId === 6 ||
      ruleTypeId === 7 ||
      ruleTypeId === 8 ||
      ruleTypeId === 9
        ? typedObject.params?.commissionAmount
        : undefined
    result.bono = parseBono(typedObject)
    result.description =
      ruleTypeId === 6 || ruleTypeId === 8 || ruleTypeId === 9
        ? typedObject.params?.description
        : undefined
    result.endDate =
      ruleTypeId === 3 || ruleTypeId === 4 || ruleTypeId === 5
        ? typedObject.params?.endDate
        : undefined
    result.percentage =
      ruleTypeId === 1 ? typedObject.params?.percentage : undefined
    result.period = ruleTypeId === 9 ? typedObject.params?.period : undefined
    result.periods =
      ruleTypeId === 6 || ruleTypeId === 8
        ? typedObject.params?.periods
        : undefined
    result.quantity =
      ruleTypeId === 5 ? typedObject.params?.quantity : undefined
    result.thresholdAmount =
      ruleTypeId === 2 ||
      ruleTypeId === 3 ||
      ruleTypeId === 5 ||
      ruleTypeId === 6 ||
      ruleTypeId === 7 ||
      ruleTypeId === 8 ||
      ruleTypeId === 9
        ? typedObject.params?.thresholdAmount
        : undefined
  }
  return result
}
export default function RuleCommissionModal({
  status = 'closed',
  messages = [],
  handleCancell,
  onMessageDismiss,
  onSubmit,
  actionType = 'add-rule',
  brands = [],
  activeObject
}: IRuleCommissionModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [typeRule, setTypeRule] = useState<string | undefined>()
  const formObject = useForm<IAdminRulesCommissions>({
    mode: 'all',
    defaultValues: getDefaults(actionType, activeObject)
  })

  const handleFormSubmit: SubmitHandler<IAdminRulesCommissions> = async (
    data
  ) => {
    setIsLoading(true)
    await onSubmit?.(data)
    setIsLoading(false)
  }

  const textModal: ITextRuleModal = {
    'add-rule': {
      title: 'Nueva regla de comisión',
      textButton: 'Crear regla'
    },
    'edit-rule': {
      title: 'Editar regla de comisión',
      textButton: 'Guardar'
    }
  }

  const handleClearForm = () => {
    handleCancell()
    setTypeRule(undefined)
    formObject.reset()
  }
  const handleSelectChange = (value?: string) => {
    setTypeRule(value)
    formObject.reset({
      percentage: undefined,
      commissionAmount: undefined,
      description: undefined,
      thresholdAmount: undefined,
      beginDate: undefined,
      endDate: undefined,
      steps: undefined,
      periods: undefined,
      quantity: undefined,
      period: undefined
    })
    formObject.clearErrors()
  }

  useEffect(() => {
    listOptions()
    if (actionType === 'edit-rule') {
      setTypeRule(activeObject?.ruleTypeId.toString())
    }
  }, [actionType, activeObject?.ruleTypeId])

  return status === 'open' ? (
    <div
      className={styles.root}
      role={'group'}
      aria-label={'Contenedor de modal de nueva regla de comisiones'}
      data-status={status}>
      <dialog
        open
        className={styles.root__dialog}
        aria-label={'Modal de nueva regla de comisiones'}>
        <button
          className={styles.root__close}
          title={'Cerrar el modal'}
          aria-label={'Cerrar el modal'}>
          <FeatherIcon
            icon="x"
            role={'img'}
            className={styles['root__close-icon']}
            onClick={() => handleClearForm()}
          />
        </button>
        <div className={styles.root__content}>
          <h2 className={styles.root__title}>{textModal[actionType].title}</h2>
          <form
            className={styles.root__form}
            role={'form'}
            onSubmit={formObject.handleSubmit(handleFormSubmit)}>
            <FieldsRulesForm
              typeBonusNumber={parseBono(activeObject)}
              formObject={formObject}
              handleSelectChange={handleSelectChange}
              typeRule={typeRule}
              brands={brands}
            />
            {isLoading && <div className={styles.root__loading}></div>}
            {messages.length !== 0 && (
              <div className={styles.root__messages}>
                <MessageToasts
                  dismissible
                  messages={messages}
                  onDismiss={onMessageDismiss}
                />
              </div>
            )}
            <div className={styles.root__actions}>
              <button
                className={styles.root__cancel}
                type={'submit'}
                aria-label={'Botón para cancelar el envio del formulario'}
                onClick={() => handleClearForm()}
                disabled={isLoading}>
                Cancelar
              </button>
              <button
                className={styles.root__submit}
                type={'submit'}
                aria-label={'Botón para enviar el formulario'}
                disabled={isLoading}>
                {textModal[actionType].textButton}
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  ) : null
}
