import {
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'

export interface IAccordionChangeEvent extends Event {
  isActive: boolean
  item: HTMLElement | null
}

interface IAccordionItemProps extends PropsWithChildren {
  title?: string
  active?: boolean
  onChange?: (event?: IAccordionChangeEvent) => void
}

export default function AccordionItem({
  active,
  title = '',
  children = '',
  onChange = () => {},
  ...extraProps
}: IAccordionItemProps) {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(active ?? false)
  }, [active])

  const handleClick: MouseEventHandler = ({ target }) => {
    setIsActive((currentValue) => !currentValue)
    const targetElement = target as HTMLElement
    const rootElement = targetElement.closest<HTMLElement>(`.${styles.root}`)
    onChange({
      ...new Event('change'),
      isActive: !isActive,
      item: rootElement
    })
  }

  const buildItemClassName = () => {
    let itemClassName = styles.root
    itemClassName += isActive ? ` ${styles['root--active']}` : ''
    return itemClassName
  }

  return (
    <div role={'listitem'} className={buildItemClassName()} {...extraProps}>
      <hgroup className={styles.root__heading} onClick={handleClick}>
        <h3 className={styles.root__title}>{title}</h3>
        <button
          className={styles['root__toggle-button']}
          type={'button'}
          title={'Click para colapsar el elemento'}
          data-action={'toggle'}>
          <FeatherIcon icon={isActive ? 'minus' : 'plus'} />
        </button>
      </hgroup>
      <p className={styles.root__content}>{children}</p>
    </div>
  )
}
