import {
  IKliikerEditDataFormFields,
  IKliikerProfile,
  TSelectItemsFiller
} from '@/interfaces'
import { mockedEmptyKliikerProfile } from '@/mocks'
import { IMessageToast, MessageToasts } from '@atoms/index'
import { useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEditDataFormGroups, useEditDataFormGroupsMap } from './index.data'
import styles from './index.module.scss'

interface IKliikerDataBoxProps {
  kliiker?: IKliikerProfile
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  stateFiller?: TSelectItemsFiller
  cityFiller?: TSelectItemsFiller
  onSubmit?: SubmitHandler<IKliikerEditDataFormFields>
}

export default function KliikerDataBox({
  kliiker = mockedEmptyKliikerProfile,
  messages = [],
  onMessageDismiss = () => {},
  stateFiller = () => [],
  cityFiller = () => [],
  onSubmit = () => {}
}: IKliikerDataBoxProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [stateId, setStateId] = useState<string | undefined>()
  const formRef = useRef<HTMLFormElement>(null)
  const formObject = useForm<IKliikerEditDataFormFields>({
    mode: 'all',
    defaultValues: {
      picture: kliiker.picture,
      ...kliiker.identification,
      ...kliiker.location,
      ...kliiker.contact
    }
  })
  const formGroups = useEditDataFormGroups()
  const formGroupsMap = useEditDataFormGroupsMap(
    formObject,
    stateFiller,
    cityFiller,
    (value) => setStateId(value),
    stateId
  )

  const handleFormSubmit: SubmitHandler<IKliikerEditDataFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    await onSubmit(data)
    setIsLoading(false)
  }

  return (
    <form
      ref={formRef}
      aria-label={'Formulario de edición de datos del Kliiker'}
      className={styles.root}
      onSubmit={formObject.handleSubmit(handleFormSubmit)}>
      {formGroups.map(({ title, items }, index) => (
        <div key={index} className={styles['root__form-group']}>
          <h2 className={styles['root__group-title']}>{title}</h2>
          <ul className={styles['root__group-fields']}>
            {items.map((row, rowIndex) => (
              <li key={rowIndex} className={styles['root__group-row']}>
                {row.map((item, itemIndex) => (
                  <span
                    key={itemIndex}
                    className={styles['root__group-field']}
                    data-field={item}>
                    {formGroupsMap.get(item)}
                  </span>
                ))}
              </li>
            ))}
          </ul>
        </div>
      ))}
      {isLoading && <div className={styles.root__loading}></div>}
      {messages.length !== 0 && (
        <div className={styles.root__messages}>
          <MessageToasts
            dismissible
            messages={messages}
            onDismiss={onMessageDismiss}
          />
        </div>
      )}
      <button
        className={styles.root__submit}
        type="submit"
        title="Enviar actualización de datos">
        Guardar cambios
      </button>
    </form>
  )
}
