
import { WelcomeMessage } from '@atoms/index'
import styles from './index.module.scss'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import AccordionVideo from '@molecules/accordion-video'
import bannerAcademy from '@/assets/img/banner-academy/Banner academia - desktop.png'
import { IMovieListForModule } from '@/interfaces/module-list-academy/video-module'

interface IKliikerAcademySectionProps {
  moduleVideo?: IMovieListForModule[];
}

export default function KliikerAcademySection({ moduleVideo }: IKliikerAcademySectionProps): JSX.Element {

  const { data: session } = useSession();
  const user = session?.user;

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección de Academia Kliiker'}
      className={styles.root}>
      <WelcomeMessage name={user?.firstname} />

      <h1 className={styles.root__title}>Academia Kliiker</h1>

      <div className={styles.root__container__image__banner_Academy}>
        <Image
          className={styles['root__image__banner-Academy']}
          src={bannerAcademy}
          alt={'Imagen del video informativo sobre Kliiker'}
          width={1000}
          priority
        />
      </div>

      <AccordionVideo items={moduleVideo ?? []} />

    </section>
  )
}
