import { AccordionItem, IAccordionChangeEvent } from '@atoms/index'
import styles from './index.module.scss'
import { useState } from 'react'

export interface IAccordionItem {
  title?: string
  content?: string
}

interface IAccordionProps {
  items?: IAccordionItem[]
}

export default function Accordion({ items = [] }: IAccordionProps) {
  const [index, setIndex] = useState(-1)

  const handleUpdate = (event?: IAccordionChangeEvent) => {
    if (event !== undefined) {
      const { isActive, item } = event
      const nextIndex = parseInt(item?.dataset.index ?? '-1')
      if (isActive && nextIndex !== -1) setIndex(nextIndex)
      else setIndex(-1)
    }
  }

  return (
    <div role={'list'} className={styles.root}>
      {items.map((item, key) => (
        <AccordionItem
          key={key}
          title={item.title}
          active={index === key}
          data-index={key}
          onChange={handleUpdate}>
          {item.content}
        </AccordionItem>
      ))}
    </div>
  )
}
