import { TCommissionRule } from '@/interfaces'

export const mockedCommissionsRules: TCommissionRule[] = [
  {
    ruleTypeId: 1,
    ruleId: 1,
    brandId: 4,
    active: true,
    params: {
      percentage: 15
    }
  },
  {
    ruleTypeId: 2,
    ruleId: 2,
    brandId: 4,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 10000
    }
  },
  {
    ruleTypeId: 3,
    ruleId: 3,
    brandId: 5,
    active: true,
    params: {
      beginDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      thresholdAmount: 150000,
      percentage: 10
    }
  },
  {
    ruleTypeId: 4,
    ruleId: 4,
    brandId: 1,
    active: true,
    params: {
      beginDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      steps: [
        { thresholdAmount: 100000, percentage: 10, description: 'Camiseta' },
        { thresholdAmount: 300000, percentage: 15, description: 'Pantalon' }
      ]
    }
  },
  {
    ruleTypeId: 5,
    ruleId: 5,
    brandId: 1,
    active: true,
    params: {
      beginDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      quantity: 3,
      thresholdAmount: 150000,
      commissionAmount: 15000
    }
  },
  {
    ruleTypeId: 6,
    ruleId: 6,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 15000,
      description: '',
      periods: 3
    }
  },
  {
    ruleTypeId: 6,
    ruleId: 6,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 0,
      description: 'Polo Americanino con ref: DSF123',
      periods: 3
    }
  },
  {
    ruleTypeId: 7,
    ruleId: 7,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 15000,
      percentage: 10
    }
  },
  {
    ruleTypeId: 7,
    ruleId: 7,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 0,
      percentage: 10
    }
  },
  {
    ruleTypeId: 8,
    ruleId: 8,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 15000,
      description: '',
      periods: 3
    }
  },
  {
    ruleTypeId: 8,
    ruleId: 8,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 0,
      description: 'Polo Americanino con ref: DSF123',
      periods: 3
    }
  },
  {
    ruleTypeId: 9,
    ruleId: 9,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 15000,
      description: '',
      period: '202302'
    }
  },
  {
    ruleTypeId: 9,
    ruleId: 9,
    brandId: 1,
    active: true,
    params: {
      thresholdAmount: 150000,
      commissionAmount: 0,
      description: 'Polo Americanino con ref: DSF123',
      period: '202302'
    }
  }
]
