import { BrandsCarousel } from '@molecules/index'
import styles from './index.module.scss'
import Link from 'next/link'
// import { SVGComponent } from '@atoms/index'

export default function BrandsSection() {
  return (
    <section role={'contentinfo'} className={styles.root}>
      <h2 className={styles.root__heading}>
        Estas son las <br />
        <strong>marcas que puedes vender</strong>
      </h2>
      <p className={styles.root__description}>
        Cada una de nuestras marcas maneja un porcentaje de comisión diferente
      </p>
      <BrandsCarousel />
      <div className={styles['root__actions-wrapper']}>
        <Link
          href={'/registro'}
          title={'Enlace al formulario de registro Kliiker'}
          className={styles.root__cta}>
          ¡Únete aquí!
        </Link>
      </div>
      {/* <SVGComponent
        svgPath="brands-section/decoration"
        className={styles.root__decoration}
      /> */}
    </section>
  )
}
