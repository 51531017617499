import { useIsMobile } from '@/hooks'
import { TCommission } from '@/interfaces'
import { CommissionTBody } from '@atoms/index'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'

interface ICommissionsTableProps {
  commissions?: TCommission[]
}

export default function ComissionsTable({
  commissions = []
}: ICommissionsTableProps) {
  const isMobile = useIsMobile()

  return (
    <table
      className={styles.root}
      aria-label={'Tabla de comisiones del Kliiker'}>
      <thead>
        <tr>
          <th></th>
          <th>Fecha</th>
          {!isMobile && <th colSpan={2}>Tipo</th>}
          <th>Valor</th>
          <th>Estado</th>
        </tr>
      </thead>
      {commissions.length === 0 ? (
        <tbody className={styles.root__empty}>
          <tr>
            <td colSpan={isMobile ? 4 : 6}>
              <FeatherIcon icon="inbox" className={styles.root__icon} />
            </td>
          </tr>
          <tr>
            <td colSpan={isMobile ? 4 : 6}>
              <p className={styles.root__message}>
                Aún no tienes comisiones registradas
              </p>
            </td>
          </tr>
        </tbody>
      ) : (
        commissions.map((commission, index) => (
          <CommissionTBody key={index} commission={commission} />
        ))
      )}
    </table>
  )
}
