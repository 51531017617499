import LogoKliiker from '@/assets/svg/logo-kliiker.svg'
import VideoSectionPlayButton from '@/assets/svg/video-section/play-button.svg'
import HomeHeroBottomDecoration from '@/assets/svg/home-hero/bottom-decoration.svg'
import HomeHeroTopDecoration from '@/assets/svg/home-hero/top-decoration.svg'
import HomeHeroBounce from '@/assets/svg/home-hero/bounce.svg'
import BrandsSectionDecoration from '@/assets/svg/brands-section/decoration.svg'
import BenefitsSectionDecoration from '@/assets/svg/benefits-section/decoration.svg'
import TestimonialsSectionQuoteIcon from '@/assets/svg/testimonials-section/quote-icon.svg'
import FooterSICLogo from '@/assets/svg/footer/sic-logo.svg'
import FloatingButtonWhatsAppIcon from '@/assets/svg/floating-button/whatsapp.svg'
import NavbarMenuDecoration from '@/assets/svg/navbar/menu-decoration.svg'
import SocialLinksLogoTikTok from '@/assets/svg/social-links/logo-tiktok.svg'
import MetricsSectionDecoration from '@/assets/svg/metrics-section/decoration.svg'

export type TSVGPath =
  | 'logo-kliiker'
  | 'video-section/play-button'
  | 'home-hero/bottom-decoration'
  | 'home-hero/top-decoration'
  | 'home-hero/bounce'
  | 'brands-section/decoration'
  | 'benefits-section/decoration'
  | 'testimonials-section/quote-icon'
  | 'footer/sic-logo'
  | 'floating-button/whatsapp'
  | 'navbar/menu-decoration'
  | 'social-links/logo-tiktok'
  | 'metrics-section/decoration'

interface ISVGElement {
  SvgElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  viewBox: string
}

type TSVGComponents = {
  [key in TSVGPath]: ISVGElement
}

export const svgComponents: TSVGComponents = {
  'logo-kliiker': {
    SvgElement: LogoKliiker,
    viewBox: '0 0 170 62'
  },
  'video-section/play-button': {
    SvgElement: VideoSectionPlayButton,
    viewBox: '0 0 80.021 80'
  },
  'home-hero/bottom-decoration': {
    SvgElement: HomeHeroBottomDecoration,
    viewBox: '0 0 612.086 412'
  },
  'home-hero/top-decoration': {
    SvgElement: HomeHeroTopDecoration,
    viewBox: '0 0 612.916 421.512'
  },
  'home-hero/bounce': {
    SvgElement: HomeHeroBounce,
    viewBox: '0 0 74.698 77.738'
  },
  'brands-section/decoration': {
    SvgElement: BrandsSectionDecoration,
    viewBox: '0 0 200.027 104.553'
  },
  'benefits-section/decoration': {
    SvgElement: BenefitsSectionDecoration,
    viewBox: '0 0 637 660'
  },
  'testimonials-section/quote-icon': {
    SvgElement: TestimonialsSectionQuoteIcon,
    viewBox: '0 0 48 36.674'
  },
  'footer/sic-logo': {
    SvgElement: FooterSICLogo,
    viewBox: '0 0 1124 247'
  },
  'floating-button/whatsapp': {
    SvgElement: FloatingButtonWhatsAppIcon,
    viewBox: '0 0 512.000000 512.000000'
  },
  'navbar/menu-decoration': {
    SvgElement: NavbarMenuDecoration,
    viewBox: '0 0 156 105.005'
  },
  'social-links/logo-tiktok': {
    SvgElement: SocialLinksLogoTikTok,
    viewBox: '0 0 512 512'
  },
  'metrics-section/decoration': {
    SvgElement: MetricsSectionDecoration,
    viewBox: '0 0 203 104'
  }
}
