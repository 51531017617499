import { Montserrat, Reenie_Beanie } from '@next/font/google'
import { Provider } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SessionProvider } from 'next-auth/react'
import { setupStore } from '@/state/store'
import '~styles/globals.scss'
import { GOOGLE_RECAPTCHA_SITE_KEY } from '@/utils/constants'
import { TCustomAppProps } from '@/types'
import Script from 'next/script'

const montserrat = Montserrat({
  variable: '--font-montserrat',
  subsets: ['latin', 'latin-ext'],
  display: 'swap'
})

const reenie = Reenie_Beanie({
  weight: '400',
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['ui-sans-serif', 'sans-serif'],
  adjustFontFallback: false
})

export default function App({
  Component,
  pageProps: { session, ...pageProps }
}: TCustomAppProps) {
  return (
    <>
      {/* <!-- Google Tag Manager --> */}
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          
          })(window,document,'script','dataLayer','GTM-TXM6J8F');
        `}
      </Script>
      {/* <!-- End Google Tag Manager --> */}
      <style jsx global>{`
        :root {
          --font-montserrat: ${montserrat.style.fontFamily};
          --font-reenie-beanie: ${reenie.style.fontFamily};
        }
      `}</style>
      <Provider store={setupStore()}>
        <SessionProvider session={session}>
          {Component.gCaptcha === true ? (
            <GoogleReCaptchaProvider
              reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}
              scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
                nonce: undefined
              }}>
              <Component {...pageProps} />
            </GoogleReCaptchaProvider>
          ) : (
            <Component {...pageProps} />
          )}
        </SessionProvider>
      </Provider>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TXM6J8F"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  )
}
