import Link from 'next/link'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { useCallback, useEffect, useState } from 'react'

interface IKliikerLinkCardProps {
  kliikerId?: string
}

type TTooltipStates = 'Copiar' | '¡Copiado!'
type TTooltipTwoStates = 'Generar' | 'Copiar'
type TTooltipThreeStates = 'Eliminar' | 'Pegar'

export default function KliikerLinkCard({
  kliikerId = ''
}: IKliikerLinkCardProps) {
  const [tooltipText, setTooltipText] = useState<TTooltipStates>('Copiar')
  const [tooltipTextTwo, setTooltipTextTwo] =
    useState<TTooltipTwoStates>('Generar')
  const [tooltipTextThree, setTooltipTextThree] =
    useState<TTooltipThreeStates>('Pegar')
  const [customKliikerLink, setCustomKliikerLink] = useState<string>('')

  const kliikerLink = `www.tienda.kliiker.com/?kliikerId=${kliikerId}`

  const handleCustomLinkSubmit = useCallback(async () => {
    if (customKliikerLink !== '' && !customKliikerLink.includes('kliikerId')) {
      let newCustomKliikerLink
      if (customKliikerLink.includes('?')) {
        newCustomKliikerLink = `${customKliikerLink}&kliikerId=${kliikerId}`
      } else {
        newCustomKliikerLink = `${customKliikerLink}?kliikerId=${kliikerId}`
      }

      if (customKliikerLink !== newCustomKliikerLink) {
        setCustomKliikerLink(newCustomKliikerLink)
      }
    }

    if (customKliikerLink !== '') {
      setTooltipTextTwo('Copiar')
      await navigator.clipboard.writeText(customKliikerLink)
    }
  }, [customKliikerLink, kliikerId])

  const handlePasteAndDelete = useCallback(async () => {
    if (customKliikerLink === '')
      return setCustomKliikerLink(await navigator.clipboard.readText())
    if (customKliikerLink !== '') setTooltipTextThree('Eliminar')
    setCustomKliikerLink('')
  }, [customKliikerLink])

  useEffect(() => {
    if (customKliikerLink === '') return setTooltipTextTwo('Generar')
    if (customKliikerLink !== '' && !customKliikerLink.includes('kliikerId'))
      setTooltipTextTwo('Generar')
    else setTooltipTextTwo('Copiar')
  }, [customKliikerLink])

  useEffect(() => {
    if (customKliikerLink === '') return setTooltipTextThree('Pegar')
    else setTooltipTextThree('Eliminar')
  }, [customKliikerLink])

  return (
    <div role={'group'} className={styles.root}>
      {kliikerId === '' && (
        <Link
          title="Enlace para crear el link de Kliiker"
          className={styles.root__link}
          href={'/portal/perfil'}>
          Crear link
        </Link>
      )}
      <h2 className={styles.root__title}>¡Comparte y comisiona!</h2>
      {kliikerId === '' && (
        <h3 className={styles.root__subtitle}>
          Crea tu link único de comisiones y ventas.
        </h3>
      )}
      <p className={styles.root__description}>
        {kliikerId === ''
          ? 'Recuerda este link será visible para todos tus compradores, piensa un gran nombre y crea tu identidad.'
          : 'Copia tu link único de Kliiker y comparte en tus redes sociales.'}
      </p>
      {kliikerId !== '' && (
        <div className={styles['root__kliiker-id-wrapper']}>
          <span className={styles['root__kliiker-id']}>{kliikerLink}</span>
          <button
            className={styles['root__copy-button']}
            type={'button'}
            title="Copiar link en portapapeles"
            onClick={async () => {
              setTooltipText('¡Copiado!')
              await navigator.clipboard.writeText(kliikerLink)
            }}
            onMouseLeave={() => {
              if (tooltipText === 'Copiar') return
              setTooltipText('Copiar')
            }}>
            <FeatherIcon icon="copy" className={styles['root__copy-icon']} />
            <span className={styles['root__copy-tooltip']}>{tooltipText}</span>
          </button>
        </div>
      )}
      {kliikerId !== '' && (
        <form className={styles['root__kliiker-link-container']}>
          <p className={styles['root__kliiker-link-text']}>
            ¿Tienes algún producto o categoría que quieras compartir?
          </p>
          <label
            className={styles['root__kliiker-id-wrapper']}
            data-spacing="none"
            onClick={async () =>
              customKliikerLink === '' &&
              setCustomKliikerLink(await navigator.clipboard.readText())
            }
            htmlFor="customKliikerLink">
            <input
              className={styles['root__kliiker-link']}
              id="customKliikerLink"
              value={customKliikerLink}
              onChange={(e) => {
                setCustomKliikerLink(e.target.value)
              }}
              placeholder="Pega el link del producto o categoría aquí"
              type="text"
              min={1}
            />
            <button
              className={styles['root__share-button']}
              type={'button'}
              title="Generar link de producto o categoría"
              onClick={async () => await handleCustomLinkSubmit()}>
              <FeatherIcon
                icon={tooltipTextTwo === 'Copiar' ? 'copy' : 'refresh-cw'}
                className={styles['root__share-icon']}
              />
              <span className={styles['root__copy-tooltip']}>
                {tooltipTextTwo}
              </span>
            </button>
            <button
              className={styles['root__delete-button']}
              type={'button'}
              title="Copiar link en portapapeles"
              onClick={async () => await handlePasteAndDelete()}>
              <FeatherIcon
                icon={customKliikerLink !== '' ? 'trash' : 'clipboard'}
                className={styles['root__delete-icon']}
              />
              <span className={styles['root__copy-tooltip']}>
                {tooltipTextThree}
              </span>
            </button>
          </label>
        </form>
      )}
    </div>
  )
}
