import { IKliikerLoginFormFields } from '@/interfaces'
import { kliikerLoginFormService } from '@/services'
import { IMessageToast, SVGComponent } from '@atoms/index'
import { LoginForm } from '@molecules/index'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styles from './index.module.scss'

export default function LoginSection() {
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const router = useRouter()

  const handleFormSubmit: SubmitHandler<IKliikerLoginFormFields> = async (
    data
  ) => {
    try {
      await kliikerLoginFormService(data)
      router.replace('/portal')
    } catch (ex) {
      setMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  return (
    <section role={'group'} className={styles.root}>
      <SVGComponent
        svgPath="home-hero/top-decoration"
        className={styles.root__decoration}
      />
      <hgroup className={styles.root__heading}>
        <h1 className={styles.root__title}>
          ¡Hola! <strong>Que placer verte de nuevo</strong>
        </h1>
        <p className={styles.root__description}>
          Por favor, digita tu correo electrónico y tu contraseña.
        </p>
      </hgroup>
      <div className={styles['root__content-wrapper']}>
        <div className={styles['root__form-wrapper']}>
          <LoginForm
            messages={messages}
            onMessageDismiss={(index) =>
              setMessages((currentMessages) =>
                currentMessages.filter(
                  (_, currentIndex) => index !== currentIndex
                )
              )
            }
            onSubmit={handleFormSubmit}
          />
        </div>
      </div>
    </section>
  )
}
