import { ITestimonial } from '@/interfaces'
import placeholder from '@/assets/img/placeholder.webp'

export const mockedTestimonial: ITestimonial = {
  name: 'John Doe',
  city: 'Bogotá',
  picture: {
    title: 'Imagen de perfil del Kliiker',
    url: placeholder.src,
    width: 69,
    height: 69
  },
  testimonial:
    'Lorem ipsum dolor sit amet consectetur adipiscing elit integer massa, potenti pharetra dignissim aenean porta posuere vehicula himenaeos condimentum magnis, fringilla suscipit molestie dictum sagittis consequat varius diam. Rutrum eget eu donec ac morbi elementum at aptent aenean interdum, viverra aliquet et cubilia nam ridiculus dapibus hendrerit.'
}

export const mockedTestimonialsArray: ITestimonial[] = [
  mockedTestimonial,
  mockedTestimonial,
  mockedTestimonial,
  mockedTestimonial
]
