import { AccordionItemVideo, IAccordionChangeEvent } from '@atoms/index'
import styles from './index.module.scss'
import { useState } from 'react'
// import { IMovieListForModule } from '@/interfaces/module-list-academy/video-module'

// interface IAccordionProps {
//   moduleVideo?: IMovieListForModule[];
// }

export default function AccordionVideo({ items = [] }: any) {

  const groupByModuleOrder = (data: any) => {
    const acc: { [x: string]: any[] } = {};

    data.forEach((obj: { moduleOrder: any }) => {
      const key = obj.moduleOrder;
      if (acc[key] === undefined) {
        acc[key] = [];
      }
      acc[key].push(obj);
    });

    return Object.values(acc);
  };

  const result = groupByModuleOrder(items);

  const [index, setIndex] = useState(-1)

  const handleUpdate = (event?: IAccordionChangeEvent) => {
    if (event !== undefined) {
      const { isActive, item } = event
      const nextIndex = parseInt(item?.dataset.index ?? '-1')
      if (isActive && nextIndex !== -1) setIndex(nextIndex)
      else setIndex(-1)
    }
  }

  return (
    <div role={'list'} className={styles.root}>
      {result.map((item, key) => (
        <AccordionItemVideo
          key={key}
          items={item}
          active={index === key}
          data-index={key}
          onChange={handleUpdate}>
        </AccordionItemVideo>
      ))}
    </div>
  )
}
