import styles from './index.module.scss'
import FeatherIcon from 'feather-icons-react'
interface ISearchInputProps {
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  onClick?: () => void
}

function SearchInput({
  placeholder,
  value,
  onChange,
  onClick
}: ISearchInputProps) {
  return (
    <div className={styles.root}>
      <label className={styles.root__label} htmlFor="SearchInput">
        <input
          className={styles.root__searchInput}
          id="SearchInput"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
        />
      </label>
      <button className={styles.root__button} type="button" onClick={onClick}>
        <FeatherIcon icon={value !== '' ? 'x' : 'search'} />
      </button>
    </div>
  )
}

export default SearchInput
