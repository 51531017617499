import { IBenefit } from '@/interfaces'
import { RootState } from '@/state/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IBenefitsAccordionState {
  benefits: IBenefit[]
}

const initialState: IBenefitsAccordionState = {
  benefits: []
}

const benefitsAccordionSlice = createSlice({
  name: 'benefitsAccordion',
  initialState,
  reducers: {
    setBenefits: (state, action: PayloadAction<IBenefit[]>) => {
      state.benefits = action.payload
    }
  }
})

export const { setBenefits } = benefitsAccordionSlice.actions

export const selectBenefits = (state: RootState) =>
  state.benefitsAccordion.benefits

export default benefitsAccordionSlice.reducer
