import Image from 'next/image'
import styles from './index.module.scss'
import fisapayLogo from '@/assets/img/fisapay-modal/logo-fisapay.png'
import { useAppSelector } from '@/state/hooks'
import { selectFisapayAgreement } from './index.slice'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { CheckboxGroup, IMessageToast, MessageToasts } from '@atoms/index'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ICheckboxFormGroup, IKliikerFisapayFormFields } from '@/interfaces'
import { useFormGroups, useKliikerFisapayFormFields } from '@/hooks'
import { getFieldProp } from '@/functions'
import { useState } from 'react'

interface IFisapayModalProps {
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onSubmit?: SubmitHandler<IKliikerFisapayFormFields>
}

export default function FisapayModal({
  messages = [],
  onMessageDismiss = () => {},
  onSubmit = () => {}
}: IFisapayModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { termsAgreement } = useAppSelector(selectFisapayAgreement)
  const formObject = useForm<IKliikerFisapayFormFields>({
    mode: 'all'
  })
  const fields = useKliikerFisapayFormFields()
  const groups = useFormGroups<
    IKliikerFisapayFormFields,
    ICheckboxFormGroup<IKliikerFisapayFormFields>
  >(fields, ['contractAgreement', 'termsAgreement'])

  const handleFormSubmit: SubmitHandler<IKliikerFisapayFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    await onSubmit(data)
    setIsLoading(false)
  }

  return (
    <div className={styles.root}>
      <dialog
        open
        className={styles.root__dialog}
        aria-label={'Modal de Fisapay'}>
        <form
          className={styles.root__form}
          role={'form'}
          onSubmit={formObject.handleSubmit(handleFormSubmit)}>
          <Image
            src={fisapayLogo}
            alt={'Logo de Fisapay'}
            className={styles.root__image}
            priority
          />
          <div className={styles['root__terms-wrapper']}>
            <div className={styles['root__terms-content']}>
              {termsAgreement !== null
                ? documentToReactComponents(termsAgreement)
                : ''}
            </div>
          </div>
          <div className={styles['root__checkbox-groups-wrapper']}>
            {groups.map(({ field, ...props }, key) => {
              const fieldProp = getFieldProp<IKliikerFisapayFormFields>(
                formObject,
                field
              )
              return <CheckboxGroup key={key} field={fieldProp} {...props} />
            })}
          </div>
          <p className={styles.root__advice}>
            * Fisapay te enviará un correo electrónico al aceptar los términos y
            condiciones.
          </p>
          {isLoading && <div className={styles.root__loading}></div>}
          {messages.length !== 0 && (
            <div className={styles.root__messages}>
              <MessageToasts
                dismissible
                messages={messages}
                onDismiss={onMessageDismiss}
              />
            </div>
          )}
          <button
            className={styles.root__submit}
            type={'submit'}
            aria-label={'Botón para enviar el formulario de Fisapay'}
            disabled={isLoading}>
            Aceptar
          </button>
        </form>
      </dialog>
    </div>
  )
}
