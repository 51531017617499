import { TCommission } from '@/interfaces'
import { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import {
  formatCommissionStatus,
  formatCommissionType,
  formatDate,
  parseCommissionStatus,
  parseCommissionType
} from '@/functions'
import AutorizationCommissionDetail from '@atoms/autorization-commission-detail'
import { InputCheck } from '..'

import styles from './index.module.scss'

type TDetailsStatus = 'collapsed' | 'expanded'

interface ICommissionTBodyProps {
  commission?: TCommission
  checked?: boolean
  handleCheck?: (value: number) => void
}

export default function AutorizationCommissionTBody({
  commission,
  checked,
  handleCheck = () => {}
}: ICommissionTBodyProps) {
  const [detailsStatus, setDetailsStatus] =
    useState<TDetailsStatus>('collapsed')
  return (
    <tbody
      className={styles.root}
      aria-label={'Detalle de comisión'}
      role={'group'}
      data-status={detailsStatus}>
      <tr
        className={styles.root__data}
        onClick={({ target }) => {
          if (target instanceof HTMLInputElement) return
          setDetailsStatus((current) =>
            current === 'collapsed' ? 'expanded' : 'collapsed'
          )
        }}>
        <td>
          <InputCheck
            htmlFor={`commission_${commission?.commissionId ?? -1}`}
            id={`commission_${commission?.commissionId ?? -1}`}
            value={`${commission?.commissionId ?? -1}`}
            dataColor="primary"
            checked={checked}
            handleCheck={() => {
              handleCheck(commission?.commissionId ?? -1)
            }}
          />
        </td>
        <td>
          {commission !== undefined && (
            <FeatherIcon
              className={styles.root__icon}
              icon={
                detailsStatus === 'collapsed' ? 'chevron-down' : 'chevron-up'
              }
            />
          )}
        </td>
        <td>{commission !== undefined && formatDate(commission.updatedAt)}</td>
        <td>{commission?.document}</td>
        <td>
          {commission !== undefined &&
            formatCommissionType(parseCommissionType(commission.ruleTypeId))}
        </td>
        <td>
          {commission?.ruleTypeId === 1
            ? commission?.params.sellRuleParams
                .map(({ brand }) => `${brand}`)
                .join(', ')
            : commission?.params.brand}
        </td>
        <td>
          {commission !== undefined && (
            <span
              className={styles.root__badge}
              data-status={parseCommissionStatus(commission.commissionStateId)}>
              {formatCommissionStatus(
                parseCommissionStatus(commission.commissionStateId)
              )}
            </span>
          )}
        </td>
      </tr>
      {commission !== undefined && (
        <AutorizationCommissionDetail
          commission={commission}
          visible={detailsStatus === 'expanded'}
        />
      )}
    </tbody>
  )
}
