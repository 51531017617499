import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/state/store'
import { Document } from '@contentful/rich-text-types'

export interface IPreregisterState {
  fulfillment: boolean
  termsAgreement: Document | null
}

const initialState: IPreregisterState = {
  fulfillment: false,
  termsAgreement: null
}

export const preRegisterSlice = createSlice({
  name: 'preRegister',
  initialState,
  reducers: {
    setFulfillment: (state, action: PayloadAction<boolean>) => {
      state.fulfillment = action.payload
    },
    setTermsAgreement: (state, action: PayloadAction<Document | null>) => {
      state.termsAgreement = action.payload
    }
  }
})

export const { setFulfillment, setTermsAgreement } = preRegisterSlice.actions

export const selectPreRegister = (state: RootState) => state.preRegister

export default preRegisterSlice.reducer
