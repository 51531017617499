import { useEffect, useState } from 'react'
import { TableWrapper } from '..'
import { useSession } from 'next-auth/react'
import { IKliikerFilterDateFormFields, TCommission } from '@/interfaces'
import { TAlertStatus, Alert, DateForm } from '@molecules/index'
import AutorizationComissionsTable from '@molecules/autorization-comissions-table'
import FeatherIcon from 'feather-icons-react'

import styles from './index.module.scss'
import {
  getAdminGeneratedCommissions,
  sendAdminCommissionsAuthorization,
  getAdminCommissionsToAuthorize
} from '@/services'
import { IMessageToast } from '@atoms/message-toasts'
import { SubmitHandler, useForm } from 'react-hook-form'

import SearchInput from '@atoms/search-input'
import SelectGroup from '@atoms/select-group'
import UploadFileModal from '@molecules/upload-file-modal'
import { IUploadFileFormFields } from '@/interfaces/upload-file-modal'
import { csvToJson } from '@/functions'

interface IJson {
  COMMISSION_ID: string
  DOCUMENT: string
  TYPE_RULE: string
  COMMISSION_STATE: string
  AMOUNT: string
  ORDER_ID: string
  PAYMENT_SYSTEM: string
}

export default function AutorizationComissionSection() {
  const tableSize = 20
  const [tableData, setTableData] = useState<TCommission[]>([])
  const [refreshTableCount, setRefreshTableCount] = useState(0)
  const [isAutorizable, setIsAutorizable] = useState(false)
  const [commissionToAuthorize, setCommissionsToAuthorize] = useState<number[]>(
    []
  )
  const [reportUrl, setReportUrl] = useState('')
  const [pageActive, setPageActive] = useState(1)
  const [countRegister, setCountRegister] = useState(1)
  const [countPages, setCountPages] = useState(1)
  const [alertStatus, setAlertStatus] = useState<TAlertStatus>('closed')
  const [alertMessages, setAlertMessages] = useState<IMessageToast[]>([])
  const [actionType, setActionType] = useState<
    'autorize-comission' | 'download-report' | ''
  >('download-report')
  const [documentSearch, setDocumentSearch] = useState<string>('')
  const { data } = useSession()
  const idToken = data?.idToken
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const [tableOptions, setTableOptions] = useState<{
    startDate?: Date
    endDate?: Date
  }>({})
  const [downloadDate, setDownloadDate] = useState<{
    startDate?: Date
    endDate?: Date
  }>()
  const [downloadAuthorize, setDownloadAuthorize] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const selectsFormObject = useForm<{
    state: string
  }>()
  const handlePageChange = (page: number) => {
    setPageActive(page)
  }

  const handleTableChange = (commissions: number[]) => {
    setCommissionsToAuthorize(commissions)
    setIsAutorizable(commissions.length > 0)
  }
  const handleOnDissmiss = (index: number) => {
    setMessages((currentMesages) =>
      currentMesages.filter((_, curentIndex) => index !== curentIndex)
    )
  }

  const handleFilterFormSubmit: SubmitHandler<
    IKliikerFilterDateFormFields
  > = async (data) => {
    try {
      const newDates: { startDate?: Date; endDate?: Date } = {
        startDate: undefined,
        endDate: undefined
      }
      let isStartDate = false
      let isEndDate = false
      if (data.startDate !== undefined && data.startDate !== '') {
        newDates.startDate = new Date(data.startDate)
        isStartDate = true
      }
      if (data.endDate !== undefined && data.endDate !== '') {
        newDates.endDate = new Date(data.endDate)
        isEndDate = true
      }
      setDownloadAuthorize(isStartDate && isEndDate)
      if (isStartDate && isEndDate) {
        setDownloadDate(newDates)
      }

      setPageActive(1)
      setTableOptions((oldOptions) => ({
        ...oldOptions,
        ...newDates
      }))
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  const handleDownloadFileAuthorizationSubmit = async () => {
    try {
      setDownloadAuthorize(false)
      setIsLoading(true)
      if (idToken === undefined)
        throw new Error('¡Algo salio mal!😰', {
          cause: 'No-Id-Token'
        })

      if (downloadDate === undefined) {
        throw new Error('¡Algo salio mal!😰', {
          cause: 'No-Id-Token'
        })
      }

      const startDate = new Date(
        downloadDate.startDate === undefined
          ? new Date()
          : downloadDate.startDate
      )
      const endDate = new Date(
        downloadDate.endDate === undefined ? new Date() : downloadDate.endDate
      )

      const response = await getAdminCommissionsToAuthorize({
        idToken,
        startDate,
        endDate
      })
      window.open(response.url, '_blank')
      setIsLoading(false)
    } catch (ex) {
      setAlertMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
      setIsLoading(false)
      setDownloadAuthorize(false)
    }
  }

  const handleUploadFileFormSubmit: SubmitHandler<
    IUploadFileFormFields
  > = async (data) => {
    try {
      if (data.file === undefined) return
      const json: IJson[] = csvToJson(data.file)
      if (actionType === '') {
        if (idToken === undefined)
          throw new Error('¡Algo salio mal!😰', {
            cause: 'No-Id-Token'
          })
        const commissionIds: number[] = json.map((item) => {
          return Number(item.COMMISSION_ID)
        })
        const response = await sendAdminCommissionsAuthorization({
          idToken,
          commissionIds
        })
        setReportUrl(response.url)
        setRefreshTableCount((oldCount) => oldCount + 1)
      } else {
        window.open(reportUrl, '_blank')
      }
      setRefreshTableCount((oldCount) => oldCount + 1)
      setAlertStatus('closed')
    } catch (ex) {
      setAlertMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
      setAlertStatus('open')
    }
  }

  const handleSearchInput = (value: string) => {
    setDocumentSearch(value)
  }

  useEffect(() => {
    async function fetchTable() {
      if (idToken === undefined) return
      try {
        const response = await getAdminGeneratedCommissions({
          idToken,
          queryOptions: {
            ...tableOptions,
            page: pageActive - 1,
            size: tableSize,
            documentSearch,
            state: selectsFormObject.getValues('state')
          }
        })
        setTableData(response.data)
        setCountRegister(response.countRegister)
        setCountPages(response.countPage)
      } catch (ex) {}
    }
    fetchTable()
  }, [
    documentSearch,
    idToken,
    pageActive,
    refreshTableCount,
    selectsFormObject,
    tableOptions
  ])

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección del perfil del Kliiker'}
      className={styles.root}>
      <div className={styles['root__container-filter']}>
        <DateForm
          typeSection="autorization-comission"
          messages={messages}
          onMessageDismiss={handleOnDissmiss}
          onSubmit={handleFilterFormSubmit}
          isOnlyIcon
          typeIcon="search"
        />
        <SearchInput
          placeholder="Digita la Cedula Aquí"
          onChange={handleSearchInput}
          onClick={() => {
            setDocumentSearch('')
            setPageActive(1)
          }}
          value={documentSearch}
        />
        <div className={styles.root__state}>
          <SelectGroup
            label="Estado Comisión"
            field={{ id: 'state', formObject: selectsFormObject }}
            itemsFiller={() => [
              { label: 'Todas', value: '0' },
              { label: 'Generadas', value: '3' },
              { label: 'En Proceso', value: '1' }
            ]}
            onChange={(value) => {
              setPageActive(1)
              setTableOptions((oldOptions) => ({
                ...oldOptions,
                state:
                  value === 'all'
                    ? undefined
                    : selectsFormObject.getValues('state')
              }))
            }}
          />
        </div>
      </div>
      <div className={styles['root__container-authorization']}>
        <button
          className={styles['root__upload-file']}
          type="button"
          onClick={() => {
            setActionType('')
            setAlertMessages([])
            if (alertStatus === 'closed') {
              setAlertStatus('open')
            } else {
              setAlertStatus('closed')
            }
          }}>
          Cargar
          <FeatherIcon
            icon="info"
            className={styles['root__upload-info']}
            height={16}
          />
          <span className={styles['root__upload-info-text']}>
            Cargar un archivo .csv para autorizar automáticamente.
          </span>
        </button>
        <button
          disabled={!downloadAuthorize}
          className={styles['root__download-file']}
          type="button"
          onClick={() => {
            handleDownloadFileAuthorizationSubmit()
          }}>
          Descargar
          <FeatherIcon
            icon="info"
            className={styles['root__download-info']}
            height={18}
          />
          <span className={styles['root__upload-info-text']}>
            Establezca un rango de fechas para descargar un archivo .csv y
            autorizar las comisiones de forma automática.
          </span>
        </button>
      </div>
      <div className={styles.root__content}>
        <TableWrapper
          title="Listado de comisiones"
          elements={[
            {
              label: 'AUTORIZAR',
              icono: 'check',
              disabled: !isAutorizable,
              handleClick: () => {
                setActionType('autorize-comission')
                setAlertMessages([])
                if (alertStatus === 'closed') {
                  setAlertStatus('open')
                } else {
                  setAlertStatus('closed')
                }
              }
            },
            {
              label: 'DESCARGAR REPORTE',
              icono: 'download-cloud',
              disabled: reportUrl === '',
              handleClick: () => {
                setActionType('download-report')
                setAlertMessages([])
                if (alertStatus === 'closed') {
                  setAlertStatus('open')
                } else {
                  setAlertStatus('closed')
                }
              }
            }
          ]}
          handlePageChange={handlePageChange}
          resultsNumber={countRegister}
          totalPages={countPages}
          resultsPerPage={tableSize}>
          <AutorizationComissionsTable
            refreshCount={refreshTableCount}
            commissions={tableData}
            onChange={handleTableChange}
          />
        </TableWrapper>
        {actionType !== 'autorize-comission' &&
        actionType !== 'download-report' ? (
          <UploadFileModal
            status={alertStatus}
            messages={alertMessages}
            onMessageDismiss={() => setAlertMessages([])}
            handleCancell={() => setAlertStatus('closed')}
            onSubmit={handleUploadFileFormSubmit}
          />
        ) : (
          <Alert
            status={alertStatus}
            type={actionType}
            messages={alertMessages}
            onResponse={async (response) => {
              if (alertStatus === 'waiting') return
              if (!response) {
                setAlertStatus('closed')
                return
              }
              setAlertStatus('waiting')
              try {
                if (actionType === 'autorize-comission') {
                  if (idToken === undefined)
                    throw new Error('¡Algo salio mal!😰', {
                      cause: 'No-Id-Token'
                    })
                  const response = await sendAdminCommissionsAuthorization({
                    idToken,
                    commissionIds: commissionToAuthorize
                  })
                  setReportUrl(response.url)
                  setRefreshTableCount((oldCount) => oldCount + 1)
                } else {
                  window.open(reportUrl, '_blank')
                }
                setAlertStatus('closed')
              } catch (ex) {
                setAlertMessages((oldMessages) => [
                  ...oldMessages,
                  { type: 'error', message: (ex as Error).message }
                ])
                setAlertStatus('open')
              }
            }}
          />
        )}
      </div>
      {isLoading && <div className={styles.root__loading}></div>}
    </section>
  )
}
