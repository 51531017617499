import {
  formatAmount,
  formatCommissionMessage,
  formatCommissionType,
  formatSellItemName,
  parseCommissionType
} from '@/functions'
import { useIsMobile } from '@/hooks'
import { TCommission } from '@/interfaces'
import styles from './index.module.scss'

interface ICommissionDetailRowProps {
  commission?: TCommission
  visible?: boolean
}

type TTrType = 'message' | 'sell-header' | 'sell-item' | 'sell-footer'

export default function CommissionDetailRow({
  commission,
  visible = false
}: ICommissionDetailRowProps) {
  const isMobile = useIsMobile()
  const getTrProps = (type: TTrType) => ({
    className: styles.root,
    'data-status': visible ? 'visible' : 'hidden',
    'data-type': type
  })

  if (commission === undefined) return <></>
  else if (commission.ruleTypeId !== 1)
    return (
      <tr {...getTrProps('message')}>
        <td colSpan={isMobile ? 4 : 6}>
          {isMobile && (
            <p>
              <strong>Tipo:</strong>{' '}
              {formatCommissionType(parseCommissionType(commission.ruleTypeId))}
            </p>
          )}
          <p>
            <strong>Detalle:</strong> {formatCommissionMessage(commission)}
          </p>
        </td>
      </tr>
    )
  else
    return (
      <>
        {isMobile && (
          <tr {...getTrProps('message')}>
            <td colSpan={4}>
              <p>
                <strong>Tipo:</strong>{' '}
                {formatCommissionType(
                  parseCommissionType(commission.ruleTypeId)
                )}
              </p>
              <p>
                <strong>Order ID:</strong> {commission.params.externalId}
              </p>
            </td>
          </tr>
        )}
        <tr {...getTrProps('sell-header')}>
          {!isMobile && <td></td>}
          <td colSpan={isMobile ? 2 : undefined}>
            {isMobile ? 'Producto' : 'SKU'}
          </td>
          {!isMobile && <td>Producto</td>}
          <td>Marca</td>
          <td>Comisión</td>
          {!isMobile && <td>Valor de venta</td>}
        </tr>
        {commission.params.itemParams.map((item, index, { length }) => {
          const formattedName = formatSellItemName(item.name, item.qty)
          return (
            <tr
              key={index}
              {...getTrProps('sell-item')}
              data-position={
                index === 0
                  ? 'first'
                  : index === length - 1
                  ? 'last'
                  : undefined
              }>
              {!isMobile && <td></td>}
              <td colSpan={isMobile ? 2 : undefined}>
                {isMobile ? (
                  <>
                    {formattedName} <br />
                    <small>SKU {item.sku}</small>
                  </>
                ) : (
                  item.sku
                )}
              </td>
              {!isMobile && <td>{formattedName}</td>}
              <td>{item.brand}</td>
              <td>{formatAmount(item.commission)}</td>
              {!isMobile && <td>{formatAmount(item.amount)}</td>}
            </tr>
          )
        })}
        <tr {...getTrProps('sell-footer')}>
          {!isMobile && <td></td>}
          <td colSpan={isMobile ? 3 : 2}>
            {isMobile ? (
              'Total'
            ) : (
              <>
                <strong>Order ID:</strong> {commission.params.externalId}
              </>
            )}
          </td>
          {!isMobile && <td>Total</td>}
          <td>
            {formatAmount(
              commission.params.itemParams
                .map<number>((item) => item.commission)
                .reduce((total, current) => total + current, 0)
            )}
          </td>
          {!isMobile && (
            <td>
              {formatAmount(
                commission.params.itemParams
                  .map<number>((item) => item.amount)
                  .reduce((total, current) => total + current, 0)
              )}
            </td>
          )}
        </tr>
      </>
    )
}
