import {
  IInputFormGroup,
  IKliikerEditDataFormFields,
  IPictureSelectorFormGroup,
  ISelectFormGroup,
  TSelectItemsFiller,
  TSelectOnChangeHandler
} from '@/interfaces'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  getFormGroups,
  getFormGroupsMap,
  getKliikerEditDataFormFields
} from '@/functions'

export function useEditDataFormGroupsMap(
  formObject: UseFormReturn<IKliikerEditDataFormFields>,
  stateItemsFiller: TSelectItemsFiller,
  cityItemsFiller: TSelectItemsFiller,
  onStateChange: TSelectOnChangeHandler = () => {},
  selectedStateId?: string
) {
  return useMemo(() => {
    const fields = getKliikerEditDataFormFields()
    const inputGroupsFields = getFormGroups<
      IKliikerEditDataFormFields,
      IInputFormGroup<IKliikerEditDataFormFields>
    >(fields, ['firstName', 'lastName', 'docNumber', 'phoneNumber', 'email'])
    const inputGroups = getFormGroupsMap<
      IKliikerEditDataFormFields,
      IInputFormGroup<IKliikerEditDataFormFields>
    >('input', inputGroupsFields, formObject, [], ['docNumber', 'email'])
    const pictureGroupFields = getFormGroups<
      IKliikerEditDataFormFields,
      IPictureSelectorFormGroup<IKliikerEditDataFormFields>
    >(fields, ['picture'])
    const pictureGroups = getFormGroupsMap<
      IKliikerEditDataFormFields,
      IPictureSelectorFormGroup<IKliikerEditDataFormFields>
    >('picture', pictureGroupFields, formObject)
    const [stateCode, cityCode] = getFormGroups<
      IKliikerEditDataFormFields,
      ISelectFormGroup<IKliikerEditDataFormFields>
    >(fields, ['stateCode', 'cityCode'])
    stateCode.onChange = onStateChange
    stateCode.itemsFiller = stateItemsFiller
    cityCode.dependencyValue = selectedStateId
    cityCode.itemsFiller = cityItemsFiller
    const selectGroups = getFormGroupsMap<
      IKliikerEditDataFormFields,
      ISelectFormGroup<IKliikerEditDataFormFields>
    >('select', [stateCode, cityCode], formObject)
    return new Map([...inputGroups, ...pictureGroups, ...selectGroups])
  }, [
    formObject,
    selectedStateId,
    onStateChange,
    cityItemsFiller,
    stateItemsFiller
  ])
}

export function getEditDataFormGroups(): Array<{
  title: string
  items: Array<Array<keyof IKliikerEditDataFormFields>>
}> {
  return [
    {
      title: 'Datos personales',
      items: [['picture'], ['firstName', 'lastName'], ['docNumber']]
    },
    {
      title: 'Ubicación',
      items: [['stateCode', 'cityCode']]
    },
    {
      title: 'Datos de contacto',
      items: [['phoneNumber', 'email']]
    }
  ]
}

export function useEditDataFormGroups() {
  return useMemo(() => getEditDataFormGroups(), [])
}
