import styles from './index.module.scss'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IMessageToast, InputGroup, MessageToasts } from '@atoms/index'
import {
  IInputFormGroup,
  IKliikerRecoveryRequestFormFields
} from '@/interfaces'
import { useFormGroups, useKliikerRecoveryRequestFormFields } from '@/hooks'
import { getFieldProp } from '@/functions'
import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface IRecoveryRequestFormProps {
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onSubmit?: SubmitHandler<IKliikerRecoveryRequestFormFields>
  disabled?: boolean
}

export default function RecoveryRequestForm({
  messages = [],
  onMessageDismiss,
  onSubmit,
  disabled = false
}: IRecoveryRequestFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const formObject = useForm<IKliikerRecoveryRequestFormFields>({
    mode: 'all'
  })
  const fields = useKliikerRecoveryRequestFormFields()
  const groups = useFormGroups<
    IKliikerRecoveryRequestFormFields,
    IInputFormGroup<IKliikerRecoveryRequestFormFields>
  >(fields, ['email'])

  const handleFormSubmit: SubmitHandler<
    IKliikerRecoveryRequestFormFields
  > = async (data) => {
    setIsLoading(true)
    if (executeRecaptcha !== undefined)
      data.gCaptcha = await executeRecaptcha('kliikerRecoveryRequestFormSubmit')
    await onSubmit?.(data)
    setIsLoading(false)
  }

  return (
    <form
      className={styles.root}
      role={'form'}
      onSubmit={formObject.handleSubmit(handleFormSubmit)}>
      <div className={styles['root__input-groups']}>
        {groups.map(({ field, ...props }, key) => {
          const fieldProp = getFieldProp<IKliikerRecoveryRequestFormFields>(
            formObject,
            field
          )
          return (
            <InputGroup<IKliikerRecoveryRequestFormFields>
              key={key}
              field={fieldProp}
              {...props}
            />
          )
        })}
      </div>
      <button
        type={'submit'}
        className={styles['root__submit-button']}
        disabled={disabled || isLoading}
        aria-label="Botón para enviar la solicitud de recuperación">
        Generar Código
      </button>
      {isLoading && <div className={styles.root__loading}></div>}
      {messages.length !== 0 && (
        <div className={styles.root__messages}>
          <MessageToasts
            dismissible
            messages={messages}
            onDismiss={onMessageDismiss}
          />
        </div>
      )}
    </form>
  )
}
