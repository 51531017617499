import { IKliikerProfile, IMetricskliiker, IServiceSession } from '@/interfaces'

export const mockedEmptyKliikerProfile: IKliikerProfile = {
  linkId: '',
  picture: '',
  identification: { docNumber: '', firstName: '', lastName: '' },
  location: { stateCode: '', cityCode: '' },
  contact: { email: '', phoneNumber: '' },
  metrics: { sells: 0, views: 0 }
}

export const mockedFullKliikerProfile: IKliikerProfile = {
  linkId: 'elchechoman',
  picture: 'https://www.kliiker.com/wp-content/uploads/2023/01/hombre.jpg',
  identification: {
    docNumber: '1234567890',
    firstName: 'Sergio',
    lastName: 'Guerrero'
  },
  location: { stateCode: '5', cityCode: '5001' },
  contact: { email: 'sguerrero@gmail.com', phoneNumber: '576015800563' },
  metrics: { sells: 450, views: 600 }
}

export const mockedFullServiceSession: IServiceSession = {
  user: {
    acceptedFisapayContract: true,
    acceptedFisapayTreatment: true,
    acceptedKliikerTerms: true,
    cityId: null,
    createdAt: '2023-03-10T16:27:52.45011',
    document: '123456',
    email: 'jhon.doe@sample.com',
    firstname: 'jhon',
    lastname: 'doe',
    phone: '123456789',
    password: 'Kliiker123',
    photoProfile: null,
    roleId: 0,
    termsVersion: '1.0.0',
    uniqueAlias: null,
    userId: 1
  },
  tokens: {
    accessToken: '12345asdf',
    expiresIn: 900,
    idToken: '1234asdf',
    refreshToken: '1234asdf',
    tokenType: 'Bearer'
  }
}

export const mockedMetricsKliiker: IMetricskliiker = {
  document: '1234567890',
  convertRate: 0.5,
  metricId: 1,
  visualizations: 100,
  sales: 50
}
