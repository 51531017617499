/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styles from './index.module.scss'
import Image from 'next/image'
import IconCheck from '@/assets/img/img-segmentation/check.png'
import IconNotCompleted from '@/assets/img/img-segmentation/negative.png'
import FeatherIcon from 'feather-icons-react'
import { convertToCOP } from '@/utils/convertToCOP'
import { translateMonthToSpanish } from '@/utils/translateMonthToSpanish'
import { orderMonths } from '@/utils/orderMonths'
import { IInfoSegmentation } from '@/interfaces/module-segmentation/segmentation'

interface SalesTableProps {
  infoTable: IInfoSegmentation | undefined
}

const SalesTable = ({ infoTable }: SalesTableProps) => {
  if (infoTable === undefined) return null

  const sortedMonths = orderMonths(infoTable.monthsells)

  return (
    <table className={styles.root__sales__table_container}>
      <thead>
        <tr>
          <th className={styles.root__sales__table_container__th_nav_month}>
            Mes
          </th>
          {Object.keys(sortedMonths).map((key) => (
            <th
              className={styles.root__sales__table_container__th_month}
              key={key}>
              {translateMonthToSpanish(key)}
            </th>
          ))}
          <th className={styles.root__sales__table_container__th_end}>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.root__sales__table_container__td_nav}></td>

          {Object.keys(sortedMonths).map((key, index) => {
            const status = sortedMonths[key].status
            let iconComponent = null

            switch (status) {
              case 'SELL':
                iconComponent = (
                  <Image
                    className={styles.root__sales__table_container__td__icon}
                    src={IconCheck}
                    alt={'Icono Check'}
                    priority
                  />
                )
                break
              case 'NOTSELL':
                iconComponent = (
                  <Image
                    className={styles.root__sales__table_container__td__icon}
                    src={IconNotCompleted}
                    alt={'Icono Negativo'}
                    priority
                  />
                )
                break
              case 'CURRENTLY':
                iconComponent = (
                  <FeatherIcon size="20" icon={'clock'} color="#1D1C1D" />
                )
                break
              default:
                break
            }

            return (
              <td
                className={styles.root__sales__table_container__td}
                key={index}>
                {iconComponent}
              </td>
            )
          })}
          <td className={styles.root__sales__table_container__td_end}></td>
        </tr>
        <tr>
          <td className={styles.root__sales__table_container__td_nav_value}>
            Ventas
          </td>

          {Object.keys(sortedMonths).map((key, index) => {
            return (
              <td
                className={styles.root__sales__table_container__td_value}
                key={index}>
                {sortedMonths[key].status !== 'NOTCURRENTLY' &&
                  convertToCOP(sortedMonths[key].ammount)}
              </td>
            )
          })}

          <td className={styles.root__sales__table_container__td_end_value}>
            {convertToCOP(infoTable.totalcollect)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default SalesTable
