import backgroundImage from '@/assets/img/dashboard/referals4x.png'
import { getFieldProp } from '@/functions'
import { useFormGroups, useKliikerReferalsFormFields } from '@/hooks'
import { IInputFormGroup, IKliikerReferalsFormFields } from '@/interfaces'
import { IMessageToast, InputGroup, MessageToasts } from '@atoms/index'
import FeatherIcon from 'feather-icons-react'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styles from './index.module.scss'

interface IKliikerReferalsFormProps {
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onSubmit?: SubmitHandler<IKliikerReferalsFormFields>
}

export default function KliikerReferalsForm({
  messages = [],
  onMessageDismiss = () => {},
  onSubmit = () => {}
}: IKliikerReferalsFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const formObject = useForm<IKliikerReferalsFormFields>({
    mode: 'all'
  })
  const fields = useKliikerReferalsFormFields()
  const [{ field, ...props }] = useFormGroups<
    IKliikerReferalsFormFields,
    IInputFormGroup<IKliikerReferalsFormFields>
  >(fields, ['email'])
  const fieldProp = getFieldProp<IKliikerReferalsFormFields>(formObject, field)

  const handleFormSubmit: SubmitHandler<IKliikerReferalsFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    try {
      await onSubmit(data)
      formObject.reset()
      setIsModalVisible(false)
    } catch (ex) {}
    setIsLoading(false)
  }

  return (
    <>
      <div
        role={'group'}
        aria-label={'Contenedor del módulo de referidos del Kliiker'}
        className={styles.root}>
        <figure
          aria-label={'Máscara de la imagen de fondo'}
          className={styles.root__mask}>
          <Image
            className={styles.root__image}
            src={backgroundImage}
            alt={''}
            priority
          />
        </figure>
        <hgroup className={styles.root__heading}>
          <h2 className={styles.root__title}>Haz más grande tu comunidad</h2>
          <p className={styles.root__description}>
            Registra a todos tus conocidos y empieza a aumentar tus comisiones.
          </p>
          <button
            className={styles.root__cta}
            type={'button'}
            title={'Abrir formulario de referidos'}
            aria-label={'Abrir formulario de referidos'}
            onClick={() => {
              setIsModalVisible(true)
            }}>
            Referir
          </button>
        </hgroup>
      </div>
      <div
        className={
          styles.root__wrapper +
          (isModalVisible ? ` ${styles['root__wrapper--open']}` : '')
        }>
        <dialog open className={styles.root__modal}>
          <button
            className={styles['root__close-modal']}
            aria-label={'Cerrar el modal de referidos'}
            title={'Cerrar el modal de referidos'}
            type="button"
            onClick={() => {
              setIsModalVisible(false)
            }}>
            <FeatherIcon icon="x" className={styles['root__close-icon']} />
          </button>
          <form
            ref={formRef}
            className={styles.root__form}
            aria-label={'Formulario de envío de referidos'}
            onSubmit={formObject.handleSubmit(handleFormSubmit)}>
            <hgroup className={styles['root__modal-heading']}>
              <FeatherIcon
                icon="users"
                className={styles['root__heading-icon']}
              />
              <h3 className={styles['root__modal-title']}>
                Haciendo más grande tu comunidad
              </h3>
            </hgroup>
            <div className={styles['root__modal-inputs']}>
              <p className={styles['root__inputs-description']}>
                Ingresa el correo electrónico de tu referido y hagamos crecer
                nuestra comunidad.
              </p>
              <InputGroup<IKliikerReferalsFormFields>
                field={fieldProp}
                {...props}
              />
            </div>
            <button
              className={styles['root__form-submit']}
              type={'submit'}
              aria-label={'Enviar referido'}
              title={'Enviar referido'}
              disabled={isLoading}>
              Referir
            </button>
            {isLoading && <div className={styles.root__loading}></div>}
            {messages.length !== 0 && (
              <div className={styles.root__messages}>
                <MessageToasts
                  dismissible
                  messages={messages}
                  onDismiss={onMessageDismiss}
                />
              </div>
            )}
          </form>
        </dialog>
      </div>
    </>
  )
}
