interface INavigationItem {
  title: string
  text: string
  style: 'link' | 'button'
}
interface INavigationButton extends INavigationItem {
  action: string
  type: 'button'
}
interface INavigationLink extends INavigationItem {
  href: string
  type: 'link'
}
type TNavigationItem = INavigationButton | INavigationLink
interface IVariableNavigationItem {
  noAuth: TNavigationItem
  auth?: TNavigationItem
}

export const navigationLinks: IVariableNavigationItem[] = [
  {
    noAuth: {
      type: 'link',
      style: 'link',
      href: '/ingreso',
      text: 'Iniciar Sesión',
      title: 'Enlace al formulario de inicio de sesión en Kliiker'
    },
    auth: {
      type: 'button',
      style: 'link',
      action: 'log-out',
      text: 'Cerrar Sesión',
      title: 'Cerrar la sesión del usuario'
    }
  },
  {
    noAuth: {
      type: 'link',
      style: 'button',
      href: '/registro',
      text: '¡Únete Aquí!',
      title: 'Enlace al formulario de registro de Kliiker'
    },
    auth: {
      type: 'link',
      style: 'button',
      href: '/portal',
      text: 'Ir a Portal',
      title: 'Volver a la la vista del usuario del portal'
    }
  }
]
