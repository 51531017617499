import { IGroups } from '@/interfaces'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import GroupsTBody from '@atoms/group-tbody'

interface IGroupsTableProps {
  groups?: IGroups[]
  onEditItem?: (group: IGroups) => void
  onDeleteItem?: (idGroup: number) => void
}

export default function GroupsTable({
  groups = [],
  onEditItem,
  onDeleteItem
}: IGroupsTableProps) {
  return (
    <table
      className={styles.root}
      role="table"
      aria-label={'Tabla de marcas vinculadas'}>
      <thead>
        <tr>
          <th>Fecha de creación</th>
          <th>Nombre de grupo</th>
          <th>Marcas asociadas</th>
          <th colSpan={2}></th>
        </tr>
      </thead>
      {groups.length === 0 ? (
        <tbody className={styles.root__empty}>
          <tr>
            <td colSpan={6}>
              <FeatherIcon icon="inbox" className={styles.root__icon} />
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <p className={styles.root__message}>
                Aún no hay marcas registradas
              </p>
            </td>
          </tr>
        </tbody>
      ) : (
        groups.map((group, index) => (
          <GroupsTBody
            key={index}
            group={group}
            onEdit={onEditItem}
            onDelete={onDeleteItem}
          />
        ))
      )}
    </table>
  )
}
