import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/state/store'
import { Document } from '@contentful/rich-text-types'

export interface IFisapayAgreementState {
  fulfillment: boolean
  termsAgreement: Document | null
}

const initialState: IFisapayAgreementState = {
  fulfillment: false,
  termsAgreement: null
}

export const fisapayAgreementSlice = createSlice({
  name: 'fisapayAgreement',
  initialState,
  reducers: {
    setFulFillment: (state, action: PayloadAction<boolean>) => {
      state.fulfillment = action.payload
    },
    setTermsAgreement: (state, action: PayloadAction<Document | null>) => {
      state.termsAgreement = action.payload
    }
  }
})

export const { setFulFillment, setTermsAgreement } =
  fisapayAgreementSlice.actions

export const selectFisapayAgreement = (state: RootState) =>
  state.fisapayAgreement

export default fisapayAgreementSlice.reducer
