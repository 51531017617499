import React from 'react'
import Image, { StaticImageData } from 'next/image'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'

interface ITooltipProps {
  text: string
  icon: string
}
interface ICardProps {
  background: StaticImageData
  alt?: string
  value: string
  label: string
  tooltip?: ITooltipProps
}
export default function Card({
  background,
  alt = 'Fondo de la card',
  value = '-',
  label = 'descripción',
  tooltip = { text: '', icon: '' }
}: ICardProps) {
  return (
    <div className={styles.root__card}>
      {tooltip.text !== '' && tooltip.icon !== '' && (
        <FeatherIcon icon={tooltip.icon} className={styles.root__icon} />
      )}

      <figure className={styles['root__background-wrapper']}>
        <Image
          src={background}
          alt={alt}
          className={styles.root__background}
          priority
        />
      </figure>

      <span className={styles.root__value}>{value}</span>
      <span className={styles.root__label}>{label}</span>
      <span className={styles['root__tooltip-message']}>
        <FeatherIcon
          icon={tooltip.icon}
          className={styles['root__tooltip-message__icon']}
        />{' '}
        {tooltip.text}
      </span>
    </div>
  )
}
