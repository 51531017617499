import { IPerformance } from '@/interfaces'

export const mockedPerformanceTable: IPerformance[] = [
  {
    cedula: '1234567890',
    nombre: 'Sergio Lenonardo',
    apellido: 'Guerrero Arango',
    ciudad: 'Bogotá',
    totalVenta: 450000,
    pedidos: 10,
    porcentajeActividad: 73.3,
    ticketPromedio: 177000
  },
  {
    cedula: '1234567890',
    nombre: 'Sergio',
    apellido: 'Guerrero',
    ciudad: 'Bogotá',
    totalVenta: 450000,
    pedidos: 10,
    porcentajeActividad: 73.3,
    ticketPromedio: 177000
  }
]
