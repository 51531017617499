import { IKliikerFilterDateFormFields, ISelectOptions } from '@/interfaces'
import { IMessageToast, SearchInput, SelectGroup } from '@atoms/index'
import { useEffect, useState } from 'react'
import { TableWrapper } from '..'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSession } from 'next-auth/react'
import {
  IAdminPerformanceQueryOptions,
  TAdminPerformanceFieldOrder,
  getAdminPerformanceReportService,
  getAdminPerformanceService
} from '@/services'
import { PerformanceTable, DateForm } from '@molecules/index'
import { IPerformance } from '@/interfaces/performance-kliikers/performance'

import styles from './index.module.scss'

export default function AdminPerformanceSection() {
  const tableSize = 20
  const [tableData, setTableData] = useState<IPerformance[]>([])
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const [pageActive, setPageActive] = useState(1)
  const [countRegister, setCountRegister] = useState(1)
  const [countPages, setCountPages] = useState(1)
  const [tableOptions, setTableOptions] =
    useState<IAdminPerformanceQueryOptions>({ size: tableSize })
  const selectsFormObject = useForm<{
    city: string
    orderBy: string
    orderDirection: string
  }>()
  const [cityOptions, setCityOptions] = useState<ISelectOptions[]>([])
  const [documentSearch, setDocumentSearch] = useState<string>('')
  const { data } = useSession()
  const idToken = data?.idToken

  const handlePageChange = (page: number) => {
    setPageActive(page)
  }

  const handleOnDissmiss = (index: number) => {
    setMessages((currentMesages) =>
      currentMesages.filter((_, curentIndex) => index !== curentIndex)
    )
  }

  const handleFilterFormSubmit: SubmitHandler<
    IKliikerFilterDateFormFields
  > = async (data) => {
    try {
      const newDates: { startDate?: Date; endDate?: Date } = {
        startDate: undefined,
        endDate: undefined
      }
      if (data.startDate !== undefined) {
        if (data.startDate === '') {
          sessionStorage.removeItem('startDateP')
          sessionStorage.removeItem('endDateP')
        } else {
          newDates.startDate = new Date(data.startDate)
          sessionStorage.setItem('startDateP', data.startDate)
        }
      }
      if (data.endDate !== undefined) {
        if (data.startDate === '') {
          sessionStorage.removeItem('startDateP')
          sessionStorage.removeItem('endDateP')
        } else {
          newDates.endDate = new Date(data.endDate)
          sessionStorage.setItem('endDateP', data.endDate)
        }
      }
      setPageActive(1)
      setTableOptions((oldOptions) => ({
        ...oldOptions,
        ...newDates
      }))
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  const handleSearchInput = (value: string) => {
    setDocumentSearch(value)
  }

  useEffect(() => {
    async function fetchTable() {
      if (idToken === undefined) return
      try {
        const newOptions = { ...tableOptions }
        const endDate = sessionStorage.getItem('endDateP') ?? ''
        const startDate = sessionStorage.getItem('startDateP') ?? ''
        if (startDate !== '') {
          newOptions.startDate = new Date(startDate)
        }
        if (endDate !== '') {
          newOptions.endDate = new Date(endDate)
        }

        const response = await getAdminPerformanceService({
          idToken,
          queryOptions: {
            ...newOptions,
            page: pageActive - 1,
            size: tableSize,
            documentNumber: documentSearch
          }
        })
        const fixedData: IPerformance[] =
          response.userPerformancePage.data.map<IPerformance>((value) => ({
            cedula: value.document,
            nombre: value.firstname,
            apellido: value.lastname,
            ciudad: value.city,
            totalVenta: value.totalSales,
            pedidos: value.ordersCount,
            porcentajeActividad: value.activityPercent,
            ticketPromedio: value.averageTicket
          }))
        setTableData(fixedData)
        setCountRegister(response.userPerformancePage.countRegister)
        setCountPages(response.userPerformancePage.countPage)
        setCityOptions(
          response.citiesNames.map<ISelectOptions>((value) => ({
            label: value,
            value
          }))
        )
      } catch (ex) {}
    }
    fetchTable()
  }, [idToken, tableOptions, pageActive, documentSearch])

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección del perfil del Kliiker'}
      className={styles.root}>
      <div className={styles['root__container-filter']}>
        <DateForm
          messages={messages}
          onMessageDismiss={handleOnDissmiss}
          onSubmit={handleFilterFormSubmit}
          isOnlyIcon
          typeIcon="search"
        />
        <div className={styles['root__select-groups']}>
          <div className={styles.root__group}>
            <SelectGroup
              label="Ciudad"
              field={{ id: 'city', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'Todas', value: 'all' },
                ...cityOptions
              ]}
              onChange={(value) => {
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  city: value === 'all' ? undefined : value
                }))
              }}
            />
          </div>
          <div className={styles.root__group}>
            <SelectGroup
              label="Ordenar por"
              field={{ id: 'orderBy', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'Total venta', value: 'total_sales' },
                { label: 'Pedidos', value: 'orders_count' },
                { label: '% Actividad', value: 'activity_percentage' },
                { label: 'Ticket prom.', value: 'average_ticket' }
              ]}
              onChange={(value) =>
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  fieldOrder: value as TAdminPerformanceFieldOrder
                }))
              }
            />
          </div>
          <div className={styles.root__group}>
            <SelectGroup
              label="Colocacion"
              field={{ id: 'orderDirection', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'Menor a mayor', value: 'asc' },
                { label: 'Mayor a menor', value: 'desc' }
              ]}
              onChange={(value) =>
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  ascending: value === 'asc'
                }))
              }
            />
          </div>
        </div>
        <SearchInput
          placeholder="Digite la cedula aquí"
          onChange={handleSearchInput}
          onClick={() => {
            setDocumentSearch('')
            setPageActive(1)
          }}
          value={documentSearch}
        />
      </div>
      <div className={styles.root__content}>
        <TableWrapper
          title="Listado de Kliikers"
          elements={[
            {
              label: 'DESCARGAR REPORTE',
              icono: 'download-cloud',
              handleClick: async () => {
                try {
                  if (idToken === undefined)
                    throw new Error('¡Algo salió mal!😰', {
                      cause: 'No-Id-Token'
                    })
                  const response = await getAdminPerformanceReportService({
                    idToken,
                    queryOptions: { ...tableOptions, page: pageActive - 1 }
                  })
                  window.open(response.url, '_blank')
                } catch (ex) {
                  setMessages((oldMessages) => [
                    ...oldMessages,
                    { type: 'error', message: (ex as Error).message }
                  ])
                }
              }
            }
          ]}
          handlePageChange={handlePageChange}
          resultsPerPage={tableSize}
          resultsNumber={countRegister}
          totalPages={countPages}>
          <PerformanceTable klikkers={tableData} />
        </TableWrapper>
      </div>
    </section>
  )
}
