import Image, { StaticImageData } from 'next/image'
import styles from './index.module.scss'
import comissionCard1 from '@/assets/img/dashboard/comission-card-1.png'
import comissionCard2 from '@/assets/img/dashboard/comission-card-2.png'

interface IComissionCard {
  background: StaticImageData
  value: string
  label: string
}

export interface IDashboardComissionCardsData {
  total: number
  current: number
}

interface IDashboardComissionCardsProps {
  comissions?: IDashboardComissionCardsData | null
}

export const formatDashboardComissionCardValue = (value: number): string =>
  new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0
  }).format(value)

export default function DashboardComissionCards({
  comissions = null
}: IDashboardComissionCardsProps) {
  const comissionCards: IComissionCard[] = [
    {
      background: comissionCard1,
      value:
        comissions !== null
          ? formatDashboardComissionCardValue(comissions.total)
          : '-',
      label: 'Comisiones totales generadas'
    },
    {
      background: comissionCard2,
      value:
        comissions !== null
          ? formatDashboardComissionCardValue(comissions.current)
          : '-',
      label: 'Comisiones del mes actual'
    }
  ]

  return (
    <div
      className={styles.root}
      role={'group'}
      aria-label={'Cards con resumenes de comisiones'}>
      {comissionCards.map((card, key) => (
        <div key={key} className={styles.root__card}>
          <figure className={styles['root__background-wrapper']}>
            <Image
              src={card.background}
              alt={'Fondo de card de comisión'}
              className={styles.root__background}
              priority
            />
          </figure>
          <span className={styles.root__value}>{card.value}</span>
          <span className={styles.root__label}>{card.label}</span>
        </div>
      ))}
    </div>
  )
}
