// import { SVGComponent } from '@atoms/index'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { formatMetricValue, metricsList } from './index.data'
import styles from './index.module.scss'
import Link from 'next/link'

interface IMetricsSectionProps {
  delay?: number
}

export default function MetricsSection({ delay = 500 }: IMetricsSectionProps) {
  const [isFirstView, setIsFirstView] = useState(false)
  const [metricsDynamicValues, setMetricsDynamicValues] = useState<number[]>(
    metricsList.map(() => 0)
  )
  const { ref: metricsBlockRef, inView: metricsInView } = useInView({
    threshold: 0,
    fallbackInView: true
  })

  useEffect(() => {
    if (!isFirstView && metricsInView) setIsFirstView(true)
  }, [isFirstView, metricsInView])

  useEffect(() => {
    if (!isFirstView) return
    const steps = 50
    const intervalDelay = delay / steps
    const handleFirstView = (intervalId: NodeJS.Timer) => {
      if (
        metricsDynamicValues.filter(
          (value, index) => value < metricsList[index].value
        ).length === 0
      ) {
        clearInterval(intervalId)
      } else {
        setMetricsDynamicValues((oldMetrics) =>
          oldMetrics.map((value, index) => {
            const newValue = value + metricsList[index].value / steps
            return newValue < metricsList[index].value
              ? newValue
              : metricsList[index].value
          })
        )
      }
    }
    const intervalId: NodeJS.Timer = setInterval(() => {
      handleFirstView(intervalId)
    }, intervalDelay)
    return () => {
      clearInterval(intervalId)
    }
  }, [isFirstView, metricsDynamicValues, delay])

  return (
    <section role={'contentinfo'} className={styles.root}>
      {/* <SVGComponent
        svgPath="metrics-section/decoration"
        className={styles.root__decoration}
      /> */}
      <hgroup className={styles.root__heading}>
        <h2 className={styles.root__title}>
          <strong>Nuestra comunidad</strong>
          <br /> hoy en día
        </h2>
        <p className={styles.root__description}>
          La comunidad Kliiker sigue creciendo, estas son nuestras cifras y
          vamos por más.
        </p>
      </hgroup>
      <ul className={styles['root__metrics-list']} ref={metricsBlockRef}>
        {metricsList.map((metric, key) => (
          <li key={key} className={styles.root__card}>
            <span className={styles['root__metric-value']}>
              {isFirstView
                ? formatMetricValue(
                    metricsDynamicValues[key],
                    metric.formatStyle
                  )
                : ''}
            </span>
            <span className={styles['root__metric-description']}>
              {metric.description}
            </span>
          </li>
        ))}
      </ul>

      <div className={styles['root__actions-wrapper']}>
        <Link
          href={'/registro'}
          title={'Enlace al formulario de registro Kliiker'}
          className={styles.root__cta}>
          ¡Únete aquí!
        </Link>
      </div>
    </section>
  )
}
