import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/state/store'
import { IBanner } from '@/interfaces/contentful/banner'

export interface IBannerState {
  banners: IBanner[]
}

const initialState: IBannerState = {
  banners: []
}

export const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    setBanners: (state, action: PayloadAction<IBanner[]>) => {
      state.banners = action.payload
    }
  }
})

export const { setBanners } = bannerSlice.actions

export const selectBanners = (state: RootState) => state.banners.banners

export default bannerSlice.reducer
