import {
  IKliikerAccountActivationFormFields,
  IKliikerPreRegisterFormFields,
  IServiceUser
} from '@/interfaces'
import { kliikerService } from '..'

export const kliikerPreRegisterFormService = async (
  data: IKliikerPreRegisterFormFields,
  bypass?: boolean
) =>
  await kliikerService<
    IKliikerPreRegisterFormFields,
    {
      email: string
      document: string
      firstname: string
      lastname: string
      phone: string
      password: string
      acceptedTerms: string
      captcha: string
    }
  >({
    type: 'bool',
    endpoint: '/registration/pre-signup',
    bypass,
    method: 'POST',
    withBody: {
      data,
      parser: (data) => ({
        email: data.email,
        document: data.docNumber,
        firstname: data.firstName,
        lastname: data.lastName,
        phone: data.phoneNumber,
        password: data.password,
        acceptedTerms: data.termsAgreement ? 'true' : 'false',
        captcha: data.gCaptcha
      })
    }
  })

export const kliikerAccountActivationFormService = async (
  data: IKliikerAccountActivationFormFields,
  bypass?: IServiceUser
) =>
  await kliikerService<
    IKliikerAccountActivationFormFields,
    {
      requestRegistrationId: string
      password: string
    },
    IServiceUser
  >({
    type: 'obj',
    endpoint: '/registration/first-password',
    bypass,
    method: 'POST',
    withBody: {
      data,
      parser: (data) => ({
        requestRegistrationId: data.requestId,
        password: data.password
      })
    }
  })
