import { ISelectFormGroupProps, ISelectOptions } from '@/interfaces'
import { FieldValues, Path, PathValue } from 'react-hook-form'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { useCallback, useEffect, useState } from 'react'
import {
  useCustomFieldRegistration,
  useFieldMessage,
  useIsRequiredField
} from '@/hooks'

export default function SelectGroup<T extends FieldValues>({
  field,
  label = '',
  activeLabel = '',
  dependencyValue,
  itemsFiller,
  onChange = () => {}
}: ISelectFormGroupProps<T>) {
  const [isFilled, setIsFilled] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [items, setItems] = useState<ISelectOptions[]>([])
  const [currentLabel, setCurrentLabel] = useState<string | undefined>()
  const isRequired = useIsRequiredField(field)
  const { isError, message } = useFieldMessage(field)
  const fieldRegister = useCustomFieldRegistration<T>(field)
  const fieldValue = field?.formObject.watch(field.id)
  const onChangeCallback = useCallback(onChange, [onChange])
  const setValueCallback = useCallback(
    (value: string) => {
      field?.formObject.setValue(field.id, value as PathValue<T, Path<T>>)
    },
    [field?.formObject, field?.id]
  )
  const currentValue = field?.formObject.watch(field.id)

  useEffect(() => {
    if (currentValue === undefined) return
    if (itemsFiller === undefined) return
    const getItems = async () => {
      const newItems = await itemsFiller(dependencyValue)
      setItems(newItems)
      const filteredItems = newItems.filter((item) => {
        return `${item.value}` === `${currentValue as string}`
      })
      const newLabel = filteredItems.length === 0 ? '' : filteredItems[0].label
      setCurrentLabel(newLabel)
    }
    getItems()
  }, [currentValue, itemsFiller, dependencyValue])

  useEffect(() => {
    setIsFilled(currentValue !== undefined && currentValue !== '')
  }, [currentValue])

  return (
    <div
      className={
        styles.root +
        (isFilled ? ` ${styles['root--filled']}` : '') +
        (isActive ? ` ${styles['root--active']}` : '') +
        (isError ? ` ${styles['root--error']}` : '')
      }
      role={'group'}
      aria-label="Campo de selección">
      <input type={'hidden'} {...fieldRegister} />
      <div
        className={styles.root__wrapper}
        onClick={() => setIsActive((current) => !current)}>
        <label className={styles.root__label}>
          {(isFilled || isActive) && activeLabel !== '' ? activeLabel : label}
          {isRequired ? ' *' : ''}
        </label>
        <div className={styles.root__input}>
          <span className={styles.root__value}>{currentLabel}</span>
          <button className={styles.root__toggle} type="button">
            <FeatherIcon icon="chevron-down" className={styles.root__icon} />
          </button>
        </div>
        {isActive && (
          <div className={styles.root__options}>
            <div className={styles['root__options-content']}>
              <ul className={styles['root__options-list']}>
                {items.map((item, itemIndex) => (
                  <li
                    key={itemIndex}
                    className={
                      styles.root__option +
                      (fieldValue === item.value
                        ? ` ${styles['root__option--selected']}`
                        : '')
                    }
                    onClick={() => {
                      onChangeCallback(item.value)
                      setValueCallback(item.value)
                    }}>
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {isError && <span className={styles.root__message}>{message}</span>}
    </div>
  )
}
