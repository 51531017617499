import {
  IKliikerRecoveryRequestFormFields,
  IKliikerResetPasswordFormFields,
  IMetricskliiker,
  IServiceCity,
  IServiceState,
  IServiceUser,
  TQueryParams
} from '@/interfaces'
import { signIn } from 'next-auth/react'
import { kliikerService } from '..'

export const kliikerRecoveryRequestFormService = async (
  data: IKliikerRecoveryRequestFormFields,
  bypass?: boolean
) =>
  await kliikerService<
    IKliikerRecoveryRequestFormFields,
    {
      email: string
      captcha: string
    }
  >({
    type: 'bool',
    endpoint: '/passwords/forget',
    bypass,
    method: 'POST',
    withBody: {
      data,
      parser: (data) => ({
        email: data.email,
        captcha: data.gCaptcha
      })
    }
  })

export const kliikerPasswordChangeRequestService = async (
  idToken: string,
  bypass?: boolean
) =>
  await kliikerService({
    type: 'bool',
    endpoint: '/passwords/change',
    bypass,
    method: 'POST',
    withAuthToken: idToken
  })

export const kliikerResetPasswordFormService = async (
  data: IKliikerResetPasswordFormFields,
  bypass?: boolean
) =>
  await kliikerService<
    IKliikerResetPasswordFormFields,
    {
      email: string
      code: string
      password: string
    }
  >({
    type: 'bool',
    endpoint: '/passwords/confirm',
    bypass,
    method: 'POST',
    withBody: {
      data,
      parser: (data) => ({
        email: data.email,
        code: data.verificationCode,
        password: data.password
      })
    }
  })

export const kliikerEditUserService = async (
  idToken: string,
  data: IServiceUser,
  bypass?: boolean
) => {
  const editResponse = await kliikerService<IServiceUser, IServiceUser>({
    type: 'bool',
    endpoint: '/user',
    bypass,
    method: 'PUT',
    withAuthToken: idToken,
    withBody: { data }
  })
  if (editResponse) {
    const freshResponse = await signIn('kliiker-services-with-token', {
      redirect: false
    })
    if (
      freshResponse === undefined ||
      (freshResponse !== undefined && !freshResponse.ok)
    ) {
      throw new Error('¡Algo salió mal! 😰', { cause: 'Cant-Auth-With-Token' })
    }
    return true
  } else return false
}

export const kliikerStatesService = async (bypass?: IServiceState[]) =>
  await kliikerService<{}, {}, IServiceState[]>({
    type: 'obj',
    endpoint: '/provinces',
    bypass,
    method: 'GET'
  })

export const kliikerCitiesService = async (
  stateId?: string,
  bypass?: IServiceCity[]
) => {
  let withQueryParams: TQueryParams | undefined
  if (stateId !== undefined && stateId !== '')
    withQueryParams = [['provinceId', stateId]]

  return await kliikerService<{}, {}, IServiceCity[]>({
    type: 'obj',
    endpoint: '/cities',
    bypass,
    method: 'GET',
    withQueryParams
  })
}

export const kliikerCityService = async (
  cityId: string,
  bypass?: IServiceCity
) =>
  await kliikerService<{}, {}, IServiceCity>({
    type: 'obj',
    endpoint: '/city',
    bypass,
    method: 'GET',
    withQueryParams: [['cityId', cityId]]
  })

export const kliikerProfilePictureService = async (
  idToken: string,
  picture: string,
  bypass?: IServiceUser
) =>
  await kliikerService<{}, { image: string }, IServiceUser>({
    type: 'obj',
    endpoint: '/user/photo-profile',
    bypass,
    method: 'PUT',
    withAuthToken: idToken,
    withBody: { data: {}, parser: () => ({ image: picture }) }
  })

export const kliikerMetricsService = async (params: {
  idToken: string
  bypass?: IMetricskliiker
}) => {
  const { idToken: withAuthToken } = params
  return await kliikerService<{}, {}, IMetricskliiker>({
    type: 'obj',
    endpoint: '/user-metric',
    method: 'GET',
    withAuthToken
  })
}
