import { IContentfulContent } from '@/interfaces'
import { SVGComponent } from '@atoms/index'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styles from './index.module.scss'

interface IStaticPageContentProps {
  title?: string
  content?: IContentfulContent
  isLoading?: boolean
}

export const StaticPageContent = ({
  title,
  content,
  isLoading = false
}: IStaticPageContentProps) => {
  return (
    <section
      className={styles.root}
      role={'contentinfo'}
      aria-label="Contenedor de página de contenido estático">
      <div className={styles.root__decoration}>
        <SVGComponent
          svgPath="metrics-section/decoration"
          className={styles.root__isotype}
        />
      </div>
      <article className={styles.root__content}>
        {isLoading ? (
          <div className={styles.root__loading}></div>
        ) : (
          <>
            <h1 className={styles.root__title}>{title}</h1>
            <div className={styles.root__info}>
              {content?.json !== undefined
                ? documentToReactComponents(content.json)
                : ''}
            </div>
          </>
        )}
      </article>
    </section>
  )
}

export default StaticPageContent
