import Image, { StaticImageData } from 'next/image'
import { SVGComponent } from '@atoms/index'
import placeHolderImage from '@/assets/img/placeholder.webp'
import styles from './index.module.scss'

interface IPresentationBubbleProps {
  caption?: string
  status?: string
  src?: string | StaticImageData
  direction?: string
}

export default function PresentationBubble({
  caption,
  status,
  src,
  direction
}: IPresentationBubbleProps) {
  const buildFigureClassName = () => {
    let clasName = styles.root
    const statusClassName = status ?? 'initial'
    clasName += statusClassName === 'iddle' ? ` ${styles['root--iddle']}` : ''
    clasName +=
      statusClassName === 'focused' ? ` ${styles['root--focused']}` : ''
    return clasName
  }
  const captionDirection = direction ?? 'left'

  return (
    <figure
      className={buildFigureClassName()}
      data-direction={captionDirection}>
      <figcaption className={styles.root__caption}>{caption ?? ''}</figcaption>
      <SVGComponent
        svgPath="home-hero/bounce"
        role={'presentation'}
        className={styles['root__arrow-icon']}
      />
      <Image
        className={styles.root__image}
        src={src ?? placeHolderImage}
        alt={caption ?? ''}
        width={284}
        priority
      />
    </figure>
  )
}
