import { useSession } from 'next-auth/react'
import styles from './index.module.scss'

interface IWelcomeMessageProps {
  name?: string
  title?: string
}

export default function WelcomeMessage({
  name = '',
  title = 'Bienvenido a tu comunidad de Kliiker'
}: IWelcomeMessageProps) {
  const session = useSession()
  const userRole = session.data?.user.roleId
  const nameMessage =
    userRole === 1 ? 'Bienvenido,' : `Hola${name !== '' ? ` ${name}` : ''}`
  return (
    <hgroup className={styles.root}>
      <span className={styles.root__prepend}>{nameMessage}</span>
      <h1 className={styles.root__title}>{title}</h1>
    </hgroup>
  )
}
