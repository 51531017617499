import { useEffect, useRef, useState } from 'react'
import { FieldValues } from 'react-hook-form'

import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { IInputFormGroupProps } from '@/interfaces'
import {
  useCustomFieldRegistration,
  useFieldMessage,
  useIsDisabledField,
  useIsRequiredField
} from '@/hooks'

export default function InputGroup<T extends FieldValues>({
  field,
  className = '',
  type = 'text',
  label = '',
  prepend = '',
  info = '',
  readOnly = false,
  noBorder = false,
  placeholder = ''
}: IInputFormGroupProps<T>) {
  const [isActive, setIsActive] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [passwordFieldType, setPasswordFieldType] = useState('password')
  const groupRef = useRef<HTMLDivElement>(null)
  const { isError, message } = useFieldMessage<T>(field, info)
  const fieldRegistration = useCustomFieldRegistration<T>(field)
  const fieldValue = field?.formObject.watch(field.id) ?? ''
  const isDisabled = useIsDisabledField<T>(field)
  const isRequired = useIsRequiredField<T>(field)

  useEffect(() => {
    const input = groupRef.current?.querySelector<HTMLInputElement>('input')
    if (input === null || input === undefined) return
    setIsFilled(input.value !== '')
  }, [])

  return (
    <div
      ref={groupRef}
      role={'group'}
      className={
        styles.root +
        (isError ? ` ${styles['root--error']}` : '') +
        (isActive ? ` ${styles['root--active']}` : '') +
        (isFilled || fieldValue !== '' || prepend !== '' || placeholder !== ''
          ? ` ${styles['root--filled']}`
          : '') +
        (noBorder ? ` ${styles['root--no-border']}` : '') +
        (isDisabled ? ` ${styles['root--disabled']}` : '') +
        (readOnly ? ` ${styles['root--readonly']}` : '') +
        (className !== '' ? ` ${className}` : '')
      }>
      <div className={styles['root__field-wrapper']}>
        <label className={styles.root__label} htmlFor={field?.id}>
          {`${label}${isRequired && label !== '' ? ' *' : ''}`}
        </label>
        <div className={styles.root__wrapper}>
          {prepend !== '' && (
            <span className={styles.root__prepend}>{prepend}</span>
          )}
          <input
            className={styles.root__field}
            id={field?.id}
            type={type === 'password' ? passwordFieldType : type}
            onFocusCapture={() => {
              setIsActive(true)
            }}
            onBlurCapture={() => {
              setIsActive(false)
            }}
            onChangeCapture={(event) => {
              const value = (event.target as HTMLInputElement).value
              setIsFilled(value !== '')
            }}
            {...fieldRegistration}
            disabled={isDisabled}
            readOnly={readOnly}
            placeholder={placeholder}
          />
          {type === 'password' && (
            <button
              className={styles['root__display-button']}
              type={'button'}
              title={'Mostrar u ocultar la contraseña'}
              onClick={() => {
                setPasswordFieldType((currentType) =>
                  currentType === 'password' ? 'text' : 'password'
                )
              }}>
              <FeatherIcon
                icon={passwordFieldType === 'password' ? 'eye-off' : 'eye'}
                className={styles['root__button-icon']}
              />
            </button>
          )}
        </div>
      </div>
      {message !== '' && !readOnly && (
        <span className={styles.root__message}>{message}</span>
      )}
    </div>
  )
}
