import { signIn, signOut, useSession } from 'next-auth/react'
import { useCallback, useEffect, useState } from 'react'

export function useAppSessionWatchdog() {
  const session = useSession()
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)

  const checkSession = useCallback(async () => {
    try {
      const refresh = await signIn('kliiker-services-with-token', {
        redirect: false
      })
      if (refresh === undefined || (refresh !== undefined && !refresh.ok))
        throw new Error('¡Algo salió mal 😰!', {
          cause: 'Cant-Refresh-Tokens'
        })
    } catch (ex) {
      setIsSessionExpired(true)
    }
  }, [])

  useEffect(() => {
    if (session.status === 'loading') return
    async function attemptClose() {
      try {
        if (session.data === null || isSessionExpired) {
          await signOut({ callbackUrl: '/ingreso' })
        }
      } catch (ex) {}
    }
    attemptClose()
  }, [session, isSessionExpired])

  useEffect(() => {
    if (!isFirstRender || session.status === 'loading') return
    if (session.data === null || isSessionExpired) return
    setIsFirstRender(false)
    checkSession()
  }, [checkSession, isFirstRender, session, isSessionExpired])

  useEffect(() => {
    if (isFirstRender || session.status === 'loading') return
    if (session.data === null || isSessionExpired) return
    const interval = setInterval(() => {
      checkSession()
    }, 10 * 60 * 1000)
    return () => clearInterval(interval)
  }, [checkSession, isFirstRender, session, isSessionExpired])

  return session
}
