import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IBrand } from '@/interfaces'
import { RootState } from '@/state/store'

export interface IBrandsCarouselState {
  brands: IBrand[]
}

const initialState: IBrandsCarouselState = {
  brands: []
}

export const brandsCarouselSlice = createSlice({
  name: 'brandsCarousel',
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<IBrand[]>) => {
      state.brands = action.payload
    }
  }
})

export const { setBrands } = brandsCarouselSlice.actions

export const selectBrands = (state: RootState) => state.brandsCarousel.brands

export default brandsCarouselSlice.reducer
