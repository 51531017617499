import { IGroups } from '@/interfaces'

export const mockedGroupsTable: IGroups[] = [
  {
    brandsGroup: {
      brandId: 1,
      name: 'Group 1',
      isGroup: true,
      createdAt: '2020-10-10',
      updatedAt: '2020-10-10'
    },
    childrenBrands: [
      {
        brandId: 1,
        name: 'Brand 1'
      },
      {
        brandId: 2,
        name: 'Brand 2'
      }
    ],
    childrenBrandNames: 'Brand 1, Brand 2'
  }
]
