import { SubmitHandler, useForm } from 'react-hook-form'
import styles from './index.module.scss'
import { useEffect, useRef, useState } from 'react'
import { IKliikerAccountActivationFormFields } from '@/interfaces'
import MessageToasts, { IMessageToast } from '@atoms/message-toasts'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface IActivationFormProps {
  requestId?: string
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onSubmit?: SubmitHandler<IKliikerAccountActivationFormFields>
}

export default function ActivationForm({
  requestId = '',
  messages = [],
  onMessageDismiss = () => {},
  onSubmit = () => {}
}: IActivationFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(true)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const formRef = useRef<HTMLFormElement>(null)
  const formObject = useForm<IKliikerAccountActivationFormFields>({
    mode: 'all',
    defaultValues: {
      requestId,
      password: 'Kliiker123',
      passwordConfirmation: 'Kliiker123'
    }
  })
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (formRef.current != null) {
      setTimeout(() => {
        if (submitButtonRef.current != null) {
          submitButtonRef.current.click()
          setVisible(false)
        }
      }, 1000)
    }
  }, [])

  const handleFormSubmit: SubmitHandler<
    IKliikerAccountActivationFormFields
  > = async (data, event) => {
    setIsLoading(true)
    event?.preventDefault()
    if (executeRecaptcha !== undefined)
      data.gCaptcha = await executeRecaptcha(
        'kliikerAccountActivationFormSubmit'
      )
    await onSubmit(data)
    setIsLoading(false)
  }

  return (
    <form
      ref={formRef}
      className={styles.root}
      role="form"
      onSubmit={formObject.handleSubmit(handleFormSubmit)}>
      <hgroup className={styles.root__heading}>
        <h1 className={styles.root__title}>
          Estamos validando <strong>tu correo electrónico</strong>
        </h1>
        <p className={styles.root__description}>
          Por favor, espera un momento... ¡Gracias!
        </p>
      </hgroup>
      <div
        role={'group'}
        aria-label={'Contenedor de los campos del formulario de activación'}
        className={styles['root__content-wrapper']}>
        {/* <PasswordFields<IKliikerAccountActivationFormFields>
          formObject={formObject}
          formElement={formRef.current}
        /> */}
        <input type={'hidden'} {...formObject.register('requestId')} />
        <input type={'hidden'} {...formObject.register('password')} />
        <input
          type={'hidden'}
          {...formObject.register('passwordConfirmation')}
        />
        {visible && (
          <button
            className={styles.root__submit}
            type={'submit'}
            disabled={isLoading}
            ref={submitButtonRef}>
            Iniciar
          </button>
        )}
        {isLoading && <div className={styles.root__loading}></div>}
        {messages.length !== 0 && (
          <div className={styles.root__messages}>
            <MessageToasts
              dismissible
              messages={messages}
              onDismiss={onMessageDismiss}
            />
          </div>
        )}
      </div>
    </form>
  )
}
