import { useEffect, useState } from 'react'
import Image from 'next/image'
import { SVGComponent } from '@atoms/index'
import videoPlaceholder from '@/assets/img/video-section/placeholder.png'
import styles from './index.module.scss'

export default function VideoSection() {
  const [isPlaying, setIsPlaying] = useState(false)
  const [domAvailable, setDomAvailable] = useState(false)

  useEffect(() => {
    setDomAvailable(true)
  }, [])

  const videoId = 'B8P88vOzy4Y'

  return (
    <section className={styles.root} role={'contentinfo'} id={'video-section'}>
      {domAvailable && (
        <div className={styles['root__video-wrapper']}>
          {isPlaying ? (
            <iframe
              className={styles['root__embed-video']}
              width="560"
              height="315"
              src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1&modestbranding=1&rel=0`}
              title="¿Cómo funciona Kliiker?"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          ) : (
            <div
              role={'group'}
              className={styles['root__video-placeholder']}
              onClick={() => setIsPlaying(true)}>
              <Image
                className={styles['root__placeholder-image']}
                src={videoPlaceholder}
                alt={'Imagen del video informativo sobre Kliiker'}
                width={739.62}
                priority
              />
              <SVGComponent
                svgPath={'video-section/play-button'}
                className={styles['root__placeholder-icon']}
                role={'img'}
                arial-label={'Botón para reproducir video'}
              />
            </div>
          )}
        </div>
      )}
    </section>
  )
}
