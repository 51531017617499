import { ITestimonial } from '@/interfaces'
import { TestimonialCard } from '@molecules/index'
import { Grid, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import styles from './index.module.scss'
import Link from 'next/link'

interface ITestimonialsSectionProps {
  testimonials?: ITestimonial[]
}

export default function TestimonialsSection({
  testimonials = []
}: ITestimonialsSectionProps) {
  return (
    <section className={styles.root} role={'contentinfo'}>
      <hgroup className={styles.root__heading}>
        <h2 className={styles.root__title}>
          <strong>Mira lo que dicen</strong> nuestros Kliikers
        </h2>
        <p className={styles.root__description}>
          Conoce algunas de las historias de nuestros Kliikers que ya empezaron
          a ganar.
        </p>
      </hgroup>
      <Swiper
        role={'list'}
        className={styles['root__testimonials-list']}
        modules={[Grid, Pagination]}
        centerInsufficientSlides
        slidesPerView={1}
        pagination={{ clickable: true }}
        breakpoints={{
          '1024': { slidesPerView: 2, grid: { rows: 2, fill: 'row' } }
        }}
        onResize={(swiper) => swiper.update()}>
        {testimonials.map((testimonial, key) => (
          <SwiperSlide
            key={key}
            role={'listitem'}
            className={styles['root__list-item']}>
            <TestimonialCard
              author={{
                name: testimonial.name,
                city: testimonial.city,
                pictureUrl: testimonial.picture?.url
              }}>
              {testimonial.testimonial}
            </TestimonialCard>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles['root__actions-wrapper']}>
        <Link
          href={'/registro'}
          title={'Enlace al formulario de registro Kliiker'}
          className={styles.root__cta}>
          ¡Únete aquí!
        </Link>
      </div>
    </section>
  )
}
