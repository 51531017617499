import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import { selectBrands } from './index.slice'
import { BrandContainer } from '@atoms/index'
import { useAppSelector } from '@/state/hooks'
import styles from './index.module.scss'

export default function BrandsCarousel() {
  const brands = useAppSelector(selectBrands)

  return (
    <Swiper
      className={styles.root}
      role={'group'}
      modules={[Autoplay]}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }}
      slidesPerView={2}
      breakpoints={{ '1024': { slidesPerView: 6 } }}
      onResize={(swiper) => swiper.update()}
      centerInsufficientSlides>
      {brands.map((brand, index) => (
        <SwiperSlide key={index} className={styles.root__slide}>
          <BrandContainer brand={brand} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
