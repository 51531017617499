import Image, { StaticImageData } from 'next/image'
import styles from './index.module.scss'

interface ICallToActionBase {
  text: string
}

interface ICallToActionButton extends ICallToActionBase {
  type: 'button'
  callback: () => void
}

interface ICallToActionLink extends ICallToActionBase {
  type: 'link'
  href: string
}

type TCallToAction = ICallToActionButton | ICallToActionLink

interface IIndicator {
  current: number
  total: number
}

export interface IOnboardingStepProps {
  image?: StaticImageData | null
  Title?: JSX.Element | null
  description?: string
  callToAction?: TCallToAction | null
  indicator?: IIndicator | null
  uniqueAlias?: string | null
}

export default function OnboardingStep({
  image = null,
  Title = null,
  description = '',
  callToAction = null,
  indicator = null,
  uniqueAlias = ''
}: IOnboardingStepProps) {
  const indicators: number[] = []
  if (indicator !== null) {
    for (let i = 0; i < indicator.total; i++) indicators.push(i)
  }

  return (
    <div role={'group'} className={styles.root}>
      {image !== null && (
        <figure className={styles['root__image-wrapper']}>
          <Image
            src={image}
            alt={'Imagen decorativa'}
            className={styles.root__image}
            priority
          />
        </figure>
      )}
      <div className={styles['root__content-wrapper']}>
        {uniqueAlias !== '' && (
          <div className={styles['root__kliiker-id-wrapper']}>
            <span
              className={
                styles['root__kliiker-id']
              }>{`tienda.kliiker.com/?kliikerId=${uniqueAlias ?? ''}`}</span>
          </div>
        )}
        {Title !== null && <h1 className={styles.root__title}>{Title}</h1>}

        {description !== '' && (
          <p className={styles.root__description}>{description}</p>
        )}
        <div className={styles['root__actions-wrapper']}>
          {callToAction !== null &&
            (callToAction.type === 'button' ? (
              <button
                aria-label={'Botón para ejecutar la acción'}
                className={styles.root__cta}
                type={'button'}
                onClick={() => {
                  callToAction.callback()
                }}>
                {callToAction.text}
              </button>
            ) : (
              <a
                href={callToAction.href}
                className={styles.root__cta}
                aria-label="Enlace para continuar">
                {callToAction.text}
              </a>
            ))}
          {indicator !== null && (
            <ul className={styles['root__step-indicator']}>
              {indicators.map((value, key) => (
                <li
                  key={key}
                  className={
                    styles['root__indicator-item'] +
                    (value === indicator.current
                      ? ` ${styles['root__indicator-item--active']}`
                      : '')
                  }></li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}
