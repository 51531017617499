import { IInputFormGroup, IKliikerFilterDateFormFields } from '@/interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import InputGroup from '@atoms/input-group'
import { useFormGroups, useKliikerFilterFormFields } from '@/hooks'
import { getFieldProp } from '@/functions'
import { useEffect, useState } from 'react'
import MessageToasts, { IMessageToast } from '@atoms/message-toasts'

interface IDateFormProps {
  isOnlyIcon?: boolean
  messages?: IMessageToast[]
  onMessageDismiss?: (index: number) => void
  onSubmit?: SubmitHandler<IKliikerFilterDateFormFields>
  typeIcon?: 'search' | 'filter'
  typeSection?: 'performance' | 'autorization-comission'
}

export default function DateForm({
  isOnlyIcon = false,
  onSubmit,
  messages = [],
  onMessageDismiss,
  typeIcon = 'filter',
  typeSection = 'performance'
}: IDateFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const formObject = useForm<IKliikerFilterDateFormFields>({ mode: 'all' })
  const fields = useKliikerFilterFormFields()
  const groups = useFormGroups<
    IKliikerFilterDateFormFields,
    IInputFormGroup<IKliikerFilterDateFormFields>
  >(fields, ['startDate', 'endDate'])

  const handleFormSubmit: SubmitHandler<IKliikerFilterDateFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    await onSubmit?.(data)
    setIsLoading(false)
  }
  useEffect(() => {
    if (typeSection !== 'performance') return
    if (typeSection === 'performance') {
      const startDate = sessionStorage.getItem('startDateP') ?? ''
      const endDate = sessionStorage.getItem('endDateP') ?? ''
      formObject.setValue('startDate', startDate)
      formObject.setValue('endDate', endDate)
    }
  }, [formObject, typeSection])
  return (
    <form
      className={styles.root}
      onSubmit={formObject.handleSubmit(handleFormSubmit)}>
      <div className={styles.root__groups}>
        {groups.map(({ field, ...group }, key) => {
          const fieldProp = getFieldProp<IKliikerFilterDateFormFields>(
            formObject,
            field
          )
          return (
            <InputGroup<IKliikerFilterDateFormFields>
              key={key}
              field={fieldProp}
              className={styles.root__group}
              {...group}
            />
          )
        })}
      </div>
      <button
        data-only-icon={isOnlyIcon}
        type={'submit'}
        className={styles['root__submit-button']}
        disabled={isLoading}
        aria-label="Botón para filtrar">
        {isOnlyIcon ? (
          <FeatherIcon
            icon={typeIcon === 'search' ? 'search' : 'filter'}
            className={styles['root__header-table__button-icon']}
          />
        ) : (
          'Filtrar'
        )}
      </button>
      {isLoading && <div className={styles.root__loading}></div>}
      {messages.length !== 0 && (
        <div className={styles.root__messages}>
          <MessageToasts
            dismissible
            messages={messages}
            onDismiss={onMessageDismiss}
          />
        </div>
      )}
    </form>
  )
}
