import FeatherIcon from 'feather-icons-react'
import { IGroups } from '@/interfaces'

import styles from './index.module.scss'
import { normalizeDate } from '@/functions'

interface IGroupsTBodyProps {
  group?: IGroups
  onEdit?: (group: IGroups) => void
  onDelete?: (idGroup: number) => void
}

export default function GroupsTBody({
  group = {
    brandsGroup: {
      brandId: 0,
      name: '',
      isGroup: true,
      createdAt: '',
      updatedAt: ''
    },
    childrenBrands: [],
    childrenBrandNames: ''
  },
  onEdit,
  onDelete
}: IGroupsTBodyProps): JSX.Element {
  return (
    <tbody
      className={styles.root}
      aria-label={'Detalles de la Marca'}
      role={'group'}>
      <tr className={styles.root__data}>
        <td>{normalizeDate(group.brandsGroup.createdAt)}</td>
        <td>{group.brandsGroup.name}</td>
        <td>{group.childrenBrandNames}</td>
        <td>
          <FeatherIcon
            icon="edit-2"
            className={styles.root__icon}
            onClick={() => onEdit?.(group)}
          />
        </td>
        <td>
          <FeatherIcon
            icon="trash-2"
            className={styles.root__icon}
            onClick={() => onDelete?.(group?.brandsGroup.brandId)}
          />
        </td>
      </tr>
    </tbody>
  )
}
