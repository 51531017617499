import Image from 'next/image'
import { IBrand } from '@/interfaces'
import styles from './index.module.scss'

interface IBrandContainerProps {
  brand: IBrand
}

export default function BrandContainer({ brand }: IBrandContainerProps) {
  const { logo, commissionRate, isVariable } = brand

  return (
    <figure className={styles.root}>
      <div className={styles['root__img-wrapper']}>
        {logo?.url !== undefined ? (
          <Image
            src={logo.url}
            alt={logo.title ?? ''}
            width={logo.width}
            height={logo.height}
          />
        ) : (
          <></>
        )}
      </div>
      <figcaption className={styles.root__caption}>
        {commissionRate !== undefined
          ? `¡Gana${isVariable ?? false ? ' hasta' : ''} ${commissionRate}%!`
          : ''}
      </figcaption>
    </figure>
  )
}
