import { IPerformance } from '@/interfaces/performance-kliikers/performance'
import PerformanceTBody from '@atoms/performance-tbody'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'

interface IPerformanceTableProps {
  klikkers?: IPerformance[]
}

export default function PerformanceTable({
  klikkers = []
}: IPerformanceTableProps) {
  return (
    <table
      className={styles.root}
      aria-label={'Tabla de comisiones del Kliiker'}>
      <thead>
        <tr>
          <th>Cedula</th>
          <th>Nombre</th>
          <th>Apellidos</th>
          <th>Ciudad</th>
          <th>Total venta</th>
          <th>Pedidos</th>
          <th>% Actividad</th>
          <th colSpan={2}>Ticket prom.</th>
        </tr>
      </thead>
      {klikkers.length === 0 ? (
        <tbody className={styles.root__empty}>
          <tr>
            <td colSpan={10}>
              <FeatherIcon icon="inbox" className={styles.root__icon} />
            </td>
          </tr>
          <tr>
            <td colSpan={10}>
              <p className={styles.root__message}>
                Aún no hay Kliikers registrados
              </p>
            </td>
          </tr>
        </tbody>
      ) : (
        klikkers.map((klikker, index) => (
          <PerformanceTBody key={index} kliiker={klikker} />
        ))
      )}
    </table>
  )
}
