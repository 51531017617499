export type TButtonAction = 'logout'

interface IMenuItem {
  icon: string
  label: string
}

interface IMenuLink extends IMenuItem {
  type: 'link'
  href: string
}

interface IMenuButton extends IMenuItem {
  type: 'button'
  action: TButtonAction
}

export const kliikerMenuItems: Array<IMenuLink | IMenuButton> = [
  {
    type: 'link',
    icon: 'activity',
    label: 'Panel',
    href: '/portal'
  },
  {
    type: 'link',
    icon: 'dollar-sign',
    label: 'Comisiones',
    href: '/portal/comisiones'
  },
  {
    type: 'link',
    icon: 'book-open',
    label: 'Academia Kliiker',
    href: '/portal/academy'
  },
  {
    type: 'link',
    icon: 'bookmark',
    label: 'Club de beneficios Kliiker',
    href: '/portal/benefits-club'
  },
  {
    type: 'button',
    icon: 'log-out',
    label: 'CERRAR SESIÓN',
    action: 'logout'
  }
]

export const adminMenuItems: Array<IMenuLink | IMenuButton> = [
  {
    type: 'link',
    icon: 'trending-up',
    label: 'Rendimiento de Kliikers',
    href: '/admin'
  },
  {
    type: 'link',
    icon: 'check-square',
    label: 'Autorización de comisiones',
    href: '/admin/autorizacion'
  },
  {
    type: 'link',
    icon: 'link',
    label: 'Vinculación de marcas',
    href: '/admin/marcas'
  },
  {
    type: 'link',
    icon: 'list',
    label: 'Reglas de comisiones',
    href: '/admin/reglas'
  },
  {
    type: 'button',
    icon: 'log-out',
    label: 'CERRAR SESIÓN',
    action: 'logout'
  }
]
