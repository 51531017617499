import Image from 'next/image'
import backgroundImage from '@/assets/img/close-section/background.png'
import styles from './index.module.scss'
import Link from 'next/link'

export default function CloseSection() {
  return (
    <section role={'contentinfo'} className={styles.root}>
      <Image
        className={styles.root__background}
        alt=""
        src={backgroundImage}
        priority
      />
      <div role={'group'} className={styles.root__wrapper}>
        <p className={styles['root__close-text']}>
          Haz parte de la mejor comunidad de venta online.
        </p>
        <Link
          href={'/registro'}
          className={styles.root__cta}
          title="Enlace a la página de registro de Kliiker">
          Unirme a la comunidad
        </Link>
      </div>
    </section>
  )
}
