import { IStaticLink, TStaticLinksNames } from '@/interfaces'

export function getStaticLinks() {
  const links = new Map<TStaticLinksNames, IStaticLink>()
  links.set('help', {
    title: 'Enlace a la página de ayuda',
    href: '/ayuda',
    text: 'Ayuda'
  })
  links.set('faqs', {
    title: 'Enlace a la página de Preguntas frecuentes',
    href: '/preguntas-frecuentes',
    text: 'Preguntas frecuentes'
  })
  links.set('terms-n-conditions', {
    title: 'Enlace a página de Términos y condiciones',
    href: '/terminos-y-condiciones',
    text: 'Términos y condiciones'
  })
  links.set('data-protection', {
    title: 'Enlace a página de Protección de datos',
    href: 'https://drive.google.com/file/d/19kznuWJCE02qHGQDIkgj09yzkViM_5Fp/view',
    target: '_blank',
    text: 'Protección de datos'
  })
  links.set('privacy-policy', {
    title: 'Enlace a página de Políticas de privacidad',
    href: '/politicas-de-privacidad',
    text: 'Políticas de privacidad'
  })
  links.set('phone', {
    title: 'Enlace para llamar a la línea Kliiker',
    href: 'tel:01 8000 182 303',
    text: '01 8000 182 303',
    featherIcon: 'phone'
  })
  links.set('mail', {
    title: 'Enlace para envíar correo a servicio al cliente de Kliiker',
    href: 'mailto:servicioalcliente@kliiker.com',
    text: 'Peticiones, Quejas y Reclamos',
    featherIcon: 'mail'
  })
  links.set('location', {
    title: 'Enlace para visualizar la ubicación de las oficionas de Kliiker',
    href: 'https://goo.gl/maps/1kYmt9jFdDDLHtz6A',
    text: 'Calle 14 # 52 A 372 Medellín, Colombia',
    target: '_blank',
    featherIcon: 'map-pin'
  })
  return links
}

export function getStaticPagesSlugs() {
  return Array.from(getStaticLinks().values())
    .filter((value) => value.href.startsWith('/'))
    .map((value) => value.href.substring(1))
}
