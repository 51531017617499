import { IKliikerReferalsFormFields } from '@/interfaces'
import { kliikerService } from '..'
import { IReward } from '@/interfaces/rewards/rewards'

interface IKliikerDashboardCommissionsCardServiceResponse {
  totalAmount: string
  totalAmountByThisMonth: string
}

export const kliikerDashboardComissionsCardsService = async (
  idToken: string,
  bypass?: IKliikerDashboardCommissionsCardServiceResponse
) =>
  await kliikerService<{}, {}, IKliikerDashboardCommissionsCardServiceResponse>(
    {
      endpoint: '/dashboard/totals',
      type: 'obj',
      bypass,
      method: 'GET',
      withAuthToken: idToken
    }
  )

interface IKliikerDashboardCommissionsGraphServiceResponse {
  [key: string]: string
}

export const kliikerDashboardCommissionsGraphService = async (
  idToken: string,
  bypass?: IKliikerDashboardCommissionsGraphServiceResponse
) =>
  await kliikerService<
    {},
    {},
    IKliikerDashboardCommissionsGraphServiceResponse
  >({
    endpoint: '/dashboard/commissions-graph',
    type: 'obj',
    bypass,
    method: 'GET',
    withAuthToken: idToken
  })

export const kliikerReferalsFormService = async (
  idToken: string,
  data: IKliikerReferalsFormFields,
  bypass?: boolean
) =>
  await kliikerService<IKliikerReferalsFormFields, { referred_email: string }>({
    type: 'bool',
    endpoint: '/dashboard/refer',
    bypass,
    method: 'POST',
    withAuthToken: idToken,
    withBody: {
      data,
      parser: (data) => ({ referred_email: data.email })
    }
  })

export const kliikerDashboardGoalRewardsService = async (
  idToken: string,
  qty: number,
  bypass?: IReward[]
) =>
  await kliikerService<{}, {}, IReward[]>({
    endpoint: '/dashboard/progress-rewards',
    type: 'obj',
    bypass,
    method: 'GET',
    withAuthToken: idToken,
    withQueryParams: [['quantity', qty.toString()]]
  })
