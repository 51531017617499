import { parseCommissionDetails } from '@/functions'
import { TCommission } from '@/interfaces'
import styles from './index.module.scss'

interface ICommissionDetailRowProps {
  commission?: TCommission
  visible?: boolean
}

type TTrType = 'message' | 'sell-header' | 'sell-item' | 'sell-footer'

export default function AutorizationCommissionDetail({
  commission,
  visible = false
}: ICommissionDetailRowProps) {
  const getTrProps = (type: TTrType) => ({
    className: styles.root,
    'data-status': visible ? 'visible' : 'hidden',
    'data-type': type
  })
  if (commission === undefined) return <></>
  const {
    commissionPeriod,
    commissionPercentage,
    commissionPercentageBrand,
    commissionPeriods,
    commissionValue,
    dateRange,
    minimumAmount,
    numberMonths,
    numberReferrals,
    numberOrder,
    prize,
    topSaleSupered
  } = parseCommissionDetails(commission)

  return (
    <tr {...getTrProps('message')}>
      <td colSpan={6}>
        {commissionPeriods !== undefined && commission.ruleTypeId === 8 && (
          <p>
            <strong>Meses previos sin actividad:</strong> {commissionPeriods}
          </p>
        )}
        {commissionPeriods !== undefined && commission.ruleTypeId === 6 && (
          <p>
            <strong>Meses consecutivos con actividad:</strong>{' '}
            {commissionPeriods}
          </p>
        )}
        {numberOrder !== undefined && (
          <p>
            <strong>Order ID:</strong> {numberOrder}
          </p>
        )}
        {numberMonths !== undefined && (
          <p>
            <strong>Numero de meses:</strong> {numberMonths}
          </p>
        )}
        {minimumAmount !== undefined && (
          <p>
            <strong>Monto mínimo de venta:</strong> {minimumAmount}
          </p>
        )}
        {commissionValue !== undefined &&
          commissionValue !== '' &&
          commissionValue !== '-' && (
            <p>
              <strong>Valor de comisión:</strong> {commissionValue}
            </p>
          )}
        {prize !== undefined && prize !== '' && (
          <p>
            <strong>Premio:</strong> {prize}
          </p>
        )}
        {dateRange !== undefined && (
          <p>
            <strong>Rango de fecha:</strong> {dateRange}
          </p>
        )}
        {topSaleSupered !== undefined && (
          <p>
            <strong>Tope superado:</strong> {topSaleSupered}
          </p>
        )}
        {commissionPercentage !== undefined && commissionPercentage !== '0%' && (
          <p>
            <strong>Porcentaje de comisión:</strong> {commissionPercentage}
          </p>
        )}
        {numberReferrals !== undefined && (
          <p>
            <strong>Numero de referidos:</strong> {numberReferrals}
          </p>
        )}
        {commissionPercentageBrand !== undefined && (
          <p>
            <strong>Porcentaje de comisión por marcas:</strong>{' '}
            {commissionPercentageBrand}
          </p>
        )}
        {commissionPeriod !== undefined && (
          <p>
            <strong>Periodo evaluado:</strong> {commissionPeriod}
          </p>
        )}
      </td>
    </tr>
  )
}
