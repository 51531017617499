import { useCustomFieldRegistration } from '@/hooks'
import { IPictureSelectorFormGroupProps } from '@/interfaces'
import FeatherIcon from 'feather-icons-react'
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import { FieldValues, Path, PathValue } from 'react-hook-form'
import styles from './index.module.scss'
import { normalizeSize } from '@/functions'

export default function FileSelector<T extends FieldValues>({
  field
}: IPictureSelectorFormGroupProps<T>) {
  const [file, setFile] = useState('')
  const [isFirstRender, setIsFirstRender] = useState(true)
  const fieldRegistration = useCustomFieldRegistration<T>(field)
  const fieldValue = field?.formObject.watch(field.id) ?? ''
  const [nameFile, setNameFile] = useState('')
  const [sizeFile, setSizeFile] = useState('')

  useEffect(() => {
    if (!isFirstRender) return
    setIsFirstRender(false)
    if (fieldValue === '') return
    setFile(fieldValue)
    field?.formObject.setValue(field.id, '' as PathValue<T, Path<T>>)
  }, [isFirstRender, fieldValue, field?.formObject, field?.id])

  const handleRemove = () => {
    setFile('')
    field?.formObject.setValue(field.id, '' as PathValue<T, Path<T>>)
  }

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      if (target.files === null || target.files.length === 0) return
      setNameFile(target.files[0].name)
      setSizeFile(normalizeSize(target.files[0].size))
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          setFile(reader.result)
          field?.formObject.setValue(
            field.id,
            reader.result as PathValue<T, Path<T>>
          )
        }
      })
      reader.readAsText(target.files[0])
    },
    [field?.formObject, field?.id]
  )

  return (
    <div
      role={'group'}
      aria-label={'Campo de ingreso de archivo'}
      className={styles.root}>
      {file !== '' ? (
        <div className={styles.root__wrapper}>
          <figure className={styles.root__mask}>
            <FeatherIcon icon="file-text" className={styles.root__picture} />
            <strong className={styles['root__size-file']}>{sizeFile}</strong>
          </figure>
          <div className={styles['root__details-logo']}>
            <p className={styles['root__name-file']}>{nameFile}</p>
          </div>
          <button
            className={styles.root__remove}
            title={'Eliminar el archivo'}
            aria-label={'Eliminar el archivo'}
            onClick={() => handleRemove()}>
            Eliminar
          </button>
        </div>
      ) : (
        <div className={styles.root__inputs}>
          <label htmlFor="file-selector" className={styles.root__label}>
            Carga un archivo .csv
            <input type={'hidden'} {...fieldRegistration} />
            <input
              id="file-selector"
              type="file"
              accept=".csv"
              onChange={handleChange}
            />
          </label>
          <label htmlFor="file-selector" className={styles.root__button}>
            Adjuntar archivo
          </label>
        </div>
      )}
    </div>
  )
}
