import {
  getFormGroups,
  getFormGroupsMap,
  getKliikerAccountActivationFormFields,
  getKliikerEditDataFormFields,
  getKliikerFilterFormFields,
  getKliikerFisapayFormFields,
  getKliikerLinkCreationFormFields,
  getKliikerLoginFormFields,
  getKliikerPasswordCreationFields,
  getKliikerPreRegisterFormFields,
  getKliikerRecoveryRequestFormFields,
  getKliikerReferalsFormFields,
  getKliikerResetPasswordFormFields,
  getKliikerVerificationCodeField,
  getPasswordConditions
} from '@/functions'
import {
  IKliikerPasswordCreationFields,
  IKliikerVerificationCodeField,
  TFormGroup,
  TFormGroupType
} from '@/interfaces'
import { useMemo } from 'react'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

export * from './fields'

export function useFormGroups<T extends FieldValues, U extends TFormGroup<T>>(
  fieldsMap: Map<keyof T, TFormGroup<T>>,
  fieldsArray: Array<keyof T>
) {
  return useMemo<U[]>(
    () => getFormGroups<T, U>(fieldsMap, fieldsArray),
    [fieldsMap, fieldsArray]
  )
}

export function useFormGroupsMap<
  T extends FieldValues,
  U extends TFormGroup<T>
>(
  type: TFormGroupType,
  groups: U[],
  formObject: UseFormReturn<T>,
  disabledFields: Array<Path<T>> = []
) {
  return useMemo(
    () => getFormGroupsMap<T, U>(type, groups, formObject, disabledFields),
    [groups, formObject, type, disabledFields]
  )
}

export function useKliikerPreRegisterFormFields() {
  return useMemo(() => getKliikerPreRegisterFormFields(), [])
}

export function usePasswordConditions() {
  return useMemo(() => getPasswordConditions(), [])
}

export function useKliikerPasswordCreationFields<
  T extends IKliikerPasswordCreationFields
>() {
  return useMemo(() => getKliikerPasswordCreationFields<T>(), [])
}

export function useKliikerAccountActivationFormFields() {
  return useMemo(() => getKliikerAccountActivationFormFields(), [])
}

export function useKliikerFisapayFormFields() {
  return useMemo(() => getKliikerFisapayFormFields(), [])
}

export function useKliikerLoginFormFields() {
  return useMemo(() => getKliikerLoginFormFields(), [])
}

export function useKliikerRecoveryRequestFormFields() {
  return useMemo(() => getKliikerRecoveryRequestFormFields(), [])
}

export function useKliikerVerificationCodeField<
  T extends IKliikerVerificationCodeField
>() {
  return useMemo(() => getKliikerVerificationCodeField<T>(), [])
}

export function useKliikerResetPasswordFormFields() {
  return useMemo(() => getKliikerResetPasswordFormFields(), [])
}

export function useKliikerReferalsFormFields() {
  return useMemo(() => getKliikerReferalsFormFields(), [])
}

export function useKliikerLinkCreationFormFields() {
  return useMemo(() => getKliikerLinkCreationFormFields(), [])
}

export function useKliikerEditDataFormFields() {
  return useMemo(() => getKliikerEditDataFormFields(), [])
}

export function useKliikerFilterFormFields() {
  return useMemo(() => getKliikerFilterFormFields(), [])
}
