import styles from './index.module.scss'
import Link from 'next/link'
import { useAppSelector } from '@/state/hooks'
import { selectBanners } from './index.slice'
import { useEffect, useRef, useState } from 'react'

export default function HeroSection() {
  const listBanner = useAppSelector(selectBanners)

  const sectionRef = useRef<HTMLSelectElement | null>(null)
  const titleOne = listBanner[0]?.titleOne
  const titleTwo = listBanner[0]?.titleTwo
  const description = listBanner[0]?.description
  const url = listBanner[0]?.image?.url

  const [datosCargados, setDatosCargados] = useState(false)

  useEffect(() => {
    if (listBanner.length > 0) {
      setDatosCargados(true)
    }
  }, [listBanner])

  useEffect(() => {
    const cambiarEstilos = () => {
      const sectionElement = sectionRef.current
      if (sectionElement !== null) {
        sectionElement.style.backgroundImage =
          url !== undefined ? `url('${url}'` : ''
      }
    }

    if (datosCargados) {
      cambiarEstilos()
    }
  }, [datosCargados, url])

  return (
    <section
      role={'contentinfo'}
      className={`${styles.root} ${styles.root__banner}`}
      ref={sectionRef}>
      <div role={'group'} className={styles['root__content-wrapper']}>
        <h1 className={styles.root__title}>
          <strong>{titleOne}</strong>
          <br />
          {titleTwo}
        </h1>
        <p className={styles.root__description}>
          <strong>{description}</strong>
        </p>
        <div className={styles['root__actions-wrapper']}>
          <Link
            href={'/registro'}
            title={'Enlace al formulario de registro Kliiker'}
            className={styles.root__cta}>
            ¡Únete aquí!
          </Link>
        </div>
      </div>
    </section>
  )
}
