import {
  IKliikerLoginFormFields,
  IKliikerRecoveryRequestFormFields,
  IKliikerResetPasswordFormFields
} from '@/interfaces'
import {
  kliikerLoginFormService,
  kliikerRecoveryRequestFormService,
  kliikerResetPasswordFormService
} from '@/services'
import { IMessageToast, MessageToasts, SVGComponent } from '@atoms/index'
import { RecoveryRequestForm, ResetPasswordForm } from '@molecules/index'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { SubmitHandler, useForm } from 'react-hook-form'
import styles from './index.module.scss'

interface IPasswordRecoverySectionProps {
  resetTime?: number
}

export default function PasswordRecoverySection({
  resetTime = 60
}: IPasswordRecoverySectionProps) {
  const router = useRouter()
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [requestMessages, setRequestMessages] = useState<IMessageToast[]>([])
  const [resetMessages, setResetMessages] = useState<IMessageToast[]>([])
  const [loginMessages, setLoginMessages] = useState<IMessageToast[]>([])
  const [displayResetForm, setDisplayResetForm] = useState(false)
  const [remainingTime, setRemainingTime] = useState(0)
  const [requestDisabled, setRequestDisabled] = useState(false)
  const [isFulfilled, setIsFulfilled] = useState(false)
  const emailSent = useRef<string>()
  const passwordSent = useRef<string>()
  const formRef = useRef<HTMLFormElement>(null)
  const formObject = useForm<IKliikerLoginFormFields>({ mode: 'all' })

  const handleRequestFormSubmit: SubmitHandler<
    IKliikerRecoveryRequestFormFields
  > = async (data) => {
    try {
      await kliikerRecoveryRequestFormService(data)
      emailSent.current = data.email
      setRemainingTime(resetTime)
      setDisplayResetForm(true)
      setRequestDisabled(true)
    } catch (ex) {
      setRequestMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  const handleResetFormSubmit: SubmitHandler<
    IKliikerResetPasswordFormFields
  > = async (data) => {
    try {
      if (emailSent.current === undefined)
        throw new Error(
          'Algo salió mal en el envío del formulario, intenta generar un nuevo código y volver a envíar'
        )
      data.email = emailSent.current
      await kliikerResetPasswordFormService(data)
      passwordSent.current = data.password
      setIsFulfilled(true)
    } catch (ex) {
      setResetMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  const handleLoginFormSubmit: SubmitHandler<IKliikerLoginFormFields> = async (
    data
  ) => {
    setIsLoginLoading(true)
    try {
      if (emailSent.current !== undefined) data.email = emailSent.current
      if (passwordSent.current !== undefined)
        data.password = passwordSent.current
      if (executeRecaptcha !== undefined)
        data.gCaptcha = await executeRecaptcha(
          'kliikerLoginAfterPasswordResetFormSubmit'
        )
      await kliikerLoginFormService(data)
      router.replace('/portal')
    } catch (ex) {
      setLoginMessages((currentMessages) => [
        ...currentMessages,
        { type: 'error', message: (ex as Error).message }
      ])
    }
    setIsLoginLoading(false)
  }

  useEffect(() => {
    if (!requestDisabled) return
    const intervalId = setInterval(() => {
      if (remainingTime === 0) {
        setRequestDisabled(false)
        clearInterval(intervalId)
      } else setRemainingTime((currentTime) => currentTime - 1)
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [remainingTime, requestDisabled])

  return (
    <section
      role={'group'}
      className={styles.root}
      aria-label={'Sección de recuperación de contraseña'}>
      <SVGComponent
        svgPath="home-hero/top-decoration"
        className={styles.root__decoration}
      />
      <hgroup className={styles.root__heading}>
        <h1 className={styles.root__title}>
          {!isFulfilled ? (
            <>
              <strong>¡No te</strong> preocupes!
            </>
          ) : (
            <>
              <strong>¡Empecemos</strong> ahora!
            </>
          )}
        </h1>
        <p className={styles.root__description}>
          {!isFulfilled
            ? 'Escribe el correo electrónico con el cual te registraste a nuestra comunidad y genera el código de verificación para restablecer tu contraseña. Te enviaremos tu código de verificación al correo con el cual te registraste.'
            : 'Ya tienes todo lo necesario para continuar.'}
        </p>
      </hgroup>
      <div className={styles['root__content-wrapper']}>
        {!isFulfilled ? (
          <div className={styles['root__form-wrapper']}>
            <RecoveryRequestForm
              messages={requestMessages}
              onMessageDismiss={(index) =>
                setRequestMessages((currentMessage) =>
                  currentMessage.filter(
                    (_, currentIndex) => index !== currentIndex
                  )
                )
              }
              onSubmit={handleRequestFormSubmit}
              disabled={requestDisabled}
            />
            {displayResetForm && (
              <ResetPasswordForm
                resetTime={remainingTime}
                messages={resetMessages}
                onMessageDismiss={(index) =>
                  setResetMessages((currentMessage) =>
                    currentMessage.filter(
                      (_, currentIndex) => index !== currentIndex
                    )
                  )
                }
                onSubmit={handleResetFormSubmit}
              />
            )}
          </div>
        ) : (
          <form
            className={styles['root__success-wrapper']}
            ref={formRef}
            onSubmit={formObject.handleSubmit(handleLoginFormSubmit)}>
            <h2 className={styles.root__subtitle}>¡Listo!</h2>
            <p className={styles['root__success-message']}>
              Tienes tu nueva contraseña para poder seguir disfrutando de toda
              tu comunidad Kliiker.
            </p>
            <button
              type="submit"
              className={styles['root__success-btn']}
              disabled={isLoginLoading}>
              Ingresar a la comunidad
            </button>
            {isLoginLoading && <div className={styles.root__loading}></div>}
            {loginMessages.length !== 0 && (
              <div className={styles.root__messages}>
                <MessageToasts
                  dismissible
                  messages={loginMessages}
                  onDismiss={(index) =>
                    setLoginMessages((currentMessages) =>
                      currentMessages.filter(
                        (_, currentIndex) => index !== currentIndex
                      )
                    )
                  }
                />
              </div>
            )}
          </form>
        )}
      </div>
    </section>
  )
}
