export type TAlertType =
  | 'logout'
  | 'link-creation'
  | 'delete-brand'
  | 'delete-group'
  | 'autorize-comission'
  | 'download-report'
  | 'duplicate-rule'
  | 'delete-rule'

interface IAlertData {
  icon: string
  title: string
  description: string
  actions: {
    affirmative: string
    negative: string
  }
}

type TAlert = {
  [key in TAlertType]: IAlertData
}

export const alerts: TAlert = {
  logout: {
    icon: 'log-out',
    title: 'Salida segura',
    description: '¿Estás seguro de cerrar sesión?',
    actions: { affirmative: 'Si', negative: 'No' }
  },
  'link-creation': {
    icon: 'link',
    title: 'Creación de link único',
    description: '¿Estás seguro de crear tu link único Kliiker?',
    actions: { affirmative: 'Crear Link', negative: 'Cancelar' }
  },
  'delete-brand': {
    icon: 'trash',
    title: 'Eliminar marca',
    description: '¿Estás seguro de eliminar esta marca?',
    actions: { affirmative: 'Eliminar', negative: 'Cancelar' }
  },
  'delete-group': {
    icon: 'trash',
    title: 'Eliminar grupo',
    description: '¿Estás seguro de eliminar este grupo seleccionado?',
    actions: { affirmative: 'Eliminar', negative: 'Cancelar' }
  },
  'autorize-comission': {
    icon: 'check',
    title: 'Autorización de comisiones',
    description: '¿Estás seguro de autorizar las comisiones seleccionadas?',
    actions: { affirmative: 'Autorizar', negative: 'Cancelar' }
  },
  'download-report': {
    icon: 'download-cloud',
    title: 'Descarga de reporte',
    description:
      '¿Deseas descargar un informe en un formato .CSV de las comisiones autorizadas?',
    actions: { affirmative: 'Descargar', negative: 'Cancelar' }
  },
  'duplicate-rule': {
    icon: 'copy',
    title: '¿Deseas duplicar la regla de comisión seleccionada?',
    description: '',
    actions: { affirmative: 'Duplicar', negative: 'Cancelar' }
  },
  'delete-rule': {
    icon: 'trash',
    title: '¿Estás seguro de eliminar esta regla de comisión?',
    description: '',
    actions: { affirmative: 'Eliminar', negative: 'Cancelar' }
  }
}
