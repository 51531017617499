import {
  IInputFormGroup,
  IKliikerFilterDateFormFields,
  TCommission
} from '@/interfaces'
import { IMessageToast, InputGroup, MessageToasts } from '@atoms/index'
import { getFieldProp } from '@/functions'
import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import Card from '@molecules/card'
import { TableWrapper, CardPerformanceKliiker } from '..'
import ComissionsTable from '@molecules/comissions-table'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useFormGroups, useKliikerFilterFormFields } from '@/hooks'
import { useInfoCard } from '@/hooks/commission/index.data'
import { useSession } from 'next-auth/react'
import {
  IKliikerCommissionsTableQuery,
  getAdminKliikerCommissions,
  getAdminPerformanceResume
} from '@/services'
import { ICardPerformanceKliiker } from '@organisms/card-performance-kliiker'

interface IAdminKliikerProfileSectionProps {
  document?: string
}

interface IDataCards {
  authorized: string
  canceled: string
  generated: string
  inProgress: string
}

const mockKliikerInfo: ICardPerformanceKliiker = {
  image: '',
  name: '',
  email: '',
  phone: '',
  totalSale: 0,
  orders: 0,
  activity: 0,
  averageTicket: 0
}

export default function AdminKliikerProfileSection({
  document
}: IAdminKliikerProfileSectionProps) {
  const tableSize = 20
  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState<TCommission[]>([])
  const [tableQuery, setTableQuery] = useState<IKliikerCommissionsTableQuery>({
    size: tableSize
  })
  const [dataCards, setDataCards] = useState<IDataCards>()
  const infoCards = useInfoCard(
    dataCards?.authorized,
    dataCards?.canceled,
    dataCards?.generated,
    dataCards?.inProgress
  )

  const formObject = useForm<IKliikerFilterDateFormFields>({ mode: 'all' })
  const fields = useKliikerFilterFormFields()
  const groups = useFormGroups<
    IKliikerFilterDateFormFields,
    IInputFormGroup<IKliikerFilterDateFormFields>
  >(fields, ['startDate', 'endDate'])
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const [pageActive, setPageActive] = useState(1)
  const [countRegister, setCountRegister] = useState(1)
  const [countPages, setCountPages] = useState(1)
  const [kliikerInfo, setKliikerInfo] =
    useState<ICardPerformanceKliiker>(mockKliikerInfo)
  const [kliikerEmail, setKliikerEmail] = useState('')
  const { data } = useSession()
  const idToken = data?.idToken

  const handlePageChange = (page: number) => {
    setPageActive(page)
  }

  const handleFilterFormSubmit: SubmitHandler<
    IKliikerFilterDateFormFields
  > = async (data) => {
    setIsLoading(true)
    try {
      if (idToken === undefined)
        throw new Error('¡Algo salio mal 😰!', { cause: 'No-Id-Token' })
      const newDates: { startDate?: Date; endDate?: Date } = {
        startDate: undefined,
        endDate: undefined
      }
      if (data.startDate !== '') {
        newDates.startDate = new Date(data.startDate)
      }
      if (data.endDate !== '') {
        newDates.endDate = new Date(data.endDate)
      }
      setPageActive(1)
      setTableQuery((oldQuery) => ({
        ...oldQuery,
        ...newDates
      }))
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    async function fetchTable() {
      if (kliikerEmail === '') return
      if (idToken === undefined) return
      try {
        const response = await getAdminKliikerCommissions({
          idToken,
          email: kliikerEmail,
          queryParams: { ...tableQuery, page: pageActive - 1 }
        })
        setTableData(response.commissionsTable.data)
        setDataCards(response.commissionsTotals)
        setCountRegister(response.commissionsTable.countRegister)
        setCountPages(response.commissionsTable.countPage)
      } catch (ex) {}
    }
    fetchTable()
  }, [idToken, pageActive, kliikerEmail, tableQuery])

  useEffect(() => {
    async function fetchInfo() {
      try {
        if (idToken === undefined) return
        if (document === undefined) return
        const response = await getAdminPerformanceResume({ idToken, document })
        setKliikerInfo({
          activity: response.activityPercent,
          averageTicket: response.averageTicket,
          email: response.email,
          image: response.photoProfile,
          name: `${response.firstname} ${response.lastname}`,
          orders: response.ordersCount,
          phone: response.phone,
          totalSale: response.totalSales
        })
        setKliikerEmail(response.email)
      } catch (ex) {
        console.log((ex as Error).cause)
      }
    }
    fetchInfo()
  }, [idToken, document])

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección del perfil del Kliiker'}
      className={styles.root}>
      <CardPerformanceKliiker kliikerInfo={kliikerInfo} />
      <h1 className={styles.root__title}>Comisiones</h1>
      <form
        className={styles['root__content-date-filter']}
        onSubmit={formObject.handleSubmit(handleFilterFormSubmit)}>
        <div className={styles['root__content-date-filter__groups']}>
          {groups.map(({ field, ...group }, key) => {
            const fieldProp = getFieldProp<IKliikerFilterDateFormFields>(
              formObject,
              field
            )
            return (
              <InputGroup<IKliikerFilterDateFormFields>
                key={key}
                field={fieldProp}
                className={styles.root__group}
                {...group}
              />
            )
          })}
        </div>
        <button
          type={'submit'}
          className={styles['root__submit-button']}
          disabled={isLoading}
          aria-label="Botón para filtrar">
          Filtrar
        </button>
        {isLoading && <div className={styles.root__loading}></div>}
        {messages.length !== 0 && (
          <div className={styles.root__messages}>
            <MessageToasts
              dismissible
              messages={messages}
              onDismiss={(index) =>
                setMessages((currentMesages) =>
                  currentMesages.filter(
                    (_, curentIndex) => index !== curentIndex
                  )
                )
              }
            />
          </div>
        )}
      </form>
      <div
        className={styles['root__content-cards']}
        role={'group'}
        aria-label={'Cards con resumenes de comisiones'}>
        {infoCards.map((card, key) => (
          <Card
            key={key}
            value={card.value}
            label={card.label}
            background={card.background}
            tooltip={{
              text: card.tooltip?.text ?? '',
              icon: card.tooltip?.icon ?? ''
            }}
          />
        ))}
      </div>
      <div className={styles.root__content}>
        <TableWrapper
          title="Histórico de comisiones"
          handlePageChange={handlePageChange}
          resultsNumber={countRegister}
          resultsPerPage={tableSize}
          totalPages={countPages}>
          <ComissionsTable commissions={tableData} />
        </TableWrapper>
      </div>
    </section>
  )
}
