import FeatherIcon from 'feather-icons-react'
import {
  InputGroup,
  MessageToasts,
  BrandLogoSelector,
  SelectBrands
} from '@atoms/index'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import {
  IAdminBrandGroupsFormFields,
  IBrands,
  IBrandsGroupModalProps,
  IGroups,
  ITextModal,
  TSelectItemsFiller
} from '@/interfaces'

import styles from './index.module.scss'

export type TAlertStatus = 'open' | 'waiting' | 'closed'

type TDefaults = Partial<IAdminBrandGroupsFormFields>

function getDefaults(
  contentType: 'brand' | 'group',
  actionType: 'create' | 'edit',
  object?: IBrands | IGroups
): TDefaults {
  const result: TDefaults = {}
  if (object !== undefined && actionType === 'edit') {
    if (contentType === 'brand') {
      const typedObject = object as IBrands
      result.brandId = typedObject.brandId
      result.name = typedObject.name
      result.logo = typedObject.logo
    } else if (contentType === 'group') {
      const typedObject = object as IGroups
      result.brandId = typedObject.brandsGroup.brandId
      result.name = typedObject.brandsGroup.name
      result.brands = typedObject.childrenBrands.map((value) => value.brandId)
    }
  }
  return result
}

export default function BrandsGroupModal({
  status = 'closed',
  activeObject,
  brands = [],
  messages = [],
  handleCancell,
  onMessageDismiss,
  onSubmit = () => {},
  contentType = 'group',
  actionType = 'create'
}: IBrandsGroupModalProps) {
  const [isLoading, setIsLoading] = useState(false)

  const formObject = useForm<IAdminBrandGroupsFormFields>({
    mode: 'all',
    defaultValues: getDefaults(contentType, actionType, activeObject)
  })

  const handleFormSubmit: SubmitHandler<IAdminBrandGroupsFormFields> = async (
    data
  ) => {
    setIsLoading(true)
    await onSubmit(data)
    setIsLoading(false)
  }

  const handleSetBrands: TSelectItemsFiller = () => {
    return brands
  }
  const textModal: ITextModal = {
    brand: {
      create: {
        title: 'Nueva marca',
        textButton: 'Crear marca'
      },
      edit: {
        title: 'Editar marca',
        textButton: 'Guardar'
      }
    },
    group: {
      create: {
        title: 'Nuevo grupo',
        textButton: 'Crear grupo'
      },
      edit: {
        title: 'Editar grupo',
        textButton: 'Guardar'
      }
    }
  }

  const handleClearForm = () => {
    handleCancell()
    formObject.reset()
    formObject.clearErrors()
  }

  return status === 'open' ? (
    <div
      className={styles.root}
      role={'group'}
      aria-label={'Contenedor de modal de confirmación'}
      data-status={status}>
      <dialog
        open
        className={styles.root__dialog}
        aria-label={'Modal de marcas'}>
        <button
          className={styles.root__close}
          title={'Cerrar el modal'}
          aria-label={'Cerrar el modal'}>
          <FeatherIcon
            icon="x"
            role={'img'}
            className={styles['root__close-icon']}
            onClick={() => handleClearForm()}
          />
        </button>
        <div className={styles.root__content}>
          <h2 className={styles.root__title}>
            {textModal[contentType][actionType].title}
          </h2>
          <form
            className={styles.root__form}
            role={'form'}
            onSubmit={formObject.handleSubmit(handleFormSubmit)}>
            <InputGroup
              type={'text'}
              label="Nombre"
              className={styles.root__input}
              field={{ id: 'name', formObject }}
            />
            {contentType === 'brand' ? (
              <BrandLogoSelector field={{ id: 'logo', formObject }} />
            ) : (
              <SelectBrands
                label="Marca o grupo"
                activeLabel="Marca o grupo"
                field={{ id: 'brands', formObject }}
                itemsFiller={handleSetBrands}
              />
            )}

            {isLoading && <div className={styles.root__loading}></div>}
            {messages.length !== 0 && (
              <div className={styles.root__messages}>
                <MessageToasts
                  dismissible
                  messages={messages}
                  onDismiss={onMessageDismiss}
                />
              </div>
            )}
            <div className={styles.root__actions}>
              <button
                className={styles.root__cancel}
                type={'submit'}
                aria-label={'Botón para cancelar el envio del formulario'}
                onClick={() => handleClearForm()}
                disabled={isLoading}>
                Cancelar
              </button>
              <button
                className={styles.root__submit}
                type={'submit'}
                aria-label={'Botón para enviar el formulario'}
                onClick={() => {
                  formObject.setValue('type', contentType)
                }}
                disabled={isLoading}>
                {textModal[contentType][actionType].textButton}
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  ) : null
}
