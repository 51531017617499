const monthTranslations: { [key: string]: string } = {
    JANUARY: 'Enero',
    FEBRUARY: 'Febrero',
    MARCH: 'Marzo',
    APRIL: 'Abril',
    MAY: 'Mayo',
    JUNE: 'Junio',
    JULY: 'Julio',
    AUGUST: 'Agosto',
    SEPTEMBER: 'Septiembre',
    OCTOBER: 'Octubre',
    NOVEMBER: 'Noviembre',
    DECEMBER: 'Diciembre'
};

export function translateMonthToSpanish(month: string): string {
    const upperCaseMonth = month.toUpperCase();
    const translatedMonth = monthTranslations[upperCaseMonth];
    if (translatedMonth.length > 0) {
        return translatedMonth;
    } else {
        throw new Error('Invalid month name');
    }
}
