import { useEffect, useState } from 'react'

interface IWindowSize {
  width?: number
  height?: number
}

export function useResize() {
  const [size, setSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined
  })
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (typeof window === 'undefined') return
    function handleResize() {
      const { innerWidth, innerHeight } = window
      setSize({ width: innerWidth, height: innerHeight })
    }
    window.addEventListener('resize', handleResize)
    if (isFirstRender) {
      handleResize()
      setIsFirstRender(false)
    }
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isFirstRender])

  return size
}

export function useIsMobile() {
  const { width = 0 } = useResize()
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    setIsMobile(width < 1024)
  }, [width])

  return isMobile
}
