import {
  ECommissionStatus,
  ECommissionType,
  TCommission,
  TCommissionStatus,
  TCommissionType,
  ICommissionsDetails,
  TCommissionRule,
  ICommissionsRuleDetails
} from '@/interfaces'
import { addDays } from 'date-fns'

export function formatDate(date?: string): string {
  return date !== undefined
    ? new Date(date).toLocaleDateString('es-CO', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      })
    : '-'
}

export function formatNewDate(date?: string): string {
  return date !== undefined
    ? addDays(new Date(date), 1).toLocaleDateString('es-CO', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      })
    : '-'
}

export function formatServiceDate(date: Date): string {
  date.setDate(date.getDate() + 1)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const dateResult = `${year}-${month < 10 ? '0' : ''}${month}-${
    day < 10 ? '0' : ''
  }${day}`
  return dateResult
}

export function formatAmount(value?: number): string {
  return value === undefined || value === 0
    ? '-'
    : new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0
      }).format(value)
}

export function formatPercent(value: number): string {
  return value === 0
    ? '-'
    : new Intl.NumberFormat('es-CO', {
        style: 'percent',
        maximumFractionDigits: 1
      }).format(value)
}

export function parseCommissionStatus(statusId: number): TCommissionStatus {
  const statuses = [
    ECommissionStatus.t1,
    ECommissionStatus.t2,
    ECommissionStatus.t3,
    ECommissionStatus.t4
  ]
  return statuses[statusId - 1]
}

export function formatCommissionStatus(status: TCommissionStatus): string {
  switch (status) {
    case 'pending':
      return 'En proceso'
    case 'cancelled':
      return 'Cancelada'
    case 'generated':
      return 'Generada'
    case 'authorized':
      return 'Autorizada'
    default:
      return '-'
  }
}

export function parseCommissionType(typeId: number): TCommissionType {
  const types = [
    ECommissionType.t1,
    ECommissionType.t2,
    ECommissionType.t3,
    ECommissionType.t4,
    ECommissionType.t5,
    ECommissionType.t6,
    ECommissionType.t7,
    ECommissionType.t8,
    ECommissionType.t9
  ]
  return types[typeId - 1]
}

export function formatCommissionType(type: TCommissionType): string {
  switch (type) {
    case 'sell':
      return 'Venta'
    case 'permanence':
      return 'Permanencia'
    case 'first-sell':
      return 'Primera venta'
    case 'season':
      return 'Ventas por temporada'
    case 'referal':
      return 'Primera venta de referido'
    case 'effective-referals':
      return 'Referidos efectivos'
    case 'consecutiveness':
      return 'Desempeño'
    case 'reactivation':
      return 'Reactivación de Kliiker'
    case 'goals':
      return 'Meta'
    default:
      return '-'
  }
}

export function formatCommissionMessage(commission: TCommission): string {
  switch (commission.ruleTypeId) {
    case 2: {
      const {
        amount,
        params: { brand }
      } = commission
      return `${brand} te premia por tu primera venta con un bono de ${formatAmount(
        amount
      )}.`
    }
    case 3: {
      const {
        params: {
          brand,
          orderId,
          ruleParams: { beginDate, endDate, percentage }
        }
      } = commission
      return `Por la venta que realizaste entre el ${formatDate(
        beginDate
      )} y el ${formatDate(
        endDate
      )} con número de orden ${orderId}, ${brand} te premia con un ${percentage}% más de comisión.`
    }
    case 4: {
      const {
        params: {
          brand,
          totalSellsAmount,
          ruleParams: { beginDate, endDate, steps }
        }
      } = commission
      const commissionApprovedSteps = steps.filter(
        (step) => step.thresholdAmount <= totalSellsAmount
      )
      const commissionPencent =
        commissionApprovedSteps[commissionApprovedSteps.length - 1].percentage

      return `${brand} te premia con un ${commissionPencent}% de comisión adicional por el total de tus ventas realizadas entre el ${formatDate(
        beginDate
      )} y el ${formatDate(endDate)}.`
    }
    case 5: {
      const {
        params: {
          brand,
          ruleParams: { beginDate, endDate, quantity, commissionAmount }
        }
      } = commission
      return `¡Tu comunidad crece y tu ganas! Por eso ${brand} te premia con un bono de ${formatAmount(
        commissionAmount
      )} por referir de forma efectiva desde de ${quantity} nuevos Kliikers entre el ${formatDate(
        beginDate
      )} y el ${formatDate(endDate)}.`
    }
    case 6: {
      const {
        params: {
          brand,
          ruleParams: { periods, commissionAmount, description }
        }
      } = commission
      return `Por tu constancia realizando pedidos durante ${periods} ${
        periods === 1 ? 'periodo' : 'periodos'
      } consecutivos. ${brand} te da el siguiente premio: ${
        description === ''
          ? `Un bono por ${formatAmount(commissionAmount)}`
          : description
      }.`
    }
    case 7: {
      const {
        params: {
          brand,
          ruleParams: { commissionAmount, percentage }
        }
      } = commission
      return `Juntos logramos más, por eso ${brand} te premia por las ventas que haces en compañia con ${
        percentage === 0
          ? `un bono de ${formatAmount(commissionAmount)}`
          : `${percentage}% de comisión sobre tus ventas realizadas`
      }.`
    }
    case 8: {
      const {
        params: {
          brand,
          ruleParams: { periods, commissionAmount, description }
        }
      } = commission
      return `¡Bienvenido de vuelta a tu comunidad! Por tu primer pedido luego de estar ausente durante ${periods} ${
        periods === 1 ? 'periodo' : 'periodos'
      } consecutivos ${brand} te da el siguiente premio: ${
        description === ''
          ? `Un bono por ${formatAmount(commissionAmount)}`
          : description
      }.`
    }
    case 9: {
      const {
        params: {
          brand,
          ruleParams: { period, commissionAmount, description }
        }
      } = commission
      return `Lograste tu meta del periodo ${period} con ${brand}. Ahora solo queda esperar tu recompensa: ${
        description === ''
          ? `Un bono por ${formatAmount(commissionAmount)}`
          : description
      }.`
    }
    default:
      return ''
  }
}

export function formatSellItemName(name: string, qty: number): string {
  return name + (qty > 1 ? `(x${qty})` : '')
}

export const formatDashboardComissionCardValue = (value: number): string =>
  new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0
  }).format(value)

export const parseCommissionDetails = (
  commission: TCommission
): ICommissionsDetails => {
  const objeto: ICommissionsDetails = {}
  const { ruleTypeId } = commission
  if (ruleTypeId === 1) {
    objeto.numberOrder = commission.params.externalId
    objeto.commissionPercentageBrand = commission.params.sellRuleParams
      .map((item) => `${item.brand} ${item.percentage}%`)
      .join(', ')
  }
  if (
    ruleTypeId === 2 ||
    ruleTypeId === 3 ||
    ruleTypeId === 5 ||
    ruleTypeId === 6 ||
    ruleTypeId === 7 ||
    ruleTypeId === 8 ||
    ruleTypeId === 9
  ) {
    objeto.minimumAmount = formatAmount(
      commission.params.ruleParams.thresholdAmount
    )
  }
  if (ruleTypeId === 2) {
    objeto.commissionValue = formatAmount(commission.amount)
  }
  if (ruleTypeId === 3 || ruleTypeId === 4 || ruleTypeId === 5) {
    objeto.dateRange = `${formatDate(
      commission.params.ruleParams.beginDate
    )} - ${formatDate(commission.params.ruleParams.endDate)}`
  }
  if (ruleTypeId === 3 || ruleTypeId === 7) {
    objeto.commissionPercentage = `${commission.params.ruleParams.percentage}%`
  }
  if (ruleTypeId === 4) {
    let topSaleSupered = 0
    let percentageEqual = 0
    let prize = ''
    for (const sell of commission.params.ruleParams.steps) {
      if (commission.params.totalSellsAmount >= sell.thresholdAmount) {
        topSaleSupered = sell.thresholdAmount
        percentageEqual = sell.percentage
        prize = sell.description
      }
    }
    objeto.topSaleSupered = formatAmount(topSaleSupered)
    objeto.commissionPercentage = `${percentageEqual}%`
    objeto.prize = commission.params.description ?? prize
  }
  if (
    ruleTypeId === 5 ||
    ruleTypeId === 6 ||
    ruleTypeId === 7 ||
    ruleTypeId === 8 ||
    ruleTypeId === 9
  ) {
    objeto.commissionValue = formatAmount(
      commission.params.ruleParams.commissionAmount
    )
  }
  if (ruleTypeId === 5) {
    objeto.numberReferrals = commission.params.ruleParams.quantity
  }
  if (ruleTypeId === 6 || ruleTypeId === 8 || ruleTypeId === 9) {
    objeto.prize = commission.params.ruleParams.description
  }
  if (ruleTypeId === 6 || ruleTypeId === 8) {
    objeto.commissionPeriods = commission.params.ruleParams.periods
  }
  if (ruleTypeId === 9) {
    objeto.commissionPeriod = commission.params.ruleParams.period
  }
  return objeto
}

export const parseCommissionRuleDetails = (
  commission: TCommissionRule
): ICommissionsRuleDetails => {
  const objeto: ICommissionsRuleDetails = {}
  const { ruleTypeId } = commission
  if (ruleTypeId === 1 || ruleTypeId === 3 || ruleTypeId === 7) {
    objeto.percentage = commission.params.percentage
  }
  if (
    ruleTypeId === 2 ||
    ruleTypeId === 5 ||
    ruleTypeId === 6 ||
    ruleTypeId === 7 ||
    ruleTypeId === 8 ||
    ruleTypeId === 9
  ) {
    objeto.commissionAmount = commission.params.commissionAmount
  }
  if (
    ruleTypeId === 2 ||
    ruleTypeId === 3 ||
    ruleTypeId === 5 ||
    ruleTypeId === 6 ||
    ruleTypeId === 7 ||
    ruleTypeId === 8 ||
    ruleTypeId === 9
  ) {
    objeto.thresholdAmount = commission.params.thresholdAmount
  }
  if (ruleTypeId === 3) {
    objeto.percentage = commission.params.percentage
  }
  if (ruleTypeId === 3 || ruleTypeId === 4 || ruleTypeId === 5) {
    objeto.beginDate = commission.params.beginDate
    objeto.endDate = commission.params.endDate
  }
  if (ruleTypeId === 4) {
    objeto.steps = commission.params.steps
  }
  if (ruleTypeId === 5) {
    objeto.quantity = commission.params.quantity
  }
  if (ruleTypeId === 6 || ruleTypeId === 8) {
    objeto.periods = commission.params.periods
  }
  if (ruleTypeId === 6 || ruleTypeId === 8 || ruleTypeId === 9) {
    objeto.description = commission.params.description
  }
  if (ruleTypeId === 9) {
    objeto.period = commission.params.period
  }

  return objeto
}

export const normalizeDate = (date?: string): string => {
  return date !== undefined
    ? new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })
    : '-'
}

export const csvToJson = (csv: string) => {
  const lines = csv.split('\n')
  const result = []
  const headers = lines[0].split(',')
  for (let i = 1; i < lines.length; i++) {
    const obj: any = {}
    const currentline = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]
    }
    result.push(obj)
  }
  return result
}

export const normalizeSize = (size: number) => {
  const sizeInKb = size / 1024
  if (sizeInKb < 1024) {
    return `${sizeInKb.toFixed(2)} KB`
  }
  const sizeInMb = sizeInKb / 1024
  if (sizeInMb < 1024) {
    return `${sizeInMb.toFixed(2)} MB`
  }
  const sizeInGb = sizeInMb / 1024
  return `${sizeInGb.toFixed(2)} GB`
}
