import { useEffect, useState } from 'react'
import { PresentationBubble } from '@atoms/index'
import { bubbleImages } from './index.data'
import styles from './index.module.scss'

interface IPresentationBubblesProps {
  delay?: number
  className?: string
}

export default function PresentationBubbles({
  delay,
  className
}: IPresentationBubblesProps) {
  const [index, setIndex] = useState(-1)

  const timerDelay = delay ?? 2000

  useEffect(() => {
    const timerId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === 2 ? -1 : prevIndex + 1))
    }, timerDelay)
    return () => clearInterval(timerId)
  }, [timerDelay])

  const buildListClassName = () => {
    let listClassName = styles.root
    const componentClassName = className ?? ''
    listClassName += componentClassName !== '' ? ` ${componentClassName}` : ''
    return listClassName
  }

  return (
    <ul className={buildListClassName()}>
      {bubbleImages.map((image, key) => {
        const itemStatus =
          index === key ? 'focused' : index !== -1 ? 'iddle' : 'initial'

        const itemDirection = key === 1 ? 'right' : 'left'

        return (
          <li
            key={key}
            className={styles.root__item}
            data-index={key}
            data-status={itemStatus}>
            <PresentationBubble
              src={image.src}
              status={itemStatus}
              caption={image.caption}
              direction={itemDirection}
            />
          </li>
        )
      })}
    </ul>
  )
}
