type TMetricValueFormat = 'number' | 'currency'

interface IMetricItem {
  value: number
  formatStyle: TMetricValueFormat
  description: string
}

export const metricsList: IMetricItem[] = [
  { value: 5000, formatStyle: 'number', description: 'Kliikers activos' },
  {
    value: 1500000,
    formatStyle: 'currency',
    description: 'Ganancia promedio mensual por Kliiker'
  },
  { value: 400, formatStyle: 'number', description: 'Ciudades en todo el país' }
]

export const formatMetricValue = (
  value: number,
  formatStyle: TMetricValueFormat
): string =>
  (formatStyle === 'number' ? '+' : '') +
  new Intl.NumberFormat(
    'es-CO',
    formatStyle === 'currency'
      ? {
        style: 'currency',
        currency: 'COP',
        currencyDisplay: 'symbol',
        notation: 'compact',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      }
      : { notation: 'compact' }
  ).format(value)
