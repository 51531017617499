import FeatherIcon from 'feather-icons-react'
import { IBrands } from '@/interfaces'
import Image from 'next/image'

import styles from './index.module.scss'
import { normalizeDate } from '@/functions'

interface IBrandsTBodyProps {
  brand?: IBrands
  onEdit?: (brand: IBrands) => void
  onDelete?: (idBrand: number) => void
}

export default function BrandsTBody({
  brand = {
    brandId: 0,
    name: '',
    logo: '',
    isGroup: false,
    createdAt: '',
    updatedAt: ''
  },
  onEdit,
  onDelete
}: IBrandsTBodyProps) {
  return (
    <tbody
      className={styles.root}
      aria-label={'Detalles de la Marca'}
      role={'group'}>
      <tr className={styles.root__data}>
        <td>{normalizeDate(brand.createdAt)}</td>
        <td>
          <Image
            src={
              brand.logo !== ''
                ? brand.logo
                : '/assets/img/brands-carousel/adidas-logo.png'
            }
            alt="Logo de la marca vinculada"
            width={55}
            height={37}
          />
        </td>
        <td>{brand.name}</td>
        <td>
          <FeatherIcon
            icon="edit-2"
            className={styles.root__icon}
            onClick={() => onEdit?.(brand)}
          />
        </td>
        <td>
          <FeatherIcon
            icon="trash-2"
            className={styles.root__icon}
            onClick={() => onDelete?.(brand?.brandId)}
          />
        </td>
      </tr>
    </tbody>
  )
}
