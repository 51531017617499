import { getStaticLinks } from '@/functions'
import { IStaticLink, TStaticLinksNames } from '@/interfaces'
import { useMemo } from 'react'

interface IFooterLinksListDefinition {
  title: string
  list: TStaticLinksNames[]
}

interface IFooterLinksList {
  title: string
  list: IStaticLink[]
}

export const footerLinksListArray: IFooterLinksListDefinition[] = [
  {
    title: 'Información',
    list: ['help', 'faqs']
  },
  {
    title: 'Términos y políticas',
    list: ['terms-n-conditions', 'data-protection', 'privacy-policy']
  },
  {
    title: 'Contáctanos',
    list: ['phone', 'mail', 'location']
  }
]

export function getFooterLinksListArray() {
  const links = getStaticLinks()
  return footerLinksListArray.map<IFooterLinksList>((value) => ({
    title: value.title,
    list: value.list
      .filter((value) => links.has(value))
      .map((value) => links.get(value) as IStaticLink)
  }))
}

export function useFooterLinksListArray() {
  return useMemo(() => getFooterLinksListArray(), [])
}
