import { useCustomFieldRegistration } from '@/hooks'
import { IPictureSelectorFormGroupProps } from '@/interfaces'
import Image from 'next/image'
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import { FieldValues, Path, PathValue } from 'react-hook-form'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'

export default function PictureSelectorGroup<T extends FieldValues>({
  field
}: IPictureSelectorFormGroupProps<T>) {
  const [previewImage, setPreviewImage] = useState('')
  const [isFirstRender, setIsFirstRender] = useState(true)
  const fieldRegistration = useCustomFieldRegistration<T>(field)
  const fieldValue = field?.formObject.watch(field.id) ?? ''

  useEffect(() => {
    if (!isFirstRender) return
    setIsFirstRender(false)
    if (fieldValue === '') return
    setPreviewImage(fieldValue)
    field?.formObject.setValue(field.id, '' as PathValue<T, Path<T>>)
  }, [isFirstRender, fieldValue, field?.formObject, field?.id])

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      if (target.files === null || target.files.length === 0) return
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          const base64 = reader.result.replace('data:', '').replace(/^.+,/, '')
          setPreviewImage(reader.result)
          field?.formObject.setValue(field.id, base64 as PathValue<T, Path<T>>)
        }
      })
      reader.readAsDataURL(target.files[0])
    },
    [field?.formObject, field?.id]
  )

  return (
    <div
      role={'group'}
      aria-label={'Campo de ingreso de archivo'}
      className={styles.root}>
      <div className={styles.root__inputs}>
        <input type={'hidden'} {...fieldRegistration} />
        <input
          id="picture-selector"
          type="file"
          accept="image/*"
          onChange={handleChange}
        />
      </div>
      <div className={styles.root__wrapper}>
        <figure className={styles.root__mask}>
          {previewImage !== '' ? (
            <Image
              className={styles.root__picture}
              src={previewImage}
              alt="Vista previa de la imagen de perfil del Kliiker"
              width={72}
              height={72}
            />
          ) : (
            <FeatherIcon
              icon="user"
              className={styles.root__icon}
              role={'img'}
            />
          )}
        </figure>
        <label htmlFor="picture-selector" className={styles.root__label}>
          <FeatherIcon icon="edit-2" className={styles.root__edit} />
        </label>
      </div>
    </div>
  )
}
